import { EventEmitter } from 'events'
import { v4 as uuid } from 'uuid'
import { markRaw } from 'vue'

import { mark } from '@/performance/mark'

import { RENDERED_CALL } from '@/performance/keys'
import type { AnnotationData } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import type { CameraEvent, CameraOffset } from '@/modules/Editor/camera'
import { Camera } from '@/modules/Editor/camera'
import type { CallbackHandle } from '@/modules/Editor/callbackHandler'
import { CallbackHandleCollection } from '@/modules/Editor/callbackHandler'
import type { ViewEvent } from '@/modules/Editor/eventBus'
import {
  AnnotationManagerEvents,
  CameraEvents,
  CommentManagerEvents as CommentManagerBusEvents,
  EditorExceptions,
  ViewEvents,
  ViewMouseEvents,
} from '@/modules/Editor/eventBus'
import { getZoomWindow } from '@/modules/Editor/getZoomWindow'
import { hasSegmentContainingIndex } from '@/modules/Editor/helpers/segments'
import type { ImageManipulationFilter, WindowLevels } from '@/modules/Editor/imageManipulation'
import { getImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import type { ActionManager } from '@/modules/Editor/managers/actionManager'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { pointInPath, pointIsVertexOfPath } from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import type { BBox } from '@/modules/Editor/types'
import { getWindowLevelsRange } from '@/modules/Editor/utils/windowLevels'
import type { Editor } from '@/modules/Editor/editor'
import { getAllAnnotationVertices } from '@/modules/Editor/getAllAnnotationVertices'
import { CommentManager } from '@/modules/Editor/managers/CommentManager'
import {
  AnnotationManager,
  Events as AnnotationEvents,
} from '@/modules/Editor/managers/annotationManager'
import { Events as FileManagerEvents, FileManager } from '@/modules/Editor/managers/fileManager'
import { AnnotationsAndFrameSyncManager } from '@/modules/Editor/managers/annotationsAndFrameSyncManager'
import { MeasureManager } from '@/modules/Editor/managers/measureManager'
import { RasterManager } from '@/modules/Editor/managers/rasterManager'
import { RenderManager } from '@/modules/Editor/managers/renderManager'
import type { ToolManager } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { isVideoAnnotation } from '@/modules/Editor/models/annotation/annotationKindValidator'
import { clearAnnotationRenderingCache } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import { annotationToRenderableItem } from '@/modules/Editor/models/annotation/annotationToRenderableItem'
import { HTMLLayer, ObjectHTML } from '@/modules/Editor/models/layers/HTMLLayer'
import { Layer } from '@/modules/Editor/models/layers/layer'
import type { RenderableItem } from '@/modules/Editor/models/layers/object2D'
import { Object2D } from '@/modules/Editor/models/layers/object2D'
import { OptimisedLayer } from '@/modules/Editor/models/layers/optimisedLayer/optimisedLayer'
import { RasterLayer } from '@/modules/Editor/models/layers/rasterLayer'
import type { ReferenceLinesLayer } from '@/modules/Editor/models/layers/referenceLinesLayer'
import type { ILayer } from '@/modules/Editor/models/layers/types'
import { renderCommentThread } from '@/modules/Editor/plugins/commentator/commentatorRenderer'
import type { UpdatedAnnotation } from '@/modules/Editor/serialization/types'
import { getCSSFilterString } from '@/modules/Editor/utils'
import { getBBox } from '@/modules/Editor/utils/calcBBox'
import type { FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
import { RasterWorker } from '@/modules/Editor/workers/RasterWorker/RasterWorker'
import { setContext } from '@/services/sentry'

// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/types'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemPayload } from '@/store/types/V2DatasetItemPayload'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import type { ViewTypesType } from './viewTypes'
import { ViewTypes } from './viewTypes'
import { HTMLImageLayer } from '@/modules/Editor/models/layers/HTMLImageLayer'
import { isAnnotationOutOfView } from '@/modules/Editor/utils/outOfViewUtils'
import type { PartialRecord } from '@/core/helperTypes'
import type { BlendImageData } from '@/modules/Editor/utils/resolveBlendedRenderableImage'
import { resolveBlendedRenderableImage } from '@/modules/Editor/utils/resolveBlendedRenderableImage'
import { setImageChannelsState } from '@/modules/Editor/setImageChannelsState'

const NO_OVERLAY_TYPES = ['string']

type Layers = (
  | ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
  | HTMLLayer
  | RasterLayer
  | ReferenceLinesLayer
  | HTMLImageLayer
)[]

type SlotName = string
export const FrameLoaderSource = {
  HQ: 'hq',
  LQ: 'lq',
  FROM_INDEX: 'fromIndex',
} as const
export type FrameLoaderSource = (typeof FrameLoaderSource)[keyof typeof FrameLoaderSource]

export type ViewChannel = {
  slotName: SlotName
  slot: V2DatasetItemSlot
  fileManager: FileManager
}

/**
 * @event currentFrameIndex:changed
 * @property {number} currentFrameIndex
 */
export abstract class View extends EventEmitter {
  /**
   * Field required to be specified by all derived views, to be used as an
   * identifier for the view type.
   */
  public abstract readonly type: ViewTypesType
  private _showFramesTool: boolean = false
  public get showFramesTool(): boolean {
    return this._showFramesTool
  }

  public set showFramesTool(state: boolean) {
    this._showFramesTool = state
    ViewEvents.showFramesToolChanged.emit(this.viewEvent, this._showFramesTool)
  }

  public id: string

  /**
   * Name used to identify the view. Usually file slot name.
   */
  public name: string

  protected readonly viewEvent: ViewEvent

  protected static RERENDER_LIMIT = 50

  /**
   * Keeps views loading state.
   *
   * @event loading:changed
   * @property {boolean} isLoading - Views loading state.
   */
  private _loading: boolean = false
  public get loading(): boolean {
    return this._loading
  }

  public set loading(state: boolean) {
    this._loading = state
    ViewEvents.loadingChanged.emit(this.viewEvent, this._loading)
  }

  /**
   * Main layer - renders image/video on the screen.
   *
   * Only zoom, panning, and image filter update
   * will trigger its re-render.
   */
  public mainLayer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
  /**
   * Renders image on the screen using HTMLImageElement.
   * It doesn't support color maps and windows/levels filters.
   */
  private htmlImageLayer: HTMLImageLayer | null = null
  protected _mainLayerVtk: boolean
  /**
   * Annotations layer - renders annotations, comments, inferenceData items.
   */
  public annotationsLayer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>
  /**
   * Annotations overlays layer - renders overlays related to annotations.
   */
  public annotationsOverlayLayer: HTMLLayer

  public rasterAnnotationLayer: RasterLayer
  public commentLayer: ILayer<CanvasRenderingContext2D, HTMLCanvasElement>

  /**
   * Returns the default image filter (for the base slot)
   * When the file has channels, then returns default window levels as they are not supported
   * at file level, but instead computed per channel
   */
  public get imageFilter(): ImageManipulationFilter {
    const imageFilter = this.imageFilters[this.fileManager.slotName]
    if (!imageFilter) {
      throw new Error('Image filter not found')
    }
    if (this.supportsChannels && this.channels.length > 0) {
      return {
        ...imageFilter,
        windowLevels: this.windowLevelsRange,
      }
    }
    return imageFilter
  }
  /**
   * Holds image filters per slot names.
   * This is useful for views with channels, so that we can store image filters for each channel
   */
  public imageFilters: PartialRecord<SlotName, ImageManipulationFilter>

  public camera: Camera

  public measureManager: MeasureManager
  public annotationManager: AnnotationManager
  public rasterManager: RasterManager
  public commentManager: CommentManager
  public renderManager: RenderManager
  public fileManager: FileManager

  protected annotationsAndFrameSyncManager = new AnnotationsAndFrameSyncManager(
    () => this.supportsImageAndAnnotationsSync,
  )

  public rasterWorker: RasterWorker

  private readonly isOnUseImgRendering: boolean

  protected onCleanup: (() => void)[] = []

  private _currentFrame: RenderableImage | undefined
  public get currentFrame(): RenderableImage | undefined {
    return this._currentFrame
  }

  /**
   * Current frame is different from the currentFrameIndex
   * since the current frame needs to get data from the server
   * so we assign it later than the currentFrameIndex
   */
  protected set currentFrame(frame: RenderableImage | undefined) {
    this._currentFrame = frame

    if (frame !== undefined) {
      ViewEvents.currentFrameDisplayed.emit(this.viewEvent, this.currentFrameIndex)
    }

    this.mainLayer.changed()
    this.updateImageLayerUrl()

    if (this.channels.length > 0) {
      // when the current frame is set, channels are blended, so state is ready
      setImageChannelsState(this.mainLayer.canvas, 'ready')
    }
  }

  private async updateImageLayerUrl(): Promise<void> {
    if (!this.htmlImageLayer) {
      return
    }

    const url = await this.fileManager.frameManager.getFrameUrl(this.currentFrameIndex)
    if (!url) {
      throw new Error('No URL for frame')
    }
    this.htmlImageLayer?.setImageURL(url)
  }

  /**
   * Current frame index is used throughout the app to know the index we are currently displaying
   * in the editor.
   * When the video is playing, it's calculated using either the video manifest or current time with
   * fps.
   */
  private _currentFrameIndex: number = 0
  public get currentFrameIndex(): number {
    return this._currentFrameIndex
  }

  protected set currentFrameIndex(value: number) {
    if (this._currentFrameIndex === value) {
      return
    }

    const oldIndex = this._currentFrameIndex
    this._currentFrameIndex = value
    ViewEvents.currentFrameIndexChanged.emit(this.viewEvent, {
      newIndex: this._currentFrameIndex,
      oldIndex,
    })

    this.defineCommentThreadsRender()
  }

  protected framesIndexes: number[] = [0]

  constructor(
    public editor: Editor,
    file: V2DatasetItemSlot,
    item: V2DatasetItemPayload,
    initialFrameIndex: number = -1,
    framesLoaderConfig: FramesLoaderConfig = {},
  ) {
    super()

    this.id = `${item.id}-${file.id}-${uuid()}`
    this.name = file.slot_name
    this.viewEvent = { viewId: this.id, slotName: this.name }
    this.editor = editor
    this.isOnUseImgRendering = editor.featureFlags.USE_IMG_RENDERING
    this.camera = markRaw(
      new Camera(this.id, file.slot_name, {
        scale: 1.0,
        offset: { x: 0, y: 0 },
        imageDimension: {
          width: file.metadata?.width || 1,
          height: file.metadata?.height || 1,
        },
      }),
    )

    this.annotationsLayer = markRaw(new OptimisedLayer(this.id, this.name, this.camera))

    this.mainLayer = markRaw(new Layer('main-layer'))
    if (this.isOnUseImgRendering) {
      this.htmlImageLayer = markRaw(new HTMLImageLayer('image-layer'))
    }
    this._mainLayerVtk = false
    this.commentLayer = markRaw(new Layer('comment-layer'))
    this.annotationsOverlayLayer = new HTMLLayer('annotations-overlay-layer')

    this.measureManager = new MeasureManager(this)
    // temporary solution to prevent annotation manager from ever
    // being made reactive by Vue, to avoid performance issues
    this.annotationManager = markRaw(new AnnotationManager(this))

    this.fileManager = new FileManager(this, file, item, framesLoaderConfig)

    // Slot based image filters
    this.imageFilters = {
      [this.fileManager.slotName]: getImageManipulationFilter(),
    }

    const channels = file.channels
    if (channels) {
      channels.forEach((channel) => {
        this._channels.push({
          slotName: channel.slot_name,
          fileManager: new FileManager(this, channel, item),
          slot: channel,
        })
        this.imageFilters[channel.slot_name] = {
          ...getImageManipulationFilter(),
          windowLevels: this.windowLevelsRange,
        }
      })
      this.toggleActiveChannels([this.fileManager.slotName])
    }

    const onToggleChannels = ({ viewId }: ViewEvent, channelSlotNames: SlotName[]): void => {
      if (viewId !== this.id) {
        return
      }
      this.toggleActiveChannels(channelSlotNames)
      // Reload the current frame with the updated list of channels
      setImageChannelsState(this.mainLayer.canvas, 'blending')
      this.reloadCurrentFrame()
    }

    ViewEvents.activeChannelsChange.on(onToggleChannels)
    this.onCleanup.push(() => ViewEvents.activeChannelsChange.off(onToggleChannels))

    this._currentFrameIndex = Math.max(0, Math.min(initialFrameIndex, this.totalFrames - 1)) || 0

    this.commentManager = new CommentManager(this)
    this.renderManager = new RenderManager()

    this.rasterWorker = new RasterWorker()
    this.onCleanup.push(() => this.rasterWorker.cleanup())

    this.rasterManager = new RasterManager(this)
    this.rasterAnnotationLayer = new RasterLayer(this)

    this.addPermanentListeners()

    const onBeforeRender = (canvas: HTMLCanvasElement): void => {
      canvas.width = this.camera.width
      canvas.height = this.camera.height
    }

    this.onCleanup.push(
      this.mainLayer.onBeforeRender((ctx, canvas) => onBeforeRender(canvas)).release,
    )

    this.onCleanup.push(
      this.annotationsLayer.onBeforeRender((ctx, canvas) => onBeforeRender(canvas)).release,
    )

    this.onCleanup.push(
      this.annotationsLayer.onRendered((key: string | null) => {
        mark(RENDERED_CALL)

        if (!this.supportsImageAndAnnotationsSync || key === null) {
          return
        }
        if (
          this.supportsImageAndAnnotationsSync &&
          this.annotationsAndFrameSyncManager.renderedKey !== key
        ) {
          return
        }

        this.annotationsAndFrameSyncManager.annotationsRenderedForKey(key)
      }).release,
    )

    this.onCleanup.push(
      this.commentLayer.onBeforeRender((ctx, canvas) => onBeforeRender(canvas)).release,
    )

    this.onCleanup.push(
      this.rasterAnnotationLayer.onBeforeRender((ctx, canvas) => onBeforeRender(canvas)).release,
    )

    const onRender = (): void => {
      this.renderManager.emitRenderCallbacks(this)
      if (this.editor.renderMeasures && !!this.measureManager.measureRegion) {
        this.measureManager.reset()
      }
    }

    this.onCleanup.push(this.mainLayer.onRender(() => onRender()).release)

    this.onCleanup.push(this.annotationsLayer.onRender(() => onRender()).release)

    this.onCleanup.push(this.commentLayer.onRender(() => onRender()).release)

    this.onCleanup.push(this.rasterAnnotationLayer.onRender(() => onRender()).release)

    const handleSetAnnotations = (viewEvent: ViewEvent): void => {
      if (viewEvent.viewId !== this.id) {
        return
      }

      if (this.annotationManager.frameIndex === null) {
        throw new Error('AnnotationManager frame index is not set')
      }

      this.annotationsAndFrameSyncManager.annotationsSetForKey(
        `${this.name}_${this.annotationManager.frameIndex}`,
      )
    }

    AnnotationManagerEvents.annotationsSet.on(handleSetAnnotations)
    this.onCleanup.push(() => {
      AnnotationManagerEvents.annotationsSet.off(handleSetAnnotations)
    })

    AnnotationManagerEvents.setParsedData.on(handleSetAnnotations)
    this.onCleanup.push(() => {
      AnnotationManagerEvents.setParsedData.off(handleSetAnnotations)
    })

    const handleAnnotationReorder = (
      toReorder: { id: string; zIndex: number | null },
      reference: { id: string },
      /** needed to determine if you're moving `annotationToReorder`
       * "above" or "below" `referenceAnnotation` */
      direction: 'up' | 'down',
    ): void => {
      const annotationToReorder = this.annotationManager.getAnnotation(toReorder.id)
      if (!annotationToReorder) {
        return
      }
      const renderableToReorder = annotationToRenderableItem(annotationToReorder, {
        frameIndex: this.currentFrameIndex,
        totalFrames: this.totalFrames,
        slotName: this.name,
        isProcessedAsVideo: this.fileManager.isProcessedAsVideo,
        videoAnnotationDuration: this.editor.videoAnnotationDuration,
      })
      const referenceAnnotation = this.annotationManager.getAnnotation(reference.id)
      if (!referenceAnnotation) {
        return
      }
      const referenceRenderable = annotationToRenderableItem(referenceAnnotation, {
        frameIndex: this.currentFrameIndex,
        totalFrames: this.totalFrames,
        slotName: this.name,
        isProcessedAsVideo: this.fileManager.isProcessedAsVideo,
        videoAnnotationDuration: this.editor.videoAnnotationDuration,
      })

      if (!renderableToReorder || !referenceRenderable) {
        return
      }

      this.annotationsLayer.reorderAnnotation(renderableToReorder, referenceRenderable, direction)
    }

    this.annotationManager.on(AnnotationEvents.ANNOTATION_REORDER, handleAnnotationReorder)
    this.onCleanup.push(() =>
      this.annotationManager.off(AnnotationEvents.ANNOTATION_REORDER, handleAnnotationReorder),
    )

    const handleAnnotationUpdate = (
      { viewId }: ViewEvent,
      { annotation }: UpdatedAnnotation,
    ): void => {
      if (viewId !== this.id) {
        return
      }

      if (isAnnotationOutOfView(annotation.data.hidden_areas, this.currentFrameIndex)) {
        this.annotationsLayer.delete(annotation.id)
        return
      }

      const renderableAnnotation = this.getRenderableAnnotation(annotation)
      if (!renderableAnnotation) {
        return
      }

      this.annotationsLayer.update(renderableAnnotation)
    }

    AnnotationManagerEvents.annotationUpdate.on(handleAnnotationUpdate)
    AnnotationManagerEvents.annotationSet.on(handleAnnotationUpdate)
    this.onCleanup.push(() => {
      AnnotationManagerEvents.annotationUpdate.off(handleAnnotationUpdate)
      AnnotationManagerEvents.annotationSet.on(handleAnnotationUpdate)
    })

    const handleAnnotationCreate = async (
      { viewId }: ViewEvent,
      annotation: Annotation,
    ): Promise<void> => {
      if (viewId !== this.id) {
        return
      }
      const annotationId = annotation.id

      clearAnnotationRenderingCache(annotationId)

      if (isAnnotationOutOfView(annotation.data.hidden_areas, this.currentFrameIndex)) {
        return
      }

      const renderableItem = annotationToRenderableItem(annotation, {
        frameIndex: this.currentFrameIndex,
        totalFrames: this.totalFrames,
        slotName: this.name,
        isProcessedAsVideo: this.fileManager.isProcessedAsVideo,
        videoAnnotationDuration: this.editor.videoAnnotationDuration,
      })
      if (!renderableItem) {
        return
      }

      await this.annotationsLayer.add(renderableItem)
      if (this.annotationManager.selectedAnnotation) {
        this.annotationsLayer.activate(renderableItem.id, { isSelected: true, isLocked: false })
      }
      this.annotationsOverlayLayer.add(this.createAnnotationOverlay(annotationId, NO_OVERLAY_TYPES))
    }

    AnnotationManagerEvents.annotationCreate.on(handleAnnotationCreate)
    AnnotationManagerEvents.annotationPushed.on(handleAnnotationCreate)
    this.onCleanup.push(() => {
      AnnotationManagerEvents.annotationCreate.off(handleAnnotationCreate)
      AnnotationManagerEvents.annotationPushed.off(handleAnnotationCreate)
    })

    this.annotationsAndFrameSyncManager.defineAnnotationsRenderer(
      () => {
        // Redefine Views annotations items
        this.setAnnotations()

        if (this.editor.renderMeasures && !!this.measureManager.measureRegion) {
          this.measureManager.reset()
        }

        this.rasterAnnotationLayer.changed()
      },
      () => this.annotationsLayer.clear(),
    )

    const handleAnnotationDuplicate = async (
      viewEvent: ViewEvent,
      {
        newAnnotation,
      }: {
        sourceAnnotationId: string
        newAnnotation: Annotation
      },
    ): Promise<void> => {
      await handleAnnotationCreate(viewEvent, newAnnotation)
    }

    AnnotationManagerEvents.annotationDuplicate.on(handleAnnotationDuplicate)
    this.onCleanup.push(() =>
      AnnotationManagerEvents.annotationDuplicate.off(handleAnnotationDuplicate),
    )

    const handleAnnotationDelete = (_: ViewEvent, annotationId: string): void => {
      this.annotationsLayer.delete(annotationId)
      this.annotationsOverlayLayer.delete(`overlay_${annotationId}`)
      this.measureManager.removeOverlayForAnnotation(annotationId)
    }

    AnnotationManagerEvents.annotationDelete.on(handleAnnotationDelete)
    this.onCleanup.push(() => AnnotationManagerEvents.annotationDelete.off(handleAnnotationDelete))

    const handleAnnotationsDelete = (viewEvent: ViewEvent, annotationIds: string[]): void => {
      annotationIds.forEach((annotationId) => handleAnnotationDelete(viewEvent, annotationId))
    }

    AnnotationManagerEvents.annotationsDelete.on(handleAnnotationsDelete)
    this.onCleanup.push(() =>
      AnnotationManagerEvents.annotationsDelete.off(handleAnnotationsDelete),
    )
    const handleCommentsChanged = ({ viewId }: ViewEvent): void => {
      if (viewId !== this.id) {
        return
      }
      this.defineCommentThreadsRender()
    }

    CommentManagerBusEvents.threadsChanged.on(handleCommentsChanged)
    this.onCleanup.push(() => CommentManagerBusEvents.threadsChanged.off(handleCommentsChanged))

    CommentManagerBusEvents.threadsChanged.on(handleCommentsChanged)
    this.onCleanup.push(() => CommentManagerBusEvents.threadsChanged.off(handleCommentsChanged))

    const handleFileLoading = (): void => {
      this.loading = true
    }
    this.fileManager.on(FileManagerEvents.FILE_LOADING, handleFileLoading)
    this.onCleanup.push(() =>
      this.fileManager.off(FileManagerEvents.FILE_LOADING, handleFileLoading),
    )

    const handleFileLoaded = (): void => {
      if (this.fileManager.metadata?.width && this.fileManager.metadata?.width) {
        this.camera.setImage({
          width: this.fileManager.metadata.width,
          height: this.fileManager.metadata.height,
        })
      }

      this.loading = false
      this.init()
    }
    this.fileManager.on(FileManagerEvents.FILE_LOADED, handleFileLoaded)
    this.onCleanup.push(() => this.fileManager.off(FileManagerEvents.FILE_LOADED, handleFileLoaded))

    CommentManagerBusEvents.threadSelected.on(this.onSelectThread)
    this.onCleanup.push(() => CommentManagerBusEvents.threadSelected.off(this.onSelectThread))

    CommentManagerBusEvents.threadDeselected.on(this.changeCommentLayer)
    this.onCleanup.push(() => CommentManagerBusEvents.threadDeselected.off(this.changeCommentLayer))

    CommentManagerBusEvents.threadVisibilityChanged.on(this.onThreadVisibilityChange)
    this.onCleanup.push(() =>
      CommentManagerBusEvents.threadVisibilityChanged.off(this.onThreadVisibilityChange),
    )

    const handleVisibility = (e: ViewEvent, annIds: string[]): void => {
      if (e.viewId !== this.id) {
        return
      }

      this.annotationsLayer.showAll()
      this.annotationsLayer.hideAll(annIds)

      this.rasterAnnotationLayer.showAll()
      this.rasterAnnotationLayer.hideAll(annIds)
    }
    AnnotationManagerEvents.setHiddenAnnotations.off(handleVisibility)
    AnnotationManagerEvents.setHiddenAnnotations.on(handleVisibility)

    this.onCleanup.push(() => AnnotationManagerEvents.setHiddenAnnotations.off(handleVisibility))
  }

  protected get supportsImageAndAnnotationsSync(): boolean {
    return (
      !this.editor.syncVideoPlayback &&
      (this.type === ViewTypes.VIDEO || this.type === ViewTypes.STREAM)
    )
  }

  get supportsChannels(): boolean {
    return this.type === ViewTypes.IMAGE || this.type === ViewTypes.TILED
  }

  private onSelectThread = ({ viewId }: ViewEvent): void => {
    if (viewId !== this.id) {
      return
    }
    this.editor.toolManager.activateTool('commentator')

    this.changeCommentLayer({ viewId })
  }

  private changeCommentLayer = ({ viewId }: ViewEvent): void => {
    if (viewId !== this.id) {
      return
    }
    this.commentLayer.changed()
  }

  private onThreadVisibilityChange = ({ viewId }: ViewEvent): void => {
    if (viewId !== this.id) {
      return
    }
    this.defineCommentThreadsRender()
  }

  get isMultiPane(): boolean {
    return (this.fileManager.item.slots.length || 0) > 1
  }

  get isMultiSlots(): boolean {
    return this.isMultiPane || this.type === ViewTypes.DICOM || this.type === ViewTypes.LEGACY_DICOM
  }

  get isMainLayerVtk(): boolean {
    return this._mainLayerVtk
  }

  get isLoading(): boolean {
    return this.loading
  }

  get isLoaded(): boolean {
    return !this.loading
  }

  get width(): number {
    return this.mainLayer.canvas?.width || 0
  }

  get height(): number {
    return this.mainLayer.canvas?.height || 0
  }

  abstract init(): void

  get lastFrameIndex(): number {
    return this.framesIndexes[this.framesIndexes.length - 1] || 0
  }

  get totalFrames(): number {
    return this.fileManager.totalSections
  }

  get currentViewSize(): { width: number; height: number } | null {
    return this._currentFrame
      ? { width: this._currentFrame.data.width, height: this._currentFrame.data.height }
      : null
  }

  public render(): void {
    this.layers.forEach((layer) => {
      layer.render()
    })
  }

  get toolManager(): ToolManager {
    return this.editor.toolManager
  }

  get actionManager(): ActionManager {
    return this.editor.actionManager
  }

  get layers(): Layers {
    const layers: Layers = [
      this.mainLayer,
      this.annotationsLayer,
      this.annotationsOverlayLayer,
      this.commentLayer,
    ]

    layers.push(this.rasterAnnotationLayer)

    if (this.htmlImageLayer) {
      layers.push(this.htmlImageLayer)
    }

    return layers
  }

  public async reloadCurrentFrame(): Promise<void> {
    this.currentFrame = await this.loadFrame(this.currentFrameIndex, FrameLoaderSource.HQ)
  }

  public allLayersChanged(): void {
    this.layers.forEach((layer) => layer.changed())
  }

  get canvasContainer(): DocumentFragment {
    const documentFragment = document.createDocumentFragment()
    this.layers.forEach((layer) => {
      documentFragment.appendChild(layer.element)
    })

    return documentFragment
  }

  get isActive(): boolean {
    return this === this.editor.layout.activeView
  }

  /**
   * Max/Min Window levels range of the current file
   */
  get windowLevelsRange(): WindowLevels {
    const { isLoaded } = this
    const { metadata } = this.fileManager

    if (isLoaded) {
      return getWindowLevelsRange(metadata?.colorspace)
    }

    return getWindowLevelsRange()
  }

  /**
   * Default window levels value of the current file
   */
  get defaultWindowLevels(): WindowLevels {
    const { isLoaded } = this
    const { metadata } = this.fileManager

    if (isLoaded) {
      return getWindowLevelsRange(metadata?.colorspace, metadata?.default_window)
    }

    return getWindowLevelsRange()
  }

  get cameraScale(): number {
    return this.camera.scale
  }

  public updateCameraDimensions(width?: number, height?: number): void {
    if (!this.annotationsLayer.canvas) {
      return
    }
    this.camera.setDimensions(
      width || this.annotationsLayer.canvas.clientWidth,
      height || this.annotationsLayer.canvas.clientHeight,
    )
  }

  public zoomTo(topLeft: IPoint, bottomRight: IPoint, scale?: number): void {
    if (
      !(
        Number.isFinite(topLeft.x) &&
        Number.isFinite(topLeft.y) &&
        Number.isFinite(bottomRight.x) &&
        Number.isFinite(bottomRight.y)
      )
    ) {
      return
    }

    this.allLayersChanged()
    this.camera.zoomToBox(
      this.camera.imageViewToCanvasView(topLeft),
      this.camera.imageViewToCanvasView(bottomRight),
      scale,
    )
  }

  public initCamera(): void {
    this.camera.init()
    this.allLayersChanged()

    this.onCameraOffsetChanged(
      {
        viewId: this.id,
        slotName: this.name,
      },
      this.camera.offset,
    )
    this.onCameraScaleChanged(
      {
        viewId: this.id,
        slotName: this.name,
      },
      this.camera.scale,
    )

    CameraEvents.offsetChanged.on(this.onCameraOffsetChanged)
    this.onCleanup.push(() => CameraEvents.offsetChanged.off(this.onCameraOffsetChanged))

    CameraEvents.scaleChanged.on(this.onCameraScaleChanged)
    this.onCleanup.push(() => CameraEvents.scaleChanged.off(this.onCameraScaleChanged))

    this.htmlImageLayer?.setImageDimensions(this.camera.image.width, this.camera.image.height)
  }

  private onCameraOffsetChanged = (e: CameraEvent, offset: CameraOffset): void => {
    if (e.slotName !== this.name) {
      return
    }

    this.htmlImageLayer?.setOffset(offset)
  }

  private onCameraScaleChanged = (e: CameraEvent, scale: number): void => {
    if (e.slotName !== this.name) {
      return
    }

    this.htmlImageLayer?.setScale(scale)
  }

  public scaleToFit(): void {
    this.camera.initialScaleToFit()
    this.allLayersChanged()
  }

  public resetZoom(): void {
    this.camera.scaleToFit()
    this.allLayersChanged()
  }

  public resetCameraOffset(): void {
    this.camera.updateOffset()
    this.allLayersChanged()
  }

  /**
   * Zoom to an annotation automatically scaling to include it within the view.
   * It's also possible to pass a specific scale to better control the final zoom level.
   */
  public zoomToAnnotation(annotation: Annotation): void {
    const vertices = getAllAnnotationVertices(annotation, this)
    if (vertices.length === 0) {
      return
    }

    // Zooming on a single point will always result in zooming to MAX_SCALE,
    // providing a weird UX, so we pass a custom scale value for keypoints;
    if (vertices.length === 1) {
      const keypointScale = 5
      this.zoomTo(vertices[0], vertices[0], keypointScale)
      return
    }

    const { width, height } = this.camera.image
    const { topLeft, bottomRight } = getZoomWindow(vertices, width, height)
    this.zoomTo(topLeft, bottomRight)
  }

  private _channels: { slotName: SlotName; slot: V2DatasetItemSlot; fileManager: FileManager }[] =
    []
  private _activeChannels: Set<SlotName> = new Set()

  get activeChannels(): ViewChannel[] {
    return this._channels.filter((channel) => this._activeChannels.has(channel.slotName))
  }

  get channels(): {
    slotName: SlotName
    slot: V2DatasetItemSlot
    fileManager: FileManager
  }[] {
    return this._channels
  }

  public toggleActiveChannels(channelSlotNames: string[]): void {
    this._activeChannels = new Set(channelSlotNames)
  }

  public setImageFilter(
    filter: ImageManipulationFilter,
    updateAllViews = this.isMainLayerVtk,
    slotName = this.fileManager.slotName,
  ): void {
    if (updateAllViews) {
      // Upadte filter for all views
      this.editor.viewsList.forEach((view) => view.setImageFilter(filter, false))
      return
    }
    if (!this.mainLayer.canvas) {
      return
    }

    this.imageFilters[slotName] = filter

    /**
     * Sets filter using css
     * renderManager.setImageFilter sets separate filter using canvases context
     * keep it separate to not multiply props
     * eg. color map and saturation
     */
    this.mainLayer.canvas.style.filter = getCSSFilterString(filter)

    ViewEvents.imageFilterChanged.emit(this.viewEvent, this.imageFilter)

    this.allLayersChanged()

    this.annotationsLayer.setFilters({
      opacity: filter.opacity,
      borderOpacity: filter.borderOpacity,
    })

    if (this.isOnUseImgRendering) {
      this.htmlImageLayer?.setFilters(filter)
    }
  }

  /**
   * Fast jumpToFrame using lq images only
   * Throttled to limit the number of calls for re-render
   * that overloads requestAnimationFrame.
   *
   * @param {number} frameIndex
   * @returns
   */
  async lqJumpToFrame(frameIndex: number): Promise<void> {
    if (this.editor.freezeFrame) {
      EditorExceptions.cannotJumpToFrame.emit(frameIndex)
      return
    }

    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.currentFrameIndex = frameIndex

    // Update the render key for next render (optimisedLayer > renderCached)
    this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)

    this.currentFrame = await this.loadFrame(this.currentFrameIndex, FrameLoaderSource.LQ)

    // jumping between frames changes subAnnotation content so the redraw option is enabled
    this.annotationManager.invalidateAnnotationCache()
    this.commentManager.deselectItem()

    this.allLayersChanged()
  }

  /**
   * Sets active frame by index
   * Throttled to limit the number of calls for re-render
   * that overloads requestAnimationFrame.
   *
   * `contextId` is used, for debug purposes and sentry tracking, to follow through the
   * frame extraction process
   */
  async jumpToFrame(frameIndex: number, contextId?: string): Promise<void> {
    if (this.editor.freezeFrame) {
      EditorExceptions.cannotJumpToFrame.emit(frameIndex)
      return
    }

    if (frameIndex < 0 || frameIndex > this.lastFrameIndex) {
      return
    }

    this.annotationsLayer.setKeyForNextRender(`${this.name}_${frameIndex}`)

    if (this.fileManager.isTiled) {
      return
    }

    this.currentFrameIndex = frameIndex

    // Update the render key for next render (optimisedLayer > renderCached)
    this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)

    if (this.isMainLayerVtk) {
      return
    }

    if (this.fileManager.isHQFrameLoaded(frameIndex)) {
      this.loading = false
      this.currentFrame = await this.loadFrame(
        this.currentFrameIndex,
        FrameLoaderSource.HQ,
        contextId,
      )
      return
    }

    if (!this.fileManager.isFrameLoaded(frameIndex)) {
      this.loading = true
    }

    try {
      const lqFrame = await this.loadFrame(frameIndex, FrameLoaderSource.FROM_INDEX)

      if (this.currentFrameIndex === frameIndex) {
        this.currentFrame = lqFrame
        if (lqFrame) {
          this.camera.setImage({ width: lqFrame.data.width, height: lqFrame.data.height })
        }

        // jumping between frames changes subAnnotation content so the redraw option is enabled
        this.annotationManager.invalidateAnnotationCache()
        this.commentManager.deselectItem()

        this.loading = false
      }
    } catch (e) {
      setContext('error', { error: e })
      console.error('v2 base view, fileManager failed at loadFramesFrom')
    }

    try {
      const hqFrame = await this.loadFrame(frameIndex, FrameLoaderSource.HQ, contextId)

      if (this.currentFrameIndex === frameIndex) {
        this.currentFrame = hqFrame
        if (hqFrame) {
          this.camera.setImage({ width: hqFrame.data.width, height: hqFrame.data.height })
        }

        // jumping between frames changes subAnnotation content so the redraw option is enabled
        this.annotationManager.invalidateAnnotationCache()
        this.commentManager.deselectItem()
      }
      this.loading = false
    } catch {
      // NOTE: This feels very wrong. We should not be swallowing errors like this
      this.loading = false
    }
  }

  /**
   * Loads the frame from the file manager and returns a renderable image.
   * If the file has channels, then it cycles through all active channels  and resolves the
   * blended image data.
   * Accepts a source parameter to either load LQ or HQ frame, or starting from the specified index.
   */
  async loadFrame(
    frameIndex: number,
    source: FrameLoaderSource,
    contextId?: string,
  ): Promise<RenderableImage | undefined> {
    const frameFileManagers =
      this._channels.length > 1 && this.supportsChannels
        ? this.activeChannels.map((channel) => channel.fileManager)
        : [this.fileManager]

    const imageRenderables = await Promise.all(
      frameFileManagers.map(async (fileManager) => {
        let frameLoader
        switch (source) {
          case FrameLoaderSource.HQ:
            frameLoader = fileManager.getHQFrame(frameIndex, contextId)
            break
          case FrameLoaderSource.LQ:
            frameLoader = fileManager.getLQFrame(
              frameIndex,
              {
                fallbackHQFrame: true,
              },
              contextId,
            )
            break
          case FrameLoaderSource.FROM_INDEX:
            frameLoader = fileManager.loadFramesFrom(frameIndex)
            break
        }
        const frameData = await frameLoader
        if (!frameData) {
          return
        }
        const windowLevels =
          this.imageFilters[fileManager.slotName]?.windowLevels || getWindowLevelsRange()
        return {
          image: frameData,
          windowLevels,
          slotName: fileManager.slotName,
        }
      }),
    )

    const blendImagesData: BlendImageData[] = imageRenderables.filter((ir) => !!ir)

    if (!this.shouldBlendChannelsData(blendImagesData)) {
      // No blending needed, we can just return the frame RenderableImage
      return blendImagesData[0]?.image
    }

    return resolveBlendedRenderableImage(blendImagesData)
  }

  private shouldBlendChannelsData(channelsData: BlendImageData[]): boolean {
    if (this.channels.length === 0 && channelsData.length === 1) {
      return false
    }

    if (channelsData.length > 1) {
      return true
    }

    const channel = channelsData[0]
    if (channel.windowLevels.toString() !== this.windowLevelsRange.toString()) {
      return true
    }

    return false
  }

  // TODO: Pull out of editor into app

  protected setAnnotations(): void {
    this.annotationsLayer.setKeyForNextRender(null)

    this.annotationsOverlayLayer.clear()

    this.annotationManager.annotations.forEach((annotation) => {
      if (!this.annotationManager.isHidden(annotation.id) && annotation.type === 'mask') {
        this.addRasterLayerAnnotation(annotation)
      }
    })

    if (this.editor.featureFlags.ANNOTATIONS_PACKAGE) {
      if (!this.annotationManager.annotationsParsedData) {
        return
      }

      this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)
      this.annotationsLayer.replaceAllWithParsedData(this.annotationManager.annotationsParsedData)

      // We will not render the annotations for the current frame if there are no annotations
      // so we can mark it as rendered
      if (!this.annotationManager.frameAnnotations.length) {
        return
      }
    } else {
      const res = this.annotationManager.getRenderableAnnotations(this.currentFrameIndex)

      if (!res.length) {
        // We will not render the annotations for the current frame if there are no annotations
        // so we can mark it as rendered
        this.annotationsAndFrameSyncManager.annotationsRenderedForKey(
          `${this.name}_${this.currentFrameIndex}`,
        )
        this.annotationsLayer.clear()
        return
      }

      this.annotationsLayer.setKeyForNextRender(`${this.name}_${this.currentFrameIndex}`)
      this.annotationsLayer.replaceAll(res)
    }

    if (this.annotationManager.selectedAnnotation) {
      this.annotationsLayer.activate(this.annotationManager.selectedAnnotation.id, {
        isSelected: true,
      })

      if (this.annotationManager.selectedVertexIndex !== null) {
        this.annotationsLayer.activateVertexWithState(
          this.annotationManager.selectedAnnotation.id,
          this.annotationManager.selectedVertexIndex,
          { isSelected: true },
        )
      }
    }
  }

  /**
   * Get renderable annotation for the current frame
   *
   * !IMPORTANT: This method has a side effect of adding the annotation to the annotationsLayer
   * Ticket: DAR-2786
   */
  private getRenderableAnnotation(annotation: Annotation): RenderableItem | undefined {
    if (
      isVideoAnnotation(annotation) &&
      !hasSegmentContainingIndex(annotation.data?.segments, this.currentFrameIndex)
    ) {
      if (this.annotationsLayer.has(annotation.id)) {
        this.annotationsLayer.delete(annotation.id)
      }
      return
    }

    if (isAnnotationOutOfView(annotation.data.hidden_areas, this.currentFrameIndex)) {
      return
    }

    const renderableItem = annotationToRenderableItem(annotation, {
      frameIndex: this.currentFrameIndex,
      totalFrames: this.totalFrames,
      slotName: this.name,
      isProcessedAsVideo: this.fileManager.isProcessedAsVideo,
      videoAnnotationDuration: this.editor.videoAnnotationDuration,
    })
    if (!renderableItem) {
      return
    }

    if (
      !this.annotationsLayer.has(renderableItem.id) &&
      !this.annotationManager.isHidden(renderableItem.id)
    ) {
      this.annotationsLayer.add(renderableItem)
      if (this.annotationManager.isSelected(renderableItem.id)) {
        this.annotationsLayer.activate(renderableItem.id, { isSelected: true })
      }
      return
    }

    return renderableItem
  }

  updateRenderedAnnotation(id: Annotation['id']): void {
    const annotation = this.annotationManager.getAnnotation(id)
    if (!annotation) {
      return
    }

    const renderableAnnotation = this.getRenderableAnnotation(annotation)
    if (!renderableAnnotation) {
      return
    }

    this.annotationsLayer.update(renderableAnnotation)
  }

  protected createAnnotationOverlay(
    annotationId: Annotation['id'],
    noOverlayTypes: string[],
  ): ObjectHTML {
    return new ObjectHTML(`overlay_${annotationId}`, () => {
      if (
        this.toolManager?.currentTool?.name === ToolName.Brush &&
        this.annotationManager.selectedAnnotation?.id === annotationId
      ) {
        return
      }

      const annotation = this.annotationManager.getAnnotation(annotationId)
      if (!annotation) {
        this.annotationsOverlayLayer.delete(annotationId)
        return
      }

      if (
        this.annotationManager.isHidden(annotation.id) ||
        noOverlayTypes.includes(annotation.type)
      ) {
        return
      }
      if (!this.isInViewport(annotation)) {
        return
      }

      return undefined
    })
  }

  protected getRasterId = (annotation: Annotation): string | undefined => {
    let annotationData

    if (isVideoAnnotation(annotation)) {
      const frames = annotation.data.frames
      const firstFrameIndex = Object.keys(frames)[0]

      annotationData = frames[firstFrameIndex]
    } else {
      annotationData = <AnnotationData>annotation.data
    }

    if (annotationData.rasterId === undefined) {
      return
    }

    return annotationData.rasterId
  }

  protected addRasterLayerAnnotation(annotation: Annotation): void {
    if (annotation.type !== 'mask') {
      throw new Error('Attempting to add non mask annotation to raster layer')
    }

    // Get the annotation from the video, to get the raster reference.

    const rasterId = this.getRasterId(annotation)

    if (rasterId === undefined) {
      throw new Error('Mask annotation has no referenced rasterId.')
    }

    this.rasterAnnotationLayer.addRaster(rasterId)
  }

  private defineCommentThreadsRender(): void {
    this.commentLayer.clear()
    this.commentManager.threads.forEach((thread) => {
      if (this.commentManager.isHidden(thread.id)) {
        return
      }

      const renderable = new Object2D(thread.id, (ctx, canvas, drawFn) => {
        // This behavior is not really clear.
        // We intentionally early-return if no draw function is provided, but the renderComment
        // function does not actually use the draw function. In previous version of the code,
        // the draw function was being passed in as the first argument, but it wasn't used by
        // renderComment anyway.
        if (!drawFn) {
          return
        }
        renderCommentThread(this, this.commentLayer, thread)
      })

      this.commentLayer.add(renderable)
    })
  }

  isPointInPath(point: IPoint, path: IPoint[]): boolean {
    return pointIsVertexOfPath(point, path, 5 / this.cameraScale) || pointInPath(point, path)
  }

  isPointInPath2D(path2D: Path2D, point: IPoint): boolean {
    const ctx = this.annotationsLayer.context
    if (!ctx) {
      return false
    }
    return ctx.isPointInPath(path2D, point.x, point.y, 'evenodd')
  }

  findVertexAtPath(
    paths: EditablePoint[][],
    point: IPoint,
    threshold?: number,
  ): EditablePoint | undefined {
    const candidateVertices: EditablePoint[] = []
    const candidateDistances: number[] = []

    for (const path of paths) {
      for (const vertex of path) {
        const distance = euclideanDistance(point, vertex)
        threshold = threshold || 5 / this.cameraScale
        if (distance < threshold) {
          candidateVertices.push(vertex)
          candidateDistances.push(distance)
        }
      }
    }

    if (candidateVertices.length > 0) {
      let vertex = candidateVertices[0]
      let minDistance = candidateDistances[0]
      for (let i = 1; i < candidateVertices.length; i++) {
        if (candidateDistances[i] < minDistance) {
          minDistance = candidateDistances[i]
          vertex = candidateVertices[i]
        }
      }
      return vertex
    }
  }

  public isFrameIndexValid(frameIndex: number): boolean {
    if (!this.isLoaded) {
      return false
    }
    return frameIndex >= 0 && frameIndex < this.totalFrames
  }

  /**
   * Holds a list of components dynamically added by plugins,
   * which are rendered at the same DOM level as the canvas.
   *
   * Components can be positioned absolute so they can be given position at a
   * certain location above the canvas if necessary.
   *
   * Use the public `addComponent` and `removeComponent` functions
   * from the plugin to manage these.
   */
  public components: { id: string; name: string; props: object }[] = []

  /**
   * Add a component to be rendered outside canvas
   */
  public addComponent(params: { id: string; name: string; props: object }): void {
    const { id, name, props } = params
    const index = this.components.findIndex((c) => c.id === id)
    if (index === -1) {
      this.components.push({ id, name, props })
    }
  }

  /**
   * Remove an existing component by specified id
   */
  public removeComponent(id: string): void {
    const index = this.components.findIndex((c) => c.id === id)
    if (index > -1) {
      this.components.splice(index, 1)
    }
  }

  unhighlightAllVertices(): void {
    this.annotationManager.unhighlightVertex()
    this.commentManager.unhighlightItem()
  }

  unhighlightAll(): void {
    this.annotationManager.unhighlightAllAnnotations()
    this.commentManager.unhighlightItem()
  }

  deselectAll(): void {
    this.annotationManager.deselectAllAnnotations()
    this.commentManager.deselectItem()
  }

  // comments

  private callbacksAreActive: boolean = true

  private onDoubleClickCallbacks: CallbackHandleCollection<[MouseEvent]> =
    new CallbackHandleCollection<[MouseEvent]>()

  private onMouseDownCallbacks: CallbackHandleCollection<[MouseEvent]> =
    new CallbackHandleCollection<[MouseEvent]>()

  private onMouseUpCallbacks: CallbackHandleCollection<[MouseEvent]> = new CallbackHandleCollection<
    [MouseEvent]
  >()

  private onMouseMoveCallbacks: CallbackHandleCollection<[MouseEvent]> =
    new CallbackHandleCollection<[MouseEvent]>()

  private onMouseLeaveCallbacks: CallbackHandleCollection<[MouseEvent]> =
    new CallbackHandleCollection<[MouseEvent]>()

  private onGestureStartCallbacks: CallbackHandleCollection<[Event]> = new CallbackHandleCollection<
    [Event]
  >()

  private onGestureChangeCallbacks: CallbackHandleCollection<[Event]> =
    new CallbackHandleCollection<[Event]>()

  private onGestureEndCallbacks: CallbackHandleCollection<[Event]> = new CallbackHandleCollection<
    [Event]
  >()

  private onWheelCallbacks: CallbackHandleCollection<[WheelEvent]> = new CallbackHandleCollection<
    [WheelEvent]
  >()

  private onTouchStartCallbacks: CallbackHandleCollection<[TouchEvent]> =
    new CallbackHandleCollection<[TouchEvent]>()

  private onTouchEndCallbacks: CallbackHandleCollection<[TouchEvent]> =
    new CallbackHandleCollection<[TouchEvent]>()

  private onTouchMoveCallbacks: CallbackHandleCollection<[TouchEvent]> =
    new CallbackHandleCollection<[TouchEvent]>()

  public onOnKeyDownCallbacks: CallbackHandleCollection<[KeyboardEvent]> =
    new CallbackHandleCollection<[KeyboardEvent]>()

  public onOnKeyPressCallbacks: CallbackHandleCollection<[KeyboardEvent]> =
    new CallbackHandleCollection<[KeyboardEvent]>()

  public onOnKeyUpCallbacks: CallbackHandleCollection<[KeyboardEvent]> =
    new CallbackHandleCollection<[KeyboardEvent]>()

  public activateCallbacks(): void {
    this.callbacksAreActive = true
  }

  public deactivateCallbacks(): void {
    this.callbacksAreActive = false
  }

  // Callbacks
  public onDoubleClick(cb: (event: MouseEvent) => void): CallbackHandle {
    return this.onDoubleClickCallbacks.add(cb)
  }

  public onMouseDown(cb: (event: MouseEvent) => void): CallbackHandle {
    return this.onMouseDownCallbacks.add(cb)
  }

  public onMouseUp(cb: (event: MouseEvent) => void): CallbackHandle {
    return this.onMouseUpCallbacks.add(cb)
  }

  public onMouseMove(cb: (event: MouseEvent) => void): CallbackHandle {
    return this.onMouseMoveCallbacks.add(cb)
  }

  public onMouseLeave(cb: (event: MouseEvent) => void): CallbackHandle {
    return this.onMouseLeaveCallbacks.add(cb)
  }

  public onGestureStart(cb: (event: Event) => void): CallbackHandle {
    return this.onGestureStartCallbacks.add(cb)
  }

  public onGestureChange(cb: (event: Event) => void): CallbackHandle {
    return this.onGestureChangeCallbacks.add(cb)
  }

  public onGestureEnd(cb: (event: Event) => void): CallbackHandle {
    return this.onGestureEndCallbacks.add(cb)
  }

  public onWheel(cb: (event: WheelEvent) => void): CallbackHandle {
    return this.onWheelCallbacks.add(cb)
  }

  public onTouchStart(cb: (event: TouchEvent) => void): CallbackHandle {
    return this.onTouchStartCallbacks.add(cb)
  }

  public onTouchEnd(cb: (event: TouchEvent) => void): CallbackHandle {
    return this.onTouchEndCallbacks.add(cb)
  }

  public onTouchMove(cb: (event: TouchEvent) => void): CallbackHandle {
    return this.onTouchMoveCallbacks.add(cb)
  }

  public onKeyDown(cb: (event: KeyboardEvent) => void): CallbackHandle {
    return this.onOnKeyDownCallbacks.add(cb)
  }

  public onKeyPress(cb: (event: KeyboardEvent) => void): CallbackHandle {
    return this.onOnKeyPressCallbacks.add(cb)
  }

  public onKeyUp(cb: (event: KeyboardEvent) => void): CallbackHandle {
    return this.onOnKeyUpCallbacks.add(cb)
  }

  public canvasListeners = {
    dblclick: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onDoubleClickCallbacks.call(event)
      }
    },
    mousedown: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        ViewMouseEvents.mousedown.emit({ viewId: this.id })
        this.onMouseDownCallbacks.call(event)
      }
    },
    mouseup: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        ViewMouseEvents.mouseup.emit({ viewId: this.id })
        this.onMouseUpCallbacks.call(event)
      }
    },
    mousemove: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onMouseMoveCallbacks.call(event)
      }
    },
    mouseleave: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onMouseLeaveCallbacks.call(event)
      }
    },
    touchstart: (event: TouchEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        ViewMouseEvents.mousedown.emit({ viewId: this.id })
        this.onTouchStartCallbacks.call(event)
      }
    },
    touchend: (event: TouchEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        ViewMouseEvents.mouseup.emit({ viewId: this.id })
        this.onTouchEndCallbacks.call(event)
      }
    },
    touchmove: (event: TouchEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onTouchMoveCallbacks.call(event)
      }
    },
    wheel: {
      listener: (event: WheelEvent): void => {
        if (this.callbacksAreActive && this.hitTarget(event)) {
          this.onWheelCallbacks.call(event)
        }
      },
      options: { passive: false },
    },
    gesturestart: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onGestureStartCallbacks.call(event)
      }
    },
    gesturechange: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onGestureChangeCallbacks.call(event)
      }
    },
    gestureend: (event: MouseEvent): void => {
      if (this.callbacksAreActive && this.hitTarget(event)) {
        this.onGestureEndCallbacks.call(event)
      }
    },
  }

  private addPermanentListeners(): void {
    const target = this.editor.embedded
      ? this.annotationsLayer.canvas || window.document.body
      : window.document.body
    target.addEventListener('mousemove', this.canvasListeners.mousemove)
  }

  public addListeners(): void {
    const target = this.editor.embedded
      ? this.annotationsLayer.canvas || window.document.body
      : window.document.body

    target.addEventListener('dblclick', this.canvasListeners.dblclick)

    target.addEventListener('mousedown', this.canvasListeners.mousedown)
    target.addEventListener('mouseup', this.canvasListeners.mouseup)
    target.addEventListener('mouseleave', this.canvasListeners.mouseleave)
    target.addEventListener('touchstart', this.canvasListeners.touchstart)
    target.addEventListener('touchmove', this.canvasListeners.touchmove)
    target.addEventListener('touchend', this.canvasListeners.touchend)

    target.addEventListener('gesturechange', this.canvasListeners.gesturechange)
    target.addEventListener('gestureend', this.canvasListeners.gestureend)
    target.addEventListener('gesturestart', this.canvasListeners.gesturestart)
    target.addEventListener(
      'wheel',
      this.canvasListeners.wheel.listener,
      this.canvasListeners.wheel.options,
    )
  }

  public removeListeners(includePermanent?: boolean): void {
    const target = this.editor.embedded
      ? this.annotationsLayer.canvas || window.document.body
      : window.document.body

    if (includePermanent) {
      target.removeEventListener('mousemove', this.canvasListeners.mousemove)
    }

    target.removeEventListener('dblclick', this.canvasListeners.dblclick)
    target.removeEventListener('mousedown', this.canvasListeners.mousedown)
    target.removeEventListener('mouseup', this.canvasListeners.mouseup)
    target.removeEventListener('mouseleave', this.canvasListeners.mouseleave)
    target.removeEventListener('touchstart', this.canvasListeners.touchstart)
    target.removeEventListener('touchmove', this.canvasListeners.touchmove)
    target.removeEventListener('touchend', this.canvasListeners.touchend)

    target.removeEventListener('gesturechange', this.canvasListeners.gesturechange)
    target.removeEventListener('gestureend', this.canvasListeners.gestureend)
    target.removeEventListener('gesturestart', this.canvasListeners.gesturestart)
    target.removeEventListener('wheel', this.canvasListeners.wheel.listener)
  }

  public cleanup(): void {
    if (this._currentFrame) {
      if (this._currentFrame.transformedData instanceof HTMLCanvasElement) {
        const ctx = this._currentFrame.transformedData.getContext('2d')
        ctx?.clearRect(
          0,
          0,
          this._currentFrame.transformedData.width,
          this._currentFrame.transformedData.height,
        )
        this._currentFrame.transformedData.remove()
      }
      this._currentFrame.data.remove()

      this._currentFrame.transformedData = null
      this._currentFrame.rawData = null
    }

    this._currentFrame = undefined
    this._currentFrameIndex = 0
    this._activeChannels.clear()
    this.layers.forEach((layer) => {
      layer.destroy()
    })
    this.fileManager.cleanup()
    this.renderManager.cleanup()
    this.measureManager.cleanup()
    this.camera.cleanup()
    this.removeListeners(true)
    this.annotationManager.cleanup()
    this.onDoubleClickCallbacks.clear()
    this.onMouseDownCallbacks.clear()
    this.onMouseUpCallbacks.clear()
    this.onMouseMoveCallbacks.clear()
    this.onMouseLeaveCallbacks.clear()
    this.onGestureStartCallbacks.clear()
    this.onGestureChangeCallbacks.clear()
    this.onGestureEndCallbacks.clear()
    this.onWheelCallbacks.clear()
    this.onTouchStartCallbacks.clear()
    this.onTouchEndCallbacks.clear()
    this.onTouchMoveCallbacks.clear()
    this.onOnKeyDownCallbacks.clear()
    this.onOnKeyPressCallbacks.clear()
    this.onOnKeyUpCallbacks.clear()
    this.onCleanup.forEach((callback) => callback())
    this.onCleanup = []
  }

  /**
   * Prepares instance to release the memory
   * After the destroy call an instance of the View becomes unusable.
   */
  public destroy(): void {
    this.cleanup()

    CameraEvents.offsetChanged.off(this.onCameraOffsetChanged)
    CameraEvents.scaleChanged.off(this.onCameraScaleChanged)
  }

  public isInViewport(annotation: Annotation): boolean {
    const { camera } = this
    const bbox = getBBox(this, annotation)

    if (!bbox) {
      return true
    }

    const { x, y, width, height } = bbox as BBox
    const halfWidth = width / 2
    const halfHeight = height / 2

    if (x === Infinity || y === Infinity) {
      return true
    }

    return (
      x + halfWidth >= 0 &&
      x - halfWidth <= camera.width &&
      y + halfHeight >= 0 &&
      y - halfHeight <= camera.height
    )
  }

  public hitTarget(event: PointerEvent): boolean {
    return this.layers.some((layer) => event.target === layer.canvas)
  }
}
