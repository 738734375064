import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'

import { LoadingStatus } from '@/store/types'
import type {
  DatasetDetailPayload,
  DatasetExportPayload,
  DatasetPayload,
  V2DatasetItemPayload,
} from '@/store/types'
import type { Dataset } from '@/store/types/Dataset'
import type { DatasetItemFilenamePayload } from '@/store/types/DatasetItemFilenamePayload'
/**
 * A pinia proxy to vuex, intended to serve routes revolging around a single
 * dataset.
 *
 * Holds state and getters to more easily access that one dataset and detailed
 * data around it.
 *
 * This is intended to replace the part of the vuex dataset module that
 * relates to the current dataset, but not the entire module.
 */
export const useDatasetStore = defineStore('dataset', () => {
  const currentDataset: Ref<Dataset> = ref({ id: null })
  const datasetDetails: Ref<DatasetDetailPayload[]> = ref([])
  const datasetExports: Ref<DatasetExportPayload[]> = ref([])
  const datasetItemFilenames: Ref<DatasetItemFilenamePayload[]> = ref([])
  const datasetItemsV2: Ref<{ [id: string]: V2DatasetItemPayload }> = ref({})
  const datasets: Ref<DatasetPayload[]> = ref([])
  const dataTabColumnCount = ref(2)
  const selectedV2ItemIds = ref<string[]>([])
  const selectedAll = ref(false)
  const loadingStatus = ref(LoadingStatus.Unloaded)

  const dataset = computed(
    () => datasets.value.find((d) => d.id === currentDataset.value.id) || null,
  )

  const clearCurrentDataset = (): void => {
    currentDataset.value.id = null
  }

  const pushDataset = (payload: DatasetPayload): void => {
    const index = datasets.value.findIndex((d) => d.id === payload.id)
    if (index > -1) {
      const oldDataset = datasets.value[index]
      datasets.value.splice(index, 1, {
        ...oldDataset,
        ...payload,
        // the single dataset endpoint sets null or [] on these fields, so they get overwritten
        // this is a temporary fix until we migrate to a Pinia store
        num_annotations: payload.num_annotations || oldDataset.num_annotations,
        num_classes: payload.num_classes || oldDataset.num_classes,
        num_images: payload.num_images || oldDataset.num_images,
        num_videos: payload.num_videos || oldDataset.num_videos,
        num_annotators: payload.num_annotators || oldDataset.num_annotators,
        thumbnails: [...(payload.thumbnails || []), ...(oldDataset.thumbnails || [])],
        progress: payload.progress || oldDataset.progress,
      })
    } else {
      datasets.value.push(payload)
    }
  }

  const removeDataset = (datasetId: DatasetPayload['id']): void => {
    const index = datasets.value.findIndex((p) => p.id === datasetId)
    if (index > -1) {
      datasets.value.splice(index, 1)
    }
  }

  const setCurrentDataset = (payload: DatasetPayload): void => {
    pushDataset(payload)
    currentDataset.value.id = payload.id
  }

  const setCurrentDatasetId = (datasetId: DatasetPayload['id']): void => {
    currentDataset.value.id = datasetId
  }

  const setDatasets = (payload: DatasetPayload[]): void => {
    datasets.value = payload.map((dataset) => {
      const oldDataset = datasets.value.find((d) => d.id === dataset.id)

      return {
        ...(oldDataset || {}),
        ...dataset,
      }
    })
  }

  const setDatasetItemFilenames = (payload: DatasetItemFilenamePayload[]): void => {
    datasetItemFilenames.value = payload
  }

  const setSelectedAllItems = (selected: boolean): void => {
    selectedAll.value = selected
  }

  const setSelectedItems = (ids: V2DatasetItemPayload['id'][]): void => {
    selectedV2ItemIds.value = ids
  }

  const deselectAllItems = (): void => {
    selectedV2ItemIds.value = []
    selectedAll.value = false
  }

  const updateV2ItemSelection = (itemIds: string[], selected: boolean): void => {
    selectedV2ItemIds.value = selected
      ? selectedV2ItemIds.value.filter((id) => !itemIds.includes(id)).concat([...itemIds])
      : selectedV2ItemIds.value.filter((id) => !itemIds.includes(id))
  }

  const clearV2DatasetItems = (): void => {
    datasetItemsV2.value = {}
  }

  const pushDatasetDetails = (details: DatasetDetailPayload): void => {
    const index = datasetDetails.value.findIndex((d) => d.id === details.id)

    if (index > -1) {
      datasetDetails.value.splice(index, 1, details)
    } else {
      datasetDetails.value.push(details)
    }
  }

  const setDataTabColumnCount = (columnCount: number): void => {
    dataTabColumnCount.value = columnCount
  }

  const setDatasetExports = (payload: DatasetExportPayload[]): void => {
    datasetExports.value = payload
  }

  const deleteExport = (datasetExportName: string): void => {
    const idx = datasetExports.value.findIndex((d) => d.name === datasetExportName)
    if (idx < 0) {
      return
    }
    datasetExports.value.splice(idx, 1)
  }

  const resetState = (): void => {
    currentDataset.value.id = null
    datasetDetails.value = []
    datasetExports.value = []
    datasetItemFilenames.value = []
    datasetItemsV2.value = {}
    datasets.value = []
    dataTabColumnCount.value = 2
    selectedV2ItemIds.value = []
    selectedAll.value = false
  }

  return {
    /**
     * Returns data for the currently selected dataset in the store.
     * This is usually the dataset whose route we're on and it can be partially
     * loaded. It consists of an id and count details
     */
    currentDataset,
    /**
     * Returns the proper backend payload for whatever dataset is returned
     * by `currentDataset`.
     */
    dataset,
    datasets,
    setDatasets,
    datasetItemFilenames,
    setDatasetItemFilenames,
    clearCurrentDataset,

    /**
     * Insert or replace a dataset in the store
     */
    pushDataset,
    removeDataset,

    /**
     * Ensures the same dataset is set across all places
     * where we have a concept of a current dataset
     */
    setCurrentDataset,

    /**
     * Only sets the id of the current dataset,
     * with no guarantee that the actual dataset is or will be in the store.
     */
    setCurrentDatasetId,

    /**
     * Loading status for the full list of datasets
     */
    loadingStatus,

    /**
     * Indicates if current item selection mode is "all"
     *
     * When this mode is enabled, we assume any action related to seletion, deals
     * with all currently filtered items in the dataset and the `selectedItemIds`
     * field is effectively ignored.
     */
    selectedAll,
    setSelectedAllItems,
    setSelectedItems,
    deselectAllItems,

    /**
     * If `selectedAll: false`, this field holds ids of all items which are
     * currently individually selected. Any action which acts on selected items
     * will affect only items with these ids.
     *
     * If `selectedAll: false`, this field is ignored
     */
    selectedV2ItemIds,
    updateV2ItemSelection,
    clearV2DatasetItems,

    datasetDetails,
    pushDatasetDetails,

    dataTabColumnCount,
    setDataTabColumnCount,

    setDatasetExports,
    deleteExport,
    datasetExports,

    resetState,
  }
})
