import { markRaw } from 'vue'

import {
  FrameManagerEvents,
  FramesLoaderEvents,
  StreamViewEvents,
  EditorEvents,
  type ViewEvent,
} from '@/modules/Editor/eventBus'
import frameExtractor, { FIRST_SEGMENT_INDEX } from '@/modules/Editor/utils/frameExtractor'
import type { FrameSpec } from '@/modules/Editor/utils/frameExtractor'
import { RuntimeCacher } from '@/modules/Editor/utils/runtimeCacher'
import { FramesImageLoader } from '@/modules/Editor/workers/FramesImageLoader/FramesImageLoader'
import { FramesLoaderWorker } from '@/modules/Editor/workers/FramesLoaderWorker/FramesLoaderWorker'
import type { FramesLoaderConfig } from '@/modules/Editor/workers/FramesLoaderWorker/types'
import { setContext, addBreadcrumb } from '@/services/sentry'
// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/types'

import { loadFramesManifests } from '@/backend/darwin/loadFramesManifests'
// eslint-disable-next-line boundaries/element-types
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import type { Frame } from '@/modules/Editor/workers/FramesImageLoader/types'
// eslint-disable-next-line boundaries/element-types
import { parseFramesManifest, type FrameManifest } from '@/modules/Workview/framesManifest'

interface Options {
  /** extract frames on FE if true, otherwise fetch from BE */
  shouldExtractFrames?: boolean
  framesLoaderConfig?: FramesLoaderConfig
  IMAGE_TAG_LOADER?: boolean
}

/**
 * Manager used to load and cache frame images for videos.
 * If shouldExtractFrames = true (default value), it will extract frames from video on frontend.
 * Otherwise it will fetch the frames from backend.
 */
export class FrameManager {
  public manifest: FrameManifest[] = []
  private viewId = ''
  private file: V2DatasetItemSlot
  // These are only keeping information of loaded frames, no caching here
  public frameCacheLQ: RuntimeCacher<RenderableImage> = new RuntimeCacher()
  public frameCacheHQ: RuntimeCacher<RenderableImage> = new RuntimeCacher()

  /** extract frames on FE if true, otherwise fetch from BE */
  private shouldExtractFrames: boolean
  private shouldCacheHQFrames = false

  private framesLoaderWorker: FramesImageLoader | FramesLoaderWorker

  constructor(
    viewId: string,
    file: V2DatasetItemSlot,
    { shouldExtractFrames = true, framesLoaderConfig, IMAGE_TAG_LOADER }: Options,
  ) {
    /** needed for events to be unique to the current view / slot / file */
    this.viewId = viewId
    this.file = file
    this.shouldExtractFrames = shouldExtractFrames
    /** HQ frames must be cached only for views that requires HQ frames only (DICOM) **/
    this.shouldCacheHQFrames = framesLoaderConfig?.hqOnly ?? false
    if (IMAGE_TAG_LOADER) {
      this.framesLoaderWorker = new FramesImageLoader(this.file.id, framesLoaderConfig)
    } else {
      this.framesLoaderWorker = new FramesLoaderWorker(this.file.id, framesLoaderConfig)
    }

    // FramesLoaderEvents.frameLoaded coming from the event bus, so potentially from other slots
    FramesLoaderEvents.frameLoaded.on(this.createAndCacheFrame)
    if (shouldExtractFrames) {
      frameExtractor.loadFFMPEG()
    }

    EditorEvents.cleanup.on(this.onCleanup)
    StreamViewEvents.flushRange.on(this.onFlushRange)
    StreamViewEvents.segmentLoaded.on(this.onSegmentLoaded)
  }

  /**
   * Editor cleaning up means we have new file/s, so we can cleanup current cache
   */
  private onCleanup = (): void => {
    if (this.shouldExtractFrames) {
      // frameExtractor.cleanup() will trigger loadFFMPEG when shouldExtractFrames === false we don't want that
      frameExtractor.cleanup()
    }
  }

  /**
   * Stores video segments into FFMPEG for frame extraction.
   * Because every video loads on frame 0 (which is always part of the first segment),
   * this emits an event when first segment is loaded to notify that the video is ready
   */
  private onSegmentLoaded = async (
    event: ViewEvent,
    segment: {
      binaryData: Uint8Array
      index: string
      segmentFileUrl: string
      start: number
      end: number
    },
  ): Promise<void> => {
    if (!this.shouldExtractFrames) {
      return
    }
    if (event.viewId !== this.viewId) {
      return
    }

    // due to memory leaks initializing multiple views that each emit this event,
    // we need to make sure the segment we're receiving isn't empty
    if (!segment.binaryData) {
      return
    }

    await frameExtractor.loadSegmentInMemory(
      this.viewId,
      segment.index,
      segment.binaryData,
      [segment.start, segment.end],
      segment.segmentFileUrl,
    )

    // emit even on first segment load to notify that the video is ready to be worked on
    if (segment.index === FIRST_SEGMENT_INDEX) {
      FrameManagerEvents.firstSegmentLoaded.emit({
        viewId: this.viewId,
        slotName: this.file.slot_name,
      })
    }
  }

  /**
   * When flushing the range, we need to only target segments from the current view
   */
  private onFlushRange = (_event: unknown, range: { start: number; end: number }): void => {
    frameExtractor.flushRange(range.start, range.end, this.viewId)
  }

  public async loadManifest(): Promise<void> {
    const fileManifests = this.file.metadata?.frames_manifests
    if (fileManifests) {
      addBreadcrumb({
        category: 'FrameManager',
        message: 'loadManifest',
        data: {
          fileId: this.file.id,
          framesManifests: this.file.metadata?.frames_manifests,
        },
      })

      const manifest = await loadFramesManifests(fileManifests[0].url)
      if (!manifest) {
        throw new Error('No frames manifest found')
      }

      this.manifest = parseFramesManifest(manifest)
      FrameManagerEvents.manifestLoaded.emit({ viewId: this.viewId, slotName: this.file.slot_name })
    }
  }

  /**
   * Gets the frame index at a specific time.
   *
   * The index of the frame at the specified time. If the time is before the timestamp of the
   * first frame, it returns 0.
   * If no manifest is present, it throws an error.
   */
  public getFrameIndexAtTime(time: number): number {
    if (!this.manifest.length) {
      throw new Error('Trying to get frame index at time without manifest')
    }

    let closestIndex = 0
    for (let i = 0; i < this.manifest.length; i++) {
      const curr = this.manifest[i]
      const prev = this.manifest[closestIndex]

      const currentTimestampDelta = Math.abs(Number(curr.timestamp) - time)
      const previousTimestampDelta = Math.abs(Number(prev.timestamp) - time)

      if (
        prev &&
        // If the difference between the current frame and the time is smaller than the
        // difference between the previous frame and the time, then the current frame is
        // closer to the time
        currentTimestampDelta < previousTimestampDelta
      ) {
        closestIndex = i
      }

      if (Number(curr.timestamp) > time) {
        return closestIndex
      }
    }
    return closestIndex
  }

  private cacheFrame(index: number, image: RenderableImage, isHQ = false): void {
    const cache = isHQ ? this.frameCacheHQ : this.frameCacheLQ
    cache.setItem(`${index}`, image)
  }

  private async createImage(objectURL: string, quality: 'HQ' | 'LQ'): Promise<RenderableImage> {
    if (!objectURL) {
      throw new Error('trying to create image from empty object URL')
    }

    const renderableImage = await new Promise<RenderableImage>((resolve, reject) => {
      const img = new Image()
      img.src = objectURL
      img.onerror = (e): void => reject(e)
      img.onload = (): void => {
        img.onload = null
        img.onerror = null

        const lastWindowLevels: [number, number] = this.file.metadata?.default_window
          ? [this.file.metadata.default_window.min, this.file.metadata.default_window.max]
          : [0, 0]

        resolve(
          markRaw({
            data: img,
            rawData: null,
            transformedData: null,
            lastWindowLevels,
            lastColorMap: 'default',
            quality,
          }),
        )
      }
    })

    return renderableImage
  }

  private createAndCacheFrame = async (frameData: {
    index: number
    url: string
    id: string
    isHQ: boolean
  }): Promise<void> => {
    const { index, url, id, isHQ } = frameData
    if (id !== this.file.id) {
      // This frame is likely coming from a different slot/file, skip caching it
      return
    }
    const image = await this.createFrame({ index, url, quality: isHQ ? 'HQ' : 'LQ' })
    if (!image) {
      // Error creating the image
      return
    }
    this.cacheFrame(index, image, false)
  }

  private onFrameCreationError = (index: number): undefined => {
    // This is likely to happen if an object URL
    // has been revoked (programmatically or by the browser)
    FramesLoaderEvents.frameInvalid.emit({ index })
    return undefined
  }

  private createFrame = async (frameData: {
    index: number
    url: string
    quality: 'HQ' | 'LQ'
  }): Promise<RenderableImage | undefined> => {
    const { index, url, quality } = frameData
    const image = await this.createImage(url, quality).catch(() => this.onFrameCreationError(index))
    if (image) {
      FrameManagerEvents.framesReady.emit({ viewId: this.viewId, slotName: this.file.slot_name }, [
        index,
      ])
    }

    return image
  }

  public isFrameLoaded(index: number): boolean {
    return this.frameCacheLQ.hasItem(index.toString())
  }

  public isHQFrameLoaded(index: number): boolean {
    return this.frameCacheHQ.hasItem(index.toString())
  }

  public async loadLQFrame(index: number): Promise<RenderableImage | undefined> {
    const cacheHit = this.frameCacheLQ.getItem(index.toString())
    if (cacheHit) {
      // Remove raw data to avoid huge memory usage
      cacheHit.rawData = null
      return cacheHit
    }

    const url = await this.framesLoaderWorker.loadLQFrame(index)
    if (url) {
      const image = await this.createFrame({ index, url, quality: 'LQ' })
      if (image) {
        this.cacheFrame(index, image, false)
      }
      return image
    }
  }

  public getFrameUrl(index: number, contextId?: string): Promise<string | undefined> {
    if (!this.shouldExtractFrames) {
      return this.framesLoaderWorker.loadHQFrame(index)
    }
    if (!this.manifest?.[index]) {
      return Promise.resolve(undefined)
    }

    const { originalIndex, segmentIndex } = this.manifest[index]
    return frameExtractor.extractFrame(this.viewId, originalIndex, segmentIndex, contextId)
  }

  public async loadHQFrame(
    index: number,
    contextId?: string,
  ): Promise<RenderableImage | undefined> {
    const cacheHit = this.frameCacheHQ.getItem(index.toString())
    if (cacheHit) {
      // Remove raw data to avoid huge memory usage
      cacheHit.rawData = null
      return cacheHit
    }

    const url = await this.getFrameUrl(index, contextId)
    if (url) {
      const image = await this.createFrame({ index, url, quality: 'HQ' })
      if (image && this.shouldCacheHQFrames) {
        this.cacheFrame(index, image, true)
      }
      return image
    }
  }

  /**
   * Get information about a specific frame index, for the current view, namely the segment
   * file URL and the index within that segment.
   */
  public async getFrameSpec(index: number): Promise<FrameSpec> {
    if (!this.shouldExtractFrames) {
      throw 'getFrameSpec is only implemented for frame extraction'
    }

    return await frameExtractor.getFrameSpec(
      this.manifest[index].originalIndex,
      this.manifest[index].segmentIndex,
      this.viewId,
    )
  }

  public cleanup(): void {
    this.frameCacheLQ.clear()
    this.frameCacheHQ.clear()
    this.framesLoaderWorker.cleanup()

    EditorEvents.cleanup.off(this.onCleanup)
    StreamViewEvents.flushRange.off(this.onFlushRange)
    StreamViewEvents.segmentLoaded.off(this.onSegmentLoaded)
    FramesLoaderEvents.frameLoaded.off(this.createAndCacheFrame)

    if (this.shouldExtractFrames) {
      this.manifest = []
    }
  }

  // ### LEGACY METHODS NEEDED JUST FOR BACKEND FRAME LOADING ###

  public loadFrames(indices: number[]): void {
    this.framesLoaderWorker.setFramesToLoad(indices).catch((e) => {
      setContext('Frame manager: loadFrames', {
        indices,
        errorMessage: e.message,
      })
      console.error('Not able to set the frames to load')
    })
  }

  public async loadFramesFrom(index: number): Promise<RenderableImage | undefined> {
    if (index < this.file.total_sections - 1) {
      this.framesLoaderWorker.setNextFrameToLoad(index + 1).catch((e) => {
        setContext('Frame manager: setNextFrameToLoad', {
          frameIndex: index + 1,
          errorMessage: e.message,
        })
        console.error('Not able to set the next frame to load')
      })
    }

    return await this.loadLQFrame(index)
  }

  public addFrameURLs(sections: Frame[]): void {
    this.framesLoaderWorker.pushSections(sections)
  }
}
