import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'
import type { Polygon } from '@/modules/Editor/polygonOperations'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

import { strokeStyle } from './strokeStyle'
import type { IPoint } from '@/core/annotations'

const getPatternCanvas = (
  dotWidth: number,
  dotDistance: number,
  color: string,
): HTMLCanvasElement => {
  const patternCanvas = document.createElement('canvas')
  patternCanvas.width = patternCanvas.height = dotWidth + dotDistance

  const ctx = patternCanvas.getContext('2d')
  if (!ctx) {
    throw new Error('Unable to get 2d context for pattern canvas (brush fill)')
  }

  ctx.fillStyle = color
  ctx.beginPath()
  ctx.arc(dotWidth / 2, dotWidth / 2, dotWidth / 2, 0, Math.PI * 2, false)
  ctx.closePath()
  ctx.fill()
  ctx.rotate(45)

  return patternCanvas
}

export const drawBrush = (
  ctx: CanvasRenderingContext2D,
  scale: number,
  offset: IPoint,
  brush: Polygon,
  color: RGBA,
  borderOpacity: number,
): void => {
  if (brush.regions.length === 0) {
    return
  }

  const stroke = strokeStyle(color, borderOpacity, false, false)

  ctx.lineWidth = DEFAULT_LINE_WIDTH
  ctx.strokeStyle = stroke

  const patternCanvas = getPatternCanvas(2, 5, stroke)
  const pattern = ctx.createPattern(patternCanvas, 'repeat')

  if (pattern) {
    const matrix = new DOMMatrix()
    pattern.setTransform(matrix.rotate(-45))
    ctx.fillStyle = pattern
  } else {
    ctx.fillStyle = rgbaString(color, 0.15)
  }

  ctx.beginPath()
  for (const path of brush.regions) {
    const [x, y] = path[0]
    ctx.moveTo(x * scale - offset.x, y * scale - offset.y)
    for (let i = 1; i < path.length; i++) {
      const [x, y] = path[i]
      ctx.lineTo(x * scale - offset.x, y * scale - offset.y)
    }
    ctx.closePath()
  }

  ctx.stroke()
  ctx.fill('evenodd')
}
