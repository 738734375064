export const FeatureName = {
  ASYNC_AUTO_ASSIGNMENT: 'ASYNC_AUTO_ASSIGNMENT',
  ASYNC_BATCH_ACTIONS: 'ASYNC_BATCH_ACTIONS',
  AUTO_LABEL: 'AUTO_LABEL',
  CONSENSUS_STAGE_V2_ALL_TOOLS_ENABLED: 'CONSENSUS_STAGE_V2_ALL_TOOLS_ENABLED',
  EYE_TOOL: 'EYE_TOOL',
  IMAGE_TAG_LOADER: 'IMAGE_TAG_LOADER',
  LEGACY_DICOM: 'LEGACY_DICOM',
  MED_LIGHT_MODE: 'MED_LIGHT_MODE',
  OBLIQUE_PLANES: 'OBLIQUE_PLANES',
  MEDICAL_VOLUME_CACHE: 'MEDICAL_VOLUME_CACHE',
  SYNC_DICOM_PLAYBACK: 'SYNC_DICOM_PLAYBACK',
  DYNAMIC_MPR: 'DYNAMIC_MPR',
  MED_3D_VIEWER: 'MED_3D_VIEWER',
  SHOW_NON_DEFAULT_V1_TEMPLATE_FF: 'SHOW_NON_DEFAULT_V1_TEMPLATE_FF',
  SIMPLE_TABLE: 'SIMPLE_TABLE',
  TEST_FEATURE: 'TEST_FEATURE',
  TEST_FEATURE_2: 'TEST_FEATURE_2',
  WORKSPACE_PRICING: 'WORKSPACE_PRICING',
  _AUTOLABEL_SUPERADMIN: '_AUTOLABEL_SUPERADMIN',
  /**
   * Used to show settings for Auto-Track on the Dataset Settings page.
   */
  AUTOTRACK_OBB_OUTPUT: 'AUTOTRACK_OBB_OUTPUT',
  AUTOTRACK_SKIP_FRAMES: 'AUTOTRACK_SKIP_FRAMES',
  /**
   * @deprecated
   * In use for e2e tests only.
   */
  LONG_VIDEOS: 'LONG_VIDEOS',
  /**
   * @deprecated Technically retired from frontend, but an E2E suite still needs it.
   * Do not use in other cases.
   */
  SENTRY_REPLAY: 'SENTRY_REPLAY',
  USE_IMG_RENDERING: 'USE_IMG_RENDERING',
  WORKLOG_V3_ENABLED: 'WORKLOG_V3_ENABLED',
  FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION: 'FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION',
  REPORTS_V3_DASHBOARD: 'REPORTS_V3_DASHBOARD',
  REPORTS_V1_DISABLED: 'REPORTS_V1_DISABLED',
  ANNOTATIONS_PACKAGE: 'ANNOTATIONS_PACKAGE',
  ENABLE_DEV_CONFIGS: 'ENABLE_DEV_CONFIGS',
  WORKVIEW_COMPROMISES_TO_BOOST_PERFORMANCE: 'WORKVIEW_COMPROMISES_TO_BOOST_PERFORMANCE',
  ANNOTATIONS_PAGINATION_V2: 'ANNOTATIONS_PAGINATION_V2',
  PARALLEL_BLIND_STAGE: 'PARALLEL_BLIND_STAGE',
  AUDIO_WAVE: 'AUDIO_WAVE',
} as const

export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName]

export type FeaturePayload = {
  enabled: boolean
  // removing feature from here will reveal its usage across the codebase
  // TEST_FEATURE and TEST_FEATURE_2 exist purely for test support
  name: FeatureName
}
