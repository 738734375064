// eslint-disable-next-line boundaries/element-types
import type { RenderableImage } from '@/store/types'

import { imageFilterToString } from './imageFilterToString'
import { resolveDicomTransformedImageData } from './utils/resolveTransformedImageData'
import type { View } from './views/view'

const computeXYScalingForIsotropicTransformation = (
  view: View,
): {
  xScale: number
  yScale: number
} => {
  let xScale = 1
  let yScale = 1

  const { metadata } = view.fileManager

  if (metadata?.medical?.handler === 'MONAI') {
    const { width, height, pixelWidth, pixelHeight } = metadata

    if (pixelWidth && pixelHeight) {
      xScale = pixelWidth / width
      yScale = pixelHeight / height
    }
  }

  return { xScale, yScale }
}

export const renderDicomImageOnCanvas = (
  view: View,
  canvas: HTMLCanvasElement,
  image: RenderableImage,
  videoMetadata: { colorspace?: 'RGB' | 'RG16' } | null,
): boolean => {
  // clicker provide tmp canvas so we need to set its size here.
  canvas.width = view.camera.width
  canvas.height = view.camera.height
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return false
  }

  ctx.clearRect(0, 0, view.camera.width, view.camera.height)
  if (!image.data) {
    return false
  }

  // 'default' color map is hardcoded here, since we no longer apply
  // it this way and instead use a css filter.
  const transformedData = resolveDicomTransformedImageData(
    image,
    view.imageFilter.windowLevels,
    'default',
    {
      ...videoMetadata,
      type: 'dicom',
    },
  )
  if (!transformedData) {
    return false
  }

  const { xScale, yScale } = computeXYScalingForIsotropicTransformation(view)
  ctx.imageSmoothingEnabled = !!view.imageFilter.isImageSmoothing
  const [dx, dy, dw, dh] = view.camera.drawImageParams(image.data)
  ctx.filter = imageFilterToString(view.imageFilter)
  ctx.drawImage(transformedData, dx, dy, dw / xScale, dh / yScale)
  ctx.filter = 'none'
  ctx.imageSmoothingEnabled = true

  return true
}
