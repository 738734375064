import type { Editor } from '@/modules/Editor/editor'
import type { AutoAnnotateModel } from '@/modules/Editor/types'
// eslint-disable-next-line boundaries/element-types
import type { ClassMapping } from '@/modules/Workview/classMappingTypes'

export class AutoAnnotateManager {
  private editor: Editor

  constructor(editor: Editor) {
    this.editor = editor
  }

  public get preselectedAutoAnnotateModel(): AutoAnnotateModel | null {
    // Here we mutate in the getter if it is not initialised. We should
    // find a cleaner way to do this.
    // https://linear.app/v7labs/issue/ANN-460/harden-auto-annotate-preselect
    let { preselectedModelId: id } = this.editor

    if (id === null) {
      // Preselect the id and optimisatically get the value to return
      id = this.setPreselectedAutoAnnotateModel()
    }

    return this.editor.autoAnnotateModels.find((m) => m.id === id) || null
  }

  public get clickerEpsilon(): number | undefined {
    return this.editor.clickerEpsilon
  }

  /**
   * Automatically preselects a neural model if one isn't already selected.
   *
   * If a model of type `ModelType.AutoAnnotation` exists, select that, otherwise
   * select the first model among the available ones.
   */
  private setPreselectedAutoAnnotateModel(): string | null {
    const { preselectedModelId } = this.editor
    if (preselectedModelId) {
      return preselectedModelId
    }

    const preselectedDefaultModel = this.editor.preselectDefaultAutoAnnotateModel()
    return preselectedDefaultModel?.id || null
  }

  get preselectedModelClassMapping(): ClassMapping {
    const { preselectedAutoAnnotateModel } = this
    if (!preselectedAutoAnnotateModel) {
      return []
    }

    const autoAnnotateClassMapping = this.editor.autoAnnotateClassMapping
    if (!(preselectedAutoAnnotateModel.id in autoAnnotateClassMapping)) {
      return []
    }

    return autoAnnotateClassMapping[preselectedAutoAnnotateModel.id]
  }
}
