import type { AnnotationData, Skeleton } from '@/modules/Editor/AnnotationData'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { createEditablePoint, subPoints } from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { drawTemporarySkeletonV2 } from '@/modules/Editor/graphicsV2/drawTemporarySkeleton'
import { drawGuideLines } from '@/modules/Editor/graphicsV2/drawGuideLines'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { annotationType } from './consts'

const annotationCreationAction = async (
  context: ToolContext,
  skeleton: AnnotationData,
  classId: number,
): Promise<Action> => {
  const params = { type: annotationType, data: skeleton, classId: classId }

  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to prepare annotation for creation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)

      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export interface SkeletonTool extends Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint

  onStart: (context: ToolContext, event: PointerEvent) => void
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void
}

export const skeletonTool: SkeletonTool = {
  initialPoint: undefined,
  cursorPoint: undefined,

  onStart(context: ToolContext, event: PointerEvent) {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.initialPoint = point
    this.draw(context.editor.activeView)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point
    this.draw(context.editor.activeView)
    if (!this.initialPoint) {
      return
    }
    return CallbackStatus.Stop
  },

  async onEnd(context: ToolContext, event: PointerEvent) {
    if (!this.initialPoint) {
      return
    }

    const point = resolveEventPoint(event, true)
    if (point) {
      this.cursorPoint = point
    }

    if (!this.cursorPoint) {
      return
    }

    const p1 = context.editor.activeView.camera.canvasViewToImageView(this.cursorPoint)
    const p2 = context.editor.activeView.camera.canvasViewToImageView(this.initialPoint)

    const { x: width, y: height } = subPoints(p1, p2)

    const klass = context.editor.activeView.annotationManager.preselectedAnnotationClass

    const skeletonMetaData = klass?.skeletonMetaData
    if (!skeletonMetaData) {
      return
    }

    const nodes = skeletonMetaData.nodes.map((node) => ({
      point: createEditablePoint({ x: p2.x + node.x * width, y: p2.y + node.y * height }),
      name: node.name,
      occluded: false,
    }))

    const skeleton: Skeleton = { nodes }

    try {
      await context.editor.actionManager.do(
        await annotationCreationAction(context, skeleton, klass.id),
      )
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 skeleton tool createAnnotation failed')
    }

    this.reset(context)
    this.draw(context.editor.activeView)
  },

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Skeleton,
      [annotationType],
      'You must create or select an existing Skeleton class before using the skeleton tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.BBox)

    context.editor.registerCommand('skeleton_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
      return CallbackStatus.Stop
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))

    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },

  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      if (this.cursorPoint) {
        drawGuideLines(ctx, view, this.cursorPoint)
      }

      if (this.cursorPoint == null || this.initialPoint == null) {
        return
      }

      ctx.beginPath()
      ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
      ctx.lineWidth = 1
      ctx.strokeRect(
        this.initialPoint.x,
        this.initialPoint.y,
        this.cursorPoint.x - this.initialPoint.x,
        this.cursorPoint.y - this.initialPoint.y,
      )

      if (!view.annotationManager.preselectedAnnotationClass) {
        return
      }

      drawTemporarySkeletonV2(
        ctx,
        this.initialPoint,
        this.cursorPoint,
        view.annotationManager.preselectedAnnotationClass,
      )
    })
  },

  deactivate(context: ToolContext): void {
    context.editor.activeView.annotationsLayer.draw()
  },

  reset() {
    this.initialPoint = undefined
    this.cursorPoint = undefined
  },
}
