import type { Camera } from '@/modules/Editor/camera'
import {
  COMMENT_THREAD_SHADOW_BLUR,
  COMMENT_THREAD_SHADOW_COLOR,
  COMMENT_THREAD_SHADOW_OFFSET_Y,
  COMMENT_THREAD_STROKE_OPACITY,
  COMMENT_THREAD_VERTEX_SIZE,
} from '@/modules/Editor/comment'
import { buildPath2DImageView } from '@/modules/Editor/graphicsV2/buildPath2DImageView'
import type { EditablePoint } from '@/modules/Editor/point'
// eslint-disable-next-line boundaries/element-types
import type { RGBA } from '@/uiKit/colorPalette'
// eslint-disable-next-line boundaries/element-types
import { rgbaString } from '@/uiKit/colorPalette'

const drawVertex = (
  ctx: CanvasRenderingContext2D,
  camera: Camera,
  point: EditablePoint,
  color: RGBA,
  isSelected: boolean,
  convertCoordinates: boolean = true,
): void => {
  if (!isSelected) {
    return
  }
  const { x, y } = convertCoordinates ? camera.imageViewToCanvasView(point) : point

  ctx.fillStyle = rgbaString(color, COMMENT_THREAD_STROKE_OPACITY)
  ctx.shadowColor = COMMENT_THREAD_SHADOW_COLOR
  ctx.shadowBlur = COMMENT_THREAD_SHADOW_BLUR
  ctx.shadowOffsetY = COMMENT_THREAD_SHADOW_OFFSET_Y

  ctx.beginPath()
  ctx.arc(x, y, COMMENT_THREAD_VERTEX_SIZE, 0, 2 * Math.PI)
  ctx.fill()
}

export const drawComment = (
  path: EditablePoint[],
  ctx: CanvasRenderingContext2D,
  camera: Camera,
  color: RGBA,
  isSelected = false,
): void => {
  ctx.strokeStyle = rgbaString(color, 1)

  const path2D = buildPath2DImageView(path)

  // path2d is in imagePoint, we need to convert it to canvas coordinates
  ctx.save()
  camera.imageViewCtxToCanvasViewCtx(ctx)

  // the line width will change at different zoom levels.
  ctx.lineWidth = 2.0 / camera.scale

  ctx.shadowColor = COMMENT_THREAD_SHADOW_COLOR
  ctx.shadowBlur = COMMENT_THREAD_SHADOW_BLUR
  ctx.shadowOffsetY = COMMENT_THREAD_SHADOW_OFFSET_Y

  ctx.stroke(path2D)
  ctx.restore()

  for (const point of path) {
    drawVertex(ctx, camera, point, color, isSelected)
  }
}
