import type { DatasetUploadItemPayload } from '@/store/types'
import {
  DEFAULT_DICOM_RATE,
  DEFAULT_PDF_RATE,
  fileStatus,
  type SetFileDataPayload,
  type SetVideoDataPayload,
  type UploadFile,
  type UploadFileData,
  type UploadVideo,
} from './types'
import { FileType } from '@/modules/AdvancedFilters/FileType'

export const isEqual = (a: File, b: File): boolean =>
  a.name === b.name && a.size === b.size && a.lastModified === b.lastModified

/**
 * load file content for the image thumbnail
 */
export const loadFileContent = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = function (): void {
      /**
       * Cast is safe as the expected result is a base64 string
       * From the docs:
       * The readAsDataURL method is used to read the contents of the specified Blob or File.
       * When the read operation is finished, the readyState becomes DONE, and the loadend
       * is triggered. At that time, the result attribute contains the data as a data: URL
       * representing the file's data as a base64 encoded string.
       */
      resolve(this.result as string)
    }
    fileReader.onerror = (err): void => {
      reject(err)
    }
    fileReader.readAsDataURL(file)
  })

const loadVideoFromLocal = function (file: File & { dataUrl?: string }): Promise<HTMLVideoElement> {
  return new Promise((resolve) => {
    const video = window.document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = function (): void {
      resolve(video)
    }
    if (file.dataUrl) {
      // Manually added file
      video.src = file.dataUrl
    } else {
      video.src = window.URL.createObjectURL(file)
    }
  })
}

const getFrame = function (video: HTMLVideoElement, time: number): Promise<string> {
  return new Promise((resolve) => {
    const canvas = window.document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    video.addEventListener(
      'seeked',
      () => {
        video.pause()
        ctx?.drawImage(video, 0, 0)
        resolve(canvas.toDataURL())
      },
      { once: true },
    )

    video.currentTime = time
  })
}

/**
 * check if the file is a video or not.
 */
export const isVideoFile = (file: File & { kind?: string }): boolean => {
  const fileType = file.type || file.kind
  if (!fileType) {
    // some web browsers won't populate file.type for some file types
    // in those cases, look at the extension.
    const fileName = file.name
    const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
    return ['mkv', 'hevc'].includes(fileExt)
  }
  return !!fileType && fileType.startsWith('video')
}

/**
 * check if the file is a image or not.
 */
const isImageFile = (file: File & { kind?: string }): boolean => {
  const fileType = file.type || file.kind
  return !!fileType && fileType.startsWith('image')
}

/**
 * check if the file is a dcm file --- Dicom
 */
const isDicomFile = (file: File): boolean => file.name.toLowerCase().endsWith('.dcm')

/**
 * check if the file is a nifti file --- NifTi
 */
const isNifTiFile = (file: File): boolean =>
  file.name.toLowerCase().endsWith('.nii') || file.name.toLowerCase().endsWith('.nii.gz')

/**
 * check if the file is a rvg file --- Dicom
 */
const isRVGFile = (file: File): boolean => file.name.toLowerCase().endsWith('.rvg')

/**
 * check if the file is a pdf file --- PDF
 */
const isPdfFile = (file: File): boolean => file.name.toLowerCase().endsWith('.pdf')

/**
 * Checks a files category, which is one of
 * image
 * video
 * other
 */
export const getFileCategory = (file: File): 'video' | 'image' | 'dicom' | 'pdf' | 'other' => {
  if (isVideoFile(file)) {
    return 'video'
  }
  if (isImageFile(file)) {
    return 'image'
  }
  if (isDicomFile(file)) {
    return 'dicom'
  }
  if (isRVGFile(file)) {
    return 'dicom'
  }
  if (isNifTiFile(file)) {
    return 'dicom'
  }
  if (isPdfFile(file)) {
    return 'pdf'
  }
  return 'other'
}

export const loadVideo = async (file: File): Promise<{ duration: number; frames: string[] }> => {
  const video = await loadVideoFromLocal(file)

  const extractTime = video.duration * 0.8
  const times = [extractTime * 0.1, extractTime * 0.5, extractTime * 0.9]
  const frames = await Promise.all(times.map((t) => getFrame(video, t)))

  window.URL.revokeObjectURL(video.src)

  return { duration: video.duration, frames }
}

export const isUploadVideo = (uploadFile: UploadFile): uploadFile is UploadVideo =>
  uploadFile.data.category === FileType.Video

export const isUploadDicom = (uploadFile: UploadFile): uploadFile is UploadVideo =>
  uploadFile.data.category === FileType.Dicom

export const isUploadPdf = (uploadFile: UploadFile): uploadFile is UploadVideo =>
  uploadFile.data.category === FileType.Pdf

export const isVideoDataPayload = (
  dataPayload: SetFileDataPayload,
): dataPayload is SetVideoDataPayload => dataPayload.uploadFile.data.category === FileType.Video

export const toUploadFile = (
  file: File,
  setId: number,
  tags?: string[],
  path?: string,
  framerate?: number,
): UploadFile => {
  const category = getFileCategory(file)
  const data: UploadFileData = {
    category,
    setId,
    status: fileStatus.ADDED,
    signingURL: null,
    sentBytes: 0,
    tags,
    path,
    framerate,
    totalBytes: file.size,
  }

  return { file, data }
}

/**
 * Merges a `UploadFile[]` collection with an optional path and tag and
 * converts to payload expected by the backend register endpoint.
 */
export const getDatasetUploadItemPayloads = (
  uploadFiles: UploadFile[],
  path?: string,
  tags?: string[],
): DatasetUploadItemPayload[] =>
  uploadFiles.map((uploadFile) => {
    const fileParam: DatasetUploadItemPayload = { file_name: uploadFile.file.name }
    if (path && path !== '') {
      fileParam.path = path
    }
    if (tags && tags.length > 0) {
      fileParam.tags = tags
    }

    if (isUploadVideo(uploadFile)) {
      fileParam.fps = uploadFile.data.extractRate
      fileParam.as_frames = uploadFile.annotateAsFrames
    } else if (isUploadDicom(uploadFile)) {
      fileParam.fps = DEFAULT_DICOM_RATE
      fileParam.extract_views = uploadFile.data.extractViews
      fileParam.as_frames = false
    } else if (isUploadPdf(uploadFile)) {
      fileParam.fps = DEFAULT_PDF_RATE
      fileParam.as_frames = false
    }

    return fileParam
  })
