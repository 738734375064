import type { AnnotationData, BoundingBox } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import {
  calculateBoundingBoxMeasures,
  toFullOverlayData,
} from '@/modules/Editor/annotationMeasures'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import { POINT_CLICK_THRESHOLD } from '@/modules/Editor/constants'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { createEditablePoint } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { drawGuideLines } from '@/modules/Editor/graphicsV2/drawGuideLines'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Action } from '@/modules/Editor/managers/actionManager'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { BOUNDING_BOX_ANNOTATION_TYPE } from './types'

interface BoundingBoxTool extends Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint
  saving: boolean
  onStart: (context: ToolContext, event: PointerEvent) => void
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void
}

const annotationCreationAction = async (
  context: ToolContext,
  bbox: AnnotationData,
): Promise<Action> => {
  const params = { type: BOUNDING_BOX_ANNOTATION_TYPE, data: bbox }
  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to create annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)

      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export const createBoundingBoxData = (
  context: ToolContext,
  p1: IPoint,
  p2: IPoint,
): BoundingBox | null => {
  const imageP1 = context.editor.activeView.camera.canvasViewToImageView(p1)
  const imageP2 = context.editor.activeView.camera.canvasViewToImageView(p2)
  const box = new Rectangle(imageP1, imageP2)

  if (!box.isValid()) {
    return null
  }

  const boundingBox: BoundingBox = {
    topLeft: createEditablePoint(box.topLeft),
    topRight: createEditablePoint(box.topRight),
    bottomRight: createEditablePoint(box.bottomRight),
    bottomLeft: createEditablePoint(box.bottomLeft),
  }

  return boundingBox
}

export const updateDrawingMeasuresOverlay = (
  context: ToolContext,
  initialPoint: IPoint,
  cursorPoint: IPoint,
): void => {
  const boundingBox = createBoundingBoxData(context, cursorPoint, initialPoint)
  if (boundingBox) {
    const view = context.editor.activeView
    const measureData = toFullOverlayData(
      calculateBoundingBoxMeasures(boundingBox, view.camera, view.measureManager.measureRegion),
      view.annotationManager.preselectedAnnotationClassColor(),
    )

    if (measureData) {
      context.editor.activeView.measureManager.updateOverlayForDrawingAnnotation(measureData)
    } else {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  } else {
    context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
  }
}

export const boundingBoxTool: BoundingBoxTool = {
  initialPoint: undefined,
  cursorPoint: undefined,
  saving: false,

  /**
   * Uses OptimisedLayer to draw a bounding box
   * works only with LayerV2 feature flag enabled
   * @param view
   */
  draw(view) {
    view.annotationsLayer.draw((ctx) => {
      if (this.cursorPoint) {
        drawGuideLines(ctx, view, this.cursorPoint)
      }

      if (this.cursorPoint == null || this.initialPoint == null) {
        return
      }

      ctx.beginPath()
      ctx.strokeStyle = view.annotationManager.preselectedAnnotationClassColor()
      ctx.fillStyle = view.annotationManager.preselectedAnnotationClassColor(0.15)
      ctx.lineWidth = 1

      const x = this.initialPoint.x
      const y = this.initialPoint.y
      const w = this.cursorPoint.x - this.initialPoint.x
      const h = this.cursorPoint.y - this.initialPoint.y
      ctx.strokeRect(x, y, w, h)
      ctx.fillRect(x, y, w, h)
    })
  },

  onStart(context: ToolContext, event: PointerEvent) {
    if (this.saving) {
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    if (!this.initialPoint) {
      this.initialPoint = point
    }
    this.draw(context.editor.activeView)
  },

  onMove(context: ToolContext, event: PointerEvent) {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    if (this.saving) {
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point
    this.draw(context.editor.activeView)
    if (!this.initialPoint) {
      return
    }

    if (context.editor.renderMeasures) {
      updateDrawingMeasuresOverlay(context, this.initialPoint, this.cursorPoint)
    }
    return CallbackStatus.Stop
  },

  async onEnd(context: ToolContext, event: PointerEvent) {
    if (this.saving) {
      return CallbackStatus.Stop
    }

    const point = resolveEventPoint(event, true)
    if (point !== null) {
      this.cursorPoint = point
    }

    if (!this.initialPoint || !this.cursorPoint) {
      return
    }

    if (euclideanDistance(this.initialPoint, this.cursorPoint) < POINT_CLICK_THRESHOLD) {
      return
    }

    const boundingBox = createBoundingBoxData(context, this.cursorPoint, this.initialPoint)

    if (!boundingBox) {
      this.initialPoint = undefined
      this.cursorPoint = undefined
      this.draw(context.editor.activeView)
      return
    }

    try {
      this.saving = true
      await context.editor.actionManager.do(await annotationCreationAction(context, boundingBox))
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 bounding box tool, annotationManager failed to create')
    } finally {
      this.reset(context)

      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
    }
    this.draw(context.editor.activeView)
  },

  async activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.BoundingBox,
      [BOUNDING_BOX_ANNOTATION_TYPE],
      'You must create or select an existing Bounding Box class before using the brush tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.BBox)

    context.editor.registerCommand('bounding_box_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))
  },
  deactivate(context: ToolContext) {
    this.reset(context)
    context.editor.activeView.annotationsLayer.draw()
  },
  reset(context: ToolContext) {
    this.initialPoint = undefined
    this.cursorPoint = undefined
    this.saving = false

    if (context.editor.renderMeasures) {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  },
}
