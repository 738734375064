import { defineComposable } from '@/core/utils/defineComposable'
// eslint-disable-next-line no-restricted-syntax
import MediaInfoFactory, { type MediaInfo, type ReadChunkFunc, type VideoTrack } from 'mediainfo.js'
import { ref } from 'vue'

export const useMediaInfo = defineComposable(() => {
  const mediaInfoFactory = ref<MediaInfo<'object'> | null>(null)

  const prefetchMediaInfo = async (): Promise<void> => {
    const mi = await MediaInfoFactory({
      format: 'object',
      locateFile: () => '/MediaInfoModule.wasm',
    })
    mediaInfoFactory.value = mi
    await mi.close()
  }

  const extractVideoFPS = async (file: File): Promise<number | undefined> => {
    const getSize = (): number => file.size

    const readChunk: ReadChunkFunc = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event: ProgressEvent<FileReader>): void => {
          if (event.target?.error) {
            reject(event.target.error)
          }

          resolve(new Uint8Array(event.target?.result as ArrayBuffer))
        }

        // load data in chunks to prevent memory exhaustion
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })

    const metadata = await mediaInfoFactory.value?.analyzeData(getSize, readChunk)
    const fps = metadata?.media?.track.find(
      (t): t is VideoTrack => t['@type'] === 'Video',
    )?.FrameRate
    return fps
  }

  const init = (): void => {
    prefetchMediaInfo()
  }

  const closeMediaFile = (): void => {
    mediaInfoFactory.value?.close()
  }

  return {
    mediaInfoFactory,
    prefetchMediaInfo,
    extractVideoFPS,
    init,
    closeMediaFile,
  }
})
