import { BlockedReason } from '@/store/types'
import { pluralize } from '@/core/utils/pluralize'

export const getBlockedFilesMessage = (
  blockedFileNames: {
    name: string
    reason?: BlockedReason | undefined
  }[],
): string => {
  const alreadyUploaded = blockedFileNames
    .filter((f) => f.reason === BlockedReason.ALREADY_EXISTS)
    .map((f) => f.name)
  const limitExceeded = blockedFileNames
    .filter((f) => f.reason === BlockedReason.ITEM_COUNT_LIMIT_EXCEEDED)
    .map((f) => f.name)

  return [
    alreadyUploaded.length > 0
      ? `Ignoring already uploaded ${pluralize(
          alreadyUploaded.length,
          'file',
          'files',
        )}: ${alreadyUploaded.slice(0, 5).join(', ')}`
      : '',
    alreadyUploaded.length > 5 ? ` and ${alreadyUploaded.length - 5} more` : '',
    alreadyUploaded.length > 0 && limitExceeded.length > 0 ? '\n' : '',
    limitExceeded.length > 0
      ? `Ignoring ${pluralize(
          limitExceeded.length,
          'file',
          'files',
        )} above the limit: ${limitExceeded.slice(0, 5).join(', ')}`
      : '',
    limitExceeded.length > 5 ? ` and ${limitExceeded.length - 5} more` : '',
  ]
    .filter((s) => s.length > 0)
    .join('')
}
