import type { DatasetUploadedItemsPayload, DatasetUploadItemPayload } from '@/store/types'
import { post } from '@/backend/api'
import { isErrorResponse, errorMessages, parseError } from '@/backend/error'
import type { ApiResult } from './types'
import type { components } from './api'

type Params = {
  items: DatasetUploadItemPayload[]
  tags: string[]
  path: string
  teamSlug: string
  datasetSlug: string
}

/**
 * Sends a request to the backend, to register files for upload
 */
export const registerUpload = async (
  params: Params,
): Promise<ApiResult<DatasetUploadedItemsPayload>> => {
  const path = `v2/teams/${params.teamSlug}/items/register_upload`

  const payload: components['schemas']['DatasetsV2.ItemRegistration.RegisterNewItemsRequest'] = {
    dataset_slug: params.datasetSlug,
    items: params.items.map((item) => ({
      slots: [
        {
          as_frames: item.as_frames,
          extract_views: item.extract_views,
          tags: params.tags,
          file_name: item.file_name,
          fps: item.fps,
          slot_name: '0',
        },
      ],
      name: item.file_name,
      layout: null,
      path: params.path,
      tags: params.tags,
    })),
  }

  try {
    const response = await post<
      components['schemas']['DatasetsV2.ItemRegistration.RegistrationResponse']
    >(path, payload)
    return { ok: true, data: response.data as DatasetUploadedItemsPayload }
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return { ...parseError(error, errorMessages.DATASET_UPDATE), ok: false }
  }
}
