export const fileStatus = {
  ADDED: 'added',
  CONFIRMING: 'confirming',
  QUEUED: 'queued',
  SIGNING: 'signing',
  SIGNED: 'signed',
  UPLOADING: 'uploading',
  REPORTING: 'reporting',
  UPLOADED_REPORTED: 'uploaded-reported',
  ERROR_SIGNING: 'error-signing',
  ERROR_UPLOADING: 'error-uploading',
  ERROR_REPORTING: 'error-reporting',
} as const
export type FileStatus = (typeof fileStatus)[keyof typeof fileStatus]

export type UploadFileData = {
  /**
   * Indicates if backend has blocked this file during upload
   * due to some issue, for example, if it was detected as duplicate.
   */
  blocked?: boolean
  category: 'image' | 'video' | 'dicom' | 'pdf' | 'other'
  extractViews?: boolean
  dataURL?: string
  duration?: number
  framerate?: number
  extractRate?: number | 'native'
  datasetItemId?: number
  path?: string
  setId: number
  signingURL: string | null
  status: FileStatus
  tags?: string[]
  thumbs?: string[]
  sentBytes: number
  totalBytes: number
  teamSlug?: string
  datasetSlug?: string
  uploadId?: string
}

export interface UploadImage {
  data: UploadFileData
  file: File
}

export interface UploadVideo {
  data: UploadFileData
  file: File
  annotateAsFrames: boolean
}

export type UploadFile = UploadImage | UploadVideo

export type UploadFileWithData = { uploadFile: UploadFile; data: Partial<UploadFileData> }

export const DEFAULT_DICOM_RATE = 30
export const DEFAULT_PDF_RATE = 30

export const uploadStatus = {
  STARTED: 'started',
  STOPPED: 'stopped',
} as const
export type UploadStatus = (typeof uploadStatus)[keyof typeof uploadStatus]

export type SetImageDataPayload = {
  uploadFile: UploadFile
  data: Partial<UploadFileData>
}

export type SetVideoDataPayload = {
  uploadFile: UploadFile
  data: Partial<UploadFileData>
  annotateAsFrames: boolean
}

export type SetFileDataPayload = SetImageDataPayload | SetVideoDataPayload

export type UploadFileSet = {
  id: number
  files: UploadFile[]
}

export const TERMINAL_STATUSES: FileStatus[] = [
  'error-signing',
  'error-uploading',
  'error-reporting',
  'uploaded-reported',
]
