export const setCurrentFrameQuality = (canvas: HTMLCanvasElement, quality: 'HQ' | 'LQ'): void => {
  canvas.dataset.currentFrameQuality = quality
}

/**
 * Set the accessibility attribute for the current frame index on the canvas element.
 */
export const setCurrentFrameForCanvas = (canvas: HTMLCanvasElement, frameIndex: number): void => {
  const frameIndexVal = frameIndex.toString()
  if (canvas.dataset.currentFrameIndex !== frameIndexVal) {
    canvas.dataset.currentFrameIndex = frameIndexVal
  }
}
