import { v4 as uuidv4 } from 'uuid'

import { onMacOS } from '@/core/utils/browser'
import type { AnnotationData, AutoAnnotateData } from '@/modules/Editor/AnnotationData'
import { euclideanDistance, maybeSimplifyPolygon } from '@/modules/Editor/algebra'
import { calcCentroid } from '@/modules/Editor/annotationCentroid'
import type { InferenceResult, Click } from '@/modules/Editor/backend'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { Camera, CameraEvent } from '@/modules/Editor/camera'
import { POINT_CLICK_THRESHOLD } from '@/modules/Editor/constants'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { CameraEvents, EditorEvents, ToolEvents } from '@/modules/Editor/eventBus'
import type { ActionGroup } from '@/modules/Editor/managers/actionManager'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint } from '@/modules/Editor/point'
import { addPoints, subPoints, pointInPath } from '@/modules/Editor/point'
import { Rectangle } from '@/modules/Editor/rectangle'
import { resolveRelativeEpsilon } from '@/modules/Editor/resolveEpsilon'
import { getDicomFrameParams } from '@/modules/Editor/utils/radiology/getDicomFrameParams'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { addAnnotationsAction } from '@/modules/Editor/actions/addAnnotationsAction'
import type { Editor } from '@/modules/Editor/editor'
import { drawDashedBox } from '@/modules/Editor/graphicsV2/drawDashedBox'
import { drawGuideLines } from '@/modules/Editor/graphicsV2/drawGuideLines'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { createAnnotationFromInferenceData } from '@/modules/Editor/models/annotation/annotationFactories'
import {
  isVideoAnnotation,
  isImageAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import type { AutoAnnotateModel } from '@/modules/Editor/types'
import type { View } from '@/modules/Editor/views/view'

import { HEADER_COMPONENT, SPINNER_COMPONENT } from './consts'
import type { CornerInfo, PointMapping } from './types'
import {
  addInferredAnnotation,
  buildAutoAnnotateRequestPayload,
  drawClick,
  drawOverlay,
  drawPendingClick,
  findEditableCorner,
  findEditableEdge,
  isPreselectedModelAutoAnnotate,
  payloadRelativeToCentroid,
  remapInferenceResult,
  resolveAnnotationPath,
  resolveClick,
  resolvePolygonPath,
  selectCornerCursor,
  transitionToAction,
  updateClickerData,
} from './utils'
import { ToolName } from '@/modules/Editor/tools/types'
import { isPolygon } from '@/modules/Editor/annotationTypes/polygon'
import { getImagePayload } from './utils/getImagePayload'

const mappingOnRectangle = (rect: Rectangle, mapping: PointMapping): Rectangle =>
  new Rectangle(mapping.forward(rect.topLeft), mapping.forward(rect.bottomRight))

const findClick = (
  context: ToolContext,
  currentClicks: Click[],
  point: IPoint,
): Click | undefined => {
  for (const click of [...currentClicks].reverse()) {
    const canvasPoint = context.editor.activeView.camera.imageViewToCanvasView({
      x: click.x,
      y: click.y,
    })

    if (euclideanDistance(point, canvasPoint) < 5) {
      return click
    }
  }
}

// Convert Rectangle to Rectangle
const imageRectangleToCanvasRectangle = (crop: Rectangle, camera: Camera): Rectangle => {
  const topLeft = camera.imageViewToCanvasView(crop.topLeft)
  const bottomRight = camera.imageViewToCanvasView(crop.bottomRight)
  return new Rectangle(topLeft, bottomRight)
}

const drawBoxCorners = (ctx: CanvasRenderingContext2D, camera: Camera, crop: Rectangle): void => {
  const corners = [crop.topLeft, crop.topRight, crop.bottomRight, crop.bottomLeft]
  for (const corner of corners) {
    const canvasPoint = camera.imageViewToCanvasView(corner)
    ctx.beginPath()
    ctx.arc(canvasPoint.x, canvasPoint.y, 3.5, 0, 2 * Math.PI)
    ctx.fillStyle = 'white'
    ctx.fill()
  }
}

const currentItemResolution = (activeView: View): { width: number; height: number } =>
  activeView.currentViewSize || { width: 0, height: 0 }

const drawClicks = (
  clicks: Click[],
  ctx: CanvasRenderingContext2D,
  camera: Camera,
  hoverClick: Click | null,
  pendingClick: Click | null,
): void => {
  // Draw clicks
  for (const click of clicks) {
    const clickPoint = { ...click }
    const canvasPoint = camera.imageViewToCanvasView(clickPoint)
    const color = click.type === 'add' ? '#00D9C9' : '#EB5353'
    const hoverColor = click.type === 'add' ? '#18BCB0' : '#BC2424'

    drawClick(ctx, canvasPoint, 5, click === hoverClick ? hoverColor : color)
  }
  if (pendingClick) {
    const clickPoint = { ...pendingClick }
    const canvasPoint = camera.imageViewToCanvasView(clickPoint)
    const clickColor = pendingClick.type === 'add' ? '#00D9C9' : '#EB5353'
    drawPendingClick(ctx, canvasPoint, 5, clickColor)
  }
}

export class ClickerTool implements Tool {
  initialPoint?: IPoint
  cursorPoint?: IPoint
  currentAnnotation?: Annotation
  currentCrop?: Rectangle
  // Keeps the original polygon path before applying the epsilon value
  currentPolygon?: AnnotationData
  currentClicks: Click[] = []
  // Visible clicks are optimistically rendered while waiting for the backend to reply
  currentVisibleClicks: Click[] = []
  pendingClick: Click | null = null
  // Which click is the user currently hovering over
  hoverClick?: Click
  actionGroup?: ActionGroup
  movingCorner?: CornerInfo
  movingPoint?: IPoint
  // uuid to keep track of if inference request are out of date and should be ignored or not.
  instance?: string
  threshold?: number

  context?: ToolContext

  /**
   * When resuming an existing clicker annotation, this holds the original
   * annotation data, before any edits were through the clicker tool.
   *
   * When completing the clicker, we use this to transition to a global action,
   * allowing the undo command to then restore the annotation to the state it
   * was in before the clicker resume happened.
   *
   * See `transitionToAction`
   */
  initialAnnotationData?: Annotation['data']

  /**
   * Adds a click to current clicks and sends an inferrence request.
   *
   * This is used when user adds more click to the clicker bounding box.
   */
  public async sendClicks(context: ToolContext): Promise<void> {
    const { currentCrop, currentAnnotation, cursorPoint, currentPolygon, pendingClick } = this

    if (
      // any of these being false means we haven't sent the initial bounding box,
      // so there is nothing to add clicks to
      !currentAnnotation ||
      !cursorPoint ||
      !currentPolygon ||
      !currentCrop
    ) {
      return
    }

    // a click is currently already beeing sent, and we're waiting for a reply
    if (pendingClick) {
      return
    }

    const currentPolygonPath = resolvePolygonPath(context, currentPolygon)
    const click = resolveClick(cursorPoint, currentPolygonPath)

    this.pendingClick = click

    const newClicks = [...this.currentClicks, click]
    await this.sendInferenceRequest(context, currentCrop, newClicks)

    this.pendingClick = null
  }

  /**
   * Sends a bounding box and clicks as an inference request.
   *
   * If no clicks are given, it will send using current clicks. This can be used
   * to rerun the previous inference request.
   *
   * Clicks can be given as [] to resend the bounding box alone and effectively
   * clear out all the current clicks.
   */
  public sendBoundingBox(context: ToolContext, clicks?: Click[]): void {
    const { currentClicks, currentCrop } = this

    if (!currentCrop) {
      return
    }

    const newClicks = clicks || currentClicks
    this.sendInferenceRequest(context, currentCrop, newClicks)
  }

  /**
   * This function is meant to make whole-image inference ('clicker_tool.infer' command)
   * discoverable. If a whole image rectangular crop is drawn to send the entire image for
   * inference, a toast notification pill is shown, hinting the hotkey.
   */
  private hintAboutWholeImageInference(editor: Editor): void {
    const { currentCrop, initialPoint } = this
    const { preselectedAutoAnnotateModel } = editor.autoAnnotateManager

    const isInitialPointTopLeft = initialPoint && initialPoint.x <= 0 && initialPoint.y <= 0

    const { width, height } = currentItemResolution(editor.activeView)
    const wholeImageCrop = new Rectangle({ x: 0, y: 0 }, { x: width, y: height })
    const isCropWholeImage =
      currentCrop &&
      currentCrop.left === wholeImageCrop.left &&
      currentCrop.top === wholeImageCrop.top &&
      currentCrop.right === wholeImageCrop.right &&
      currentCrop.bottom === wholeImageCrop.bottom

    if (isInitialPointTopLeft && isCropWholeImage) {
      const hotkey = onMacOS() ? '⌘⏎' : 'Ctrl⏎'
      const { isProcessedAsVideo } = editor.activeView.fileManager
      const item = isProcessedAsVideo ? 'frame' : 'image'
      const modelName =
        preselectedAutoAnnotateModel === null ? 'a model' : preselectedAutoAnnotateModel.name

      EditorEvents.message.emit({
        content: `You can use ${hotkey} to send the whole ${item} for inference to ${modelName}.`,
        level: 'info',
      })
    }
  }

  private async sendInferenceRequest(
    context: ToolContext,
    crop: Rectangle,
    clicks: Click[],
  ): Promise<void> {
    // we store the instance (which is an id of ther current clicker UI being rendered)
    // so we can later check if user closed that one and maybe openned a new one
    const { instance, threshold } = this
    const { editor } = context
    try {
      this.setBusy()

      // optimistic UI
      this.currentVisibleClicks = clicks

      this.draw(editor.activeView, context)

      // store the model id in case this changes while the request is in progress
      // as we need it both to send the request, as well as later, to build the annotation
      const { id: modelId } =
        editor.autoAnnotateManager.preselectedAutoAnnotateModel || editor.autoAnnotateModels[0]

      const imageView = editor.visibleView
      const { imagePayload, mapping, scaled } = await getImagePayload(imageView, crop)
      const scaledBbox = mappingOnRectangle(crop, mapping)
      let scaledClicks = clicks

      const dicomFrameParams = getDicomFrameParams(imageView)
      if (dicomFrameParams) {
        const { xScale, yScale } = dicomFrameParams

        Object.assign(scaledBbox, {
          x1: scaledBbox.left * xScale,
          x2: scaledBbox.right * xScale,
          y1: scaledBbox.top * yScale,
          y2: scaledBbox.bottom * yScale,
        })

        scaledClicks = clicks.map((click) => ({
          x: click.x * xScale,
          y: click.y * yScale,
          type: click.type,
        }))
      }

      const requestPayload = buildAutoAnnotateRequestPayload(
        editor,
        { imagePayload, mapping, scaled },
        scaledBbox,
        scaledClicks,
        threshold,
        crop,
      )
      const response = await editor.runInference(modelId, requestPayload)

      // the user canceled the inference before the callback finished
      if (instance !== this.instance) {
        return
      }

      this.hintAboutWholeImageInference(editor)

      if (response) {
        const { preselectedModelClassMapping, preselectedAutoAnnotateModel } =
          editor.autoAnnotateManager
        if (isPreselectedModelAutoAnnotate(editor)) {
          const { path } = remapInferenceResult(response as InferenceResult, mapping)
          if (path) {
            await addInferredAnnotation(this, modelId, clicks, crop, path, dicomFrameParams)
          }
        } else {
          if (!preselectedAutoAnnotateModel) {
            return
          }

          const inferenceResult = response as InferenceResult[]
          const model = editor.autoAnnotateModels.find((m) => m.id === modelId)
          if (!model) {
            return
          }

          const annotations = []
          const inferredClassLabels: Set<string> = new Set()
          for (let i = 0; i < inferenceResult.length; i++) {
            const inferenceData = remapInferenceResult(inferenceResult[i], mapping)
            const annotation = createAnnotationFromInferenceData(inferenceData, model.classes)
            if (!annotation) {
              continue
            }

            const classMapping = preselectedModelClassMapping.find(
              (m) => m.modelClassLabel === annotation.label,
            )
            if (!classMapping || !classMapping.annotationClassId) {
              inferredClassLabels.add(annotation.label)
              continue
            }

            const annotationClass = editor.getClassById(classMapping.annotationClassId)
            if (!annotationClass) {
              continue
            }

            annotation.annotationClass = annotationClass
            annotations.push(annotation)
          }

          const action = addAnnotationsAction(editor.activeView, annotations)
          this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()
          this.actionGroup.do(action)

          this.notifyUnmappedClasses(preselectedAutoAnnotateModel, inferredClassLabels)
        }
      }
    } finally {
      if (instance === this.instance) {
        this.setNotBusy(context)
      }
    }
  }

  notifyUnmappedClasses(model: AutoAnnotateModel, inferredClassLabels: Set<string>): void {
    if (inferredClassLabels.size > 0) {
      const inferredClassLabelList = [...inferredClassLabels]
      const firstItems =
        inferredClassLabels.size > 1
          ? inferredClassLabelList.slice(0, 2)
          : [inferredClassLabelList[0]]

      let itemList: string
      if (inferredClassLabels.size === 1) {
        itemList = `${firstItems[0]} class`
      } else if (inferredClassLabels.size === 2) {
        itemList = `${firstItems[0]} and ${firstItems[1]} classes`
      } else {
        itemList = `${firstItems[0]}, ${firstItems[1]} and other classes`
      }
      const content = [
        `${model.name} has detected objects of ${itemList}.`,
        'Use the Map Classes button in the Top Bar of this WorkView',
        'to create annotations from these detections.',
      ].join(' ')

      EditorEvents.message.emit({ content, level: 'info' })
    }
  }

  activate(context: ToolContext): void {
    ToolEvents.activate.emit({
      toolName: ToolName.Clicker,
      lastHotkeyPressed: null,
      editing: false,
    })
    this.context = context

    setupMouseButtonLoadout(context, { middle: true })

    selectCursor(EditorCursor.Magic)

    context.editor.registerCommand<[number]>('clicker_tool.set_threshold', (threshold: number) => {
      this.threshold = threshold
    })

    context.editor.registerCommand('clicker_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('clicker_tool.complete', () => {
      this.reset(context)
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('clicker_tool.infer', async () => {
      const { width, height } = currentItemResolution(context.editor.activeView)
      this.currentCrop = new Rectangle({ x: 0, y: 0 }, { x: width, y: height })
      this.updateComponents(context, context.editor.activeView.camera)
      this.draw(context.editor.activeView)
      await this.sendInferenceRequest(context, this.currentCrop, [])

      this.reset(context)
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand('clicker_tool.apply_clicker_epsilon', () => {
      this.applyClickerEpsilon()
      this.draw(context.editor.activeView, context)
    })

    context.editor.registerCommand(
      'clicker_tool.init',
      (annotation: Annotation, data: AutoAnnotateData) => {
        this.init(context, annotation, data)
      },
    )

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )

    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))
    context.handles.push(...context.editor.onTouchStart((event) => this.onStart(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))

    const viewsOnRender = context.editor.viewsList.map((view) => {
      const cameraMoveHandler = (cameraEvent: CameraEvent): void => {
        if (cameraEvent.viewId !== view.id) {
          return
        }

        this.draw(view, context)
      }
      CameraEvents.scaleChanged.on(cameraMoveHandler)
      CameraEvents.offsetChanged.on(cameraMoveHandler)

      return {
        id: -1,
        release(): void {
          CameraEvents.scaleChanged.off(cameraMoveHandler)
          CameraEvents.offsetChanged.off(cameraMoveHandler)
        },
      }
    })
    context.handles.push(...viewsOnRender)

    this.update(context, context.editor.activeView.camera)
  }

  deactivate(context: ToolContext): void {
    ToolEvents.deactivate.emit({
      toolName: ToolName.Clicker,
      lastHotkeyPressed: null,
      editing: false,
    })
    this.setNotBusy(context)
    this.reset(context)
    if (!this.context) {
      return
    }
    this.unregisterComponents(this.context)
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  draw(view: View, context?: ToolContext): void {
    if (context) {
      this.update(context, view.camera)
    }

    view.annotationsLayer.draw((ctx) => {
      if (this.cursorPoint) {
        const canvasCursorPoint = view.camera.imageViewToCanvasView(this.cursorPoint)
        drawGuideLines(ctx, view, canvasCursorPoint)
      }

      if (this.currentCrop) {
        const canvasRectangle = imageRectangleToCanvasRectangle(this.currentCrop, view.camera)
        // Clear, draw overlay and draw box
        drawOverlay(
          ctx,
          {
            width: view.width,
            height: view.height,
          },
          canvasRectangle,
        )
        drawDashedBox(ctx, canvasRectangle)
        drawBoxCorners(ctx, view.camera, this.currentCrop)
        drawClicks(
          this.currentVisibleClicks,
          ctx,
          view.camera,
          this.hoverClick || null,
          this.pendingClick,
        )
      } else if (this.cursorPoint && this.initialPoint) {
        // Clear and draw box
        const canvasInitialPoint = view.camera.imageViewToCanvasView(this.initialPoint)
        const canvasCursorPoint = view.camera.imageViewToCanvasView(this.cursorPoint)
        const canvasRectangle = new Rectangle(canvasInitialPoint, canvasCursorPoint)
        drawDashedBox(ctx, canvasRectangle)
      }
    })
  }

  reset(context: ToolContext): void {
    transitionToAction(this, context)

    this.initialPoint = undefined
    this.initialAnnotationData = undefined
    this.currentCrop = undefined
    this.currentPolygon = undefined
    this.pendingClick = null
    this.currentClicks = []
    this.currentVisibleClicks = []
    this.currentAnnotation = undefined
    this.instance = undefined
    this.hoverClick = undefined
    this.actionGroup = undefined

    this.unregisterComponents(context)
    selectCursor(EditorCursor.Magic)
  }

  private init(
    context: ToolContext,
    annotation: Annotation,
    { bbox, clicks }: AutoAnnotateData,
  ): void {
    this.reset(context)
    this.initialAnnotationData = cloneAnnotation(annotation).data

    const activeView = context.editor.activeView
    const annotationManager = activeView.annotationManager
    const imageSize = activeView.currentViewSize

    if (!imageSize) {
      throw new Error('Current frame not loaded yet')
    }
    // Setup the state in the same way as if we manually draw the bounding box and made the clicks.
    // The bounding box and clicks are relative to the centroid.
    let centroid: IPoint
    if (isVideoAnnotation(annotation)) {
      const { data } = inferVideoData(annotation, activeView.currentFrameIndex)
      if (!data.path) {
        return
      }
      centroid = calcCentroid(data.path)
    } else {
      if (!isImageAnnotation(annotation)) {
        throw new Error('ClickerTool: Annotation inferred as neither image nor video')
      }
      if (!annotation.data.path) {
        return
      }
      centroid = calcCentroid(annotation.data.path)
    }

    this.currentAnnotation = annotationManager.getAnnotation(annotation.id)
    if (!this.currentAnnotation) {
      return
    }
    if (!bbox) {
      return
    }
    this.initialPoint = addPoints({ x: bbox.x1, y: bbox.y1 }, centroid)
    this.currentCrop = new Rectangle(
      addPoints({ x: bbox.x1, y: bbox.y1 }, centroid),
      addPoints({ x: bbox.x2, y: bbox.y2 }, centroid),
    )
    this.currentCrop.normalize()
    this.currentCrop.clamp(imageSize)
    this.currentClicks = (clicks || []).map((click: Click) => ({
      ...click,
      x: click.x + centroid.x,
      y: click.y + centroid.y,
    }))
    this.currentVisibleClicks = this.currentClicks
    this.currentPolygon = annotation.data
    this.instance = uuidv4()

    annotationManager.unhighlightAnnotation(this.currentAnnotation.id)
    annotationManager.deselectAnnotation(this.currentAnnotation.id)

    this.draw(activeView, context)
  }

  /**
   * Handles recomputation logic which needs to happen before every render
   *
   * Specifically, it determines if the header component
   * should be rendered, and (re)computes the position of it.
   */
  private update(context: ToolContext, camera: Camera): void {
    if (this.currentCrop) {
      this.updateComponents(context, camera)
    } else {
      this.unregisterComponents(context)
    }
  }

  /**
   * Holds data for the header component, in cases where it should be rendered.
   *
   * If undefined, it means the component is not rendered.
   *
   * The header component always renders alongside the crop.
   */
  header?: {
    id: string
    name: string
    props: {
      x: number
      y: number
      onClear: () => void
      onRerun: () => void
      busy: boolean
    }
  }

  /**
   * Holds data for the spinner component
   * Position in the centre of the crop
   *
   * If undefined, do not render
   */
  spinner?: {
    id: string
    name: string
    props: {
      x: number
      y: number
      show: boolean
    }
  }

  private setBusy(): void {
    const { header, spinner } = this
    if (spinner) {
      spinner.props.show = true
    }
    if (header) {
      header.props.busy = true
    }
  }

  private setNotBusy(context: ToolContext): void {
    const { header, spinner } = this
    if (spinner) {
      spinner.props.show = false
    }
    if (header) {
      header.props.busy = false
    }
    this.resetUnlessAutoAnnotate(context)
  }

  /**
   * If the preselected model is not of type `auto_annotate`, then
   * exit "edit" mode by resetting the state of this tool.
   */
  private resetUnlessAutoAnnotate(context: ToolContext): void {
    if (!isPreselectedModelAutoAnnotate(context.editor)) {
      this.reset(context)
      this.draw(context.editor.activeView, context)
    }
  }

  /**
   * Go through all components (currently only header) and recompute their data.
   */
  private updateComponents(context: ToolContext, camera: Camera): void {
    this.updateHeaderComponent(context, camera)
    this.updateSpinnerComponent(context, camera)
  }

  private updateHeaderComponent(context: ToolContext, camera: Camera): void {
    const { currentCrop } = this
    if (!currentCrop) {
      return
    }

    // crop is in image coordinates,
    // but we need to compute header placement in canvas coordinates
    const topLeft = camera.imageViewToCanvasView(currentCrop.topLeft)
    const placement = { x: topLeft.x, y: topLeft.y - 30 }

    const { header } = this
    if (header) {
      // if the header already exists, we only need to update positioning
      header.props.x = placement.x
      header.props.y = placement.y
    } else {
      // if the header does not exist, we need to create it and add it to the editor
      this.header = {
        id: uuidv4(),
        name: HEADER_COMPONENT,
        props: {
          ...placement,
          onRerun: (): void => this.rerun(),
          onClear: (): void => this.clear(),
          busy: false,
        },
      }
      context.editor.activeView.addComponent(this.header)
    }
  }

  private updateSpinnerComponent(context: ToolContext, camera: Camera): void {
    const { currentCrop } = this
    if (!currentCrop) {
      return
    }

    // crop is in image coordinates,
    // but we need to compute header placement in canvas coordinates

    const corners = [
      currentCrop.topLeft,
      currentCrop.topRight,
      currentCrop.bottomRight,
      currentCrop.bottomLeft,
    ]
    const centerImage = calcCentroid(corners)
    const center = camera.imageViewToCanvasView(centerImage)
    const placement = { x: center.x, y: center.y }

    const { spinner } = this
    if (spinner) {
      spinner.props.x = placement.x
      spinner.props.y = placement.y
    } else {
      this.spinner = {
        id: uuidv4(),
        name: SPINNER_COMPONENT,
        props: {
          ...placement,
          show: false,
        },
      }
      context.editor.activeView.addComponent(this.spinner)
    }
  }

  /** Unregisters all components (currently header) and removes them from editor */
  private unregisterComponents(context: ToolContext): void {
    const { header, spinner } = this
    if (!header || !spinner) {
      return
    }
    context.editor.activeView.removeComponent(header.id)
    context.editor.activeView.removeComponent(spinner.id)
    delete this.header
    delete this.spinner
  }

  private onStart(context: ToolContext, event: PointerEvent): void {
    const eventPoint = resolveEventPoint(event)
    if (!eventPoint) {
      return
    }

    if (this.currentCrop === undefined) {
      context.editor.activeView.annotationManager.deselectAllAnnotations()

      if (!this.initialPoint) {
        this.initialPoint = context.editor.activeView.camera.canvasViewToImageView(eventPoint)
      }
    } else {
      this.movingCorner = findEditableCorner(context.editor, this.currentCrop, eventPoint)
      if (!this.movingCorner) {
        this.movingPoint = findEditableEdge(context.editor, this.currentCrop, eventPoint)
      }
    }
    this.draw(context.editor.activeView, context)
  }

  private onMove(context: ToolContext, event: PointerEvent): CallbackStatus | void {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    const eventPoint = resolveEventPoint(event)
    if (!eventPoint) {
      return
    }

    this.cursorPoint = context.editor.activeView.camera.canvasViewToImageView(eventPoint)

    if (!this.initialPoint) {
      this.draw(context.editor.activeView, context)
      return
    }

    if (this.movingCorner) {
      this.movingCorner.corner.set(
        context.editor.activeView.camera.canvasViewToImageView(eventPoint),
      )
      selectCornerCursor(context.editor, this.movingCorner.position)
      this.draw(context.editor.activeView, context)

      // To stop the panning mixin
      return CallbackStatus.Stop
    }

    if (this.movingPoint && this.currentCrop) {
      const imagePoint = context.editor.activeView.camera.canvasViewToImageView(eventPoint)
      this.currentCrop.add(subPoints(imagePoint, this.movingPoint))
      this.movingPoint = imagePoint
      selectCursor(EditorCursor.DefaultMove)
      this.draw(context.editor.activeView, context)

      // To stop the panning mixin
      return CallbackStatus.Stop
    }

    if (this.cursorPoint && this.currentCrop && this.currentPolygon && this.currentAnnotation) {
      const corner = findEditableCorner(context.editor, this.currentCrop, eventPoint)
      if (corner) {
        selectCornerCursor(context.editor, corner.position)
        return
      }
      const edge = findEditableEdge(context.editor, this.currentCrop, eventPoint)
      if (edge) {
        selectCursor(EditorCursor.DefaultMove)
        return
      }

      this.hoverClick = findClick(context, this.currentClicks, eventPoint)
      if (this.hoverClick) {
        selectCursor(EditorCursor.MagicDeleteClick)
        this.draw(context.editor.activeView, context)
        return
      }

      const path = [
        context.editor.activeView.camera.imageViewToCanvasView(this.currentCrop.topLeft),
        context.editor.activeView.camera.imageViewToCanvasView(this.currentCrop.topRight),
        context.editor.activeView.camera.imageViewToCanvasView(this.currentCrop.bottomRight),
        context.editor.activeView.camera.imageViewToCanvasView(this.currentCrop.bottomLeft),
      ]
      const canvasCursorPoint = context.editor.activeView.camera.imageViewToCanvasView(
        this.cursorPoint,
      )

      const currentPolygonPath = resolvePolygonPath(context, this.currentPolygon)
      const canvasPolygonPath = currentPolygonPath.map((point) =>
        context.editor.activeView.camera.imageViewToCanvasView(point),
      )
      if (pointInPath(canvasCursorPoint, canvasPolygonPath)) {
        selectCursor(EditorCursor.MagicRemovePoint)
      } else if (pointInPath(canvasCursorPoint, path)) {
        selectCursor(EditorCursor.MagicAddPoint)
      } else {
        selectCursor(EditorCursor.Magic)
      }
    }
    this.draw(context.editor.activeView, context)
    if (!this.currentCrop) {
      return CallbackStatus.Stop
    }
  }

  private onEnd(context: ToolContext, event: PointerEvent): void {
    const { activeView } = context.editor
    const { currentViewSize, camera } = activeView

    if (!currentViewSize) {
      return
    }

    const point = resolveEventPoint(event, true)
    if (point) {
      this.cursorPoint = context.editor.activeView.camera.canvasViewToImageView(point)
    }

    if (this.movingCorner || this.movingPoint) {
      this.movingCorner = undefined
      this.movingPoint = undefined

      if (this.currentCrop) {
        this.currentCrop.normalize()
        this.currentCrop.clamp(currentViewSize)
      }
      this.sendBoundingBox(context)
      return
    }

    if (this.hoverClick) {
      const index = this.currentClicks.findIndex((c) => c === this.hoverClick)
      if (index === -1) {
        return
      }
      const clicks = [...this.currentClicks]
      clicks.splice(index, 1)
      this.sendBoundingBox(context, clicks)
      return
    }

    if (!this.initialPoint || !this.cursorPoint) {
      return
    }

    if (this.currentCrop && this.currentCrop.isValid(5)) {
      const path = [
        camera.imageViewToCanvasView(this.currentCrop.topLeft),
        camera.imageViewToCanvasView(this.currentCrop.topRight),
        camera.imageViewToCanvasView(this.currentCrop.bottomRight),
        camera.imageViewToCanvasView(this.currentCrop.bottomLeft),
      ]
      const canvasCursorPoint = camera.imageViewToCanvasView(this.cursorPoint)

      // NB: is this correct? Sometimes, mouseUp seems to register as being out
      // of the box I just drew, causing inference to cancel out. Happens if I
      // drag and release quickly
      if (!pointInPath(canvasCursorPoint, path)) {
        this.reset(context)
        return
      }
      this.sendClicks(context)
    } else {
      if (euclideanDistance(this.initialPoint, this.cursorPoint) < POINT_CLICK_THRESHOLD) {
        return
      }

      const crop = new Rectangle(this.cursorPoint, this.initialPoint)
      const { activeView } = context.editor

      const imageSize = activeView.currentViewSize

      if (!imageSize) {
        throw new Error('Current frame not loaded yet')
      }

      crop.clamp(imageSize)

      if (!crop.isValid(5)) {
        this.reset(context)
        return
      }
      this.currentCrop = crop
      // Since this is a new run of clicker, we assign it a new id.
      this.instance = uuidv4()

      // we must force the components to update before sending the first bounding box
      // otherwise the spinner would not show up.
      this.updateComponents(context, context.editor.activeView.camera)
      this.sendBoundingBox(context)
      this.draw(context.editor.activeView, context)
    }
  }

  /** Handles user clicking the RERUN button in the header */
  private rerun(): void {
    if (!this.context) {
      return
    }
    this.sendBoundingBox(this.context)
  }

  /** Handles user clicking the CLEAR button in the header */
  private clear(): void {
    if (!this.context) {
      return
    }
    this.sendBoundingBox(this.context, [])
    this.draw(this.context.editor.activeView, this.context)
  }

  /** Applies the epsilon to the current annotation path */
  private applyClickerEpsilon(): boolean | undefined {
    const {
      context,
      currentAnnotation: annotation,
      currentClicks,
      currentCrop,
      currentPolygon: polygon,
    } = this
    if (!context || !annotation || !polygon || !currentCrop) {
      return
    }
    const { id } = annotation

    const match = context.editor.activeView.annotationManager.getAnnotation(id)
    if (!match) {
      return
    }

    if (!isPolygon(polygon)) {
      return
    }

    const originalPath = polygon.path.map((point) => ({ x: point.x, y: point.y }))
    const zoomScale = context.editor.activeView.camera.scale
    const epsilon = resolveRelativeEpsilon(
      zoomScale,
      context.editor.autoAnnotateManager.clickerEpsilon,
    )
    const simplifiedPath = maybeSimplifyPolygon(originalPath, epsilon)
    const newPolygonPath = resolveAnnotationPath(context, simplifiedPath)

    const model =
      context.editor.autoAnnotateManager.preselectedAutoAnnotateModel ||
      context.editor.autoAnnotateModels[0]
    const newAutoAnnotate = {
      ...payloadRelativeToCentroid(
        { clicks: currentClicks, bbox: currentCrop },
        calcCentroid(newPolygonPath),
      ),
      model: model.id,
    }

    context.editor.activeView.annotationManager.deselectAllAnnotations()
    if (!newAutoAnnotate) {
      return false
    }

    updateClickerData(
      match,
      { path: newPolygonPath, additionalPaths: [] },
      newAutoAnnotate,
      context,
    )
    const frameIndex = context.editor.activeView.currentFrameIndex
    context.editor.activeView.annotationManager.updateAnnotation(match, {
      updatedFramesIndices: [frameIndex],
    })
  }
}

export const clickerTool = new ClickerTool()
