import { computed } from 'vue'

import { useCurrentItemStore } from '@/modules/Workview/useCurrentItemStore'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { defineComposable } from '@/core/utils/defineComposable'

/**
 * Returns a boolean value that indicates whether the app should be using
 * paginated annotations for the current workview item.
 */
export const useShouldPaginateAnnotations = defineComposable(() => {
  const currentItemStore = useCurrentItemStore()
  const featureStore = useFeatureFlagsStore()

  /**
   * ANNOTATIONS_PAGINATION_V2 uses the `useV2AnnotationsStore` that keeps all annotations and tags.
   * useAnnotationPaginationV2Store is used to load metadata and frame data.
   *
   * Instead of keeping metadata and frames data in a store we push them to the useV2AnnotationsStore and
   * using metadata will initialise the annotations without the frame data.
   *
   * Loading the frame data we push them to the useV2AnnotationsStore and it will assign frame data from the API to the existing annotations.
   *
   * To perform any CRUD operations we can use useV2AnnotationsStore methods directly since it has all the annotations and tags.
   *
   * All loading and pre-loading logic for the annotations is done in the useAnnotationsLoader composable.
   * On jump to the not loaded frame, it will trigger the loading of the annotations using minimum page size (number of frames).
   * In most cases, the minimum page size is 1, but for multi-slot it might be different since slots can have different frame indexes.
   *
   * After the current frame is loaded, it will trigger the pre-loading of the next page of annotations with N frames per page.
   */
  const shouldUsePaginatedAnnotationsV2 = computed(
    () =>
      currentItemStore.activeSlot?.hasFrames && featureStore.featureFlags.ANNOTATIONS_PAGINATION_V2,
  )

  return {
    shouldUsePaginatedAnnotationsV2,
  }
})
