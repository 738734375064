<template>
  <DarwinDialog
    :open="open"
    :class-name="className"
    :nudge-top="nudgeTop"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :max-height="maxHeight"
    :min-height="minHeight"
    :show-overlay="showOverlay"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :click-outside-to-close="clickOutsideToClose"
    @keydown:esc="$emit('update:open', false)"
    @click:outside="$emit('update:open', false)"
    @update:open="$emit('update:open', $event)"
  >
    <div
      class="confirm-dialog"
      :class="{
        'confirm-dialog--loading': loading,
        [`confirm-dialog--variant-${type}`]: true,
        [`confirm-dialog--size-${size}`]: true,
      }"
    >
      <div class="confirm-dialog__header">
        <div
          v-if="type !== 'default'"
          class="confirm-dialog__header-icon"
          :class="{ [`confirm-dialog__header-icon--${iconColor}`]: true }"
        >
          <Icon name="mono-warning" />
        </div>
        <div class="confirm-dialog__header-title">
          <slot name="title" />
          <IconButton
            v-if="!hideClose"
            flair="rounded"
            kind="transparent"
            size="xs"
            class="confirm-dialog__close"
            @click="onCancel"
          >
            <Icon name="mono-close" />
          </IconButton>
        </div>
        <div
          v-if="$slots['detail']"
          class="confirm-dialog__header-description"
        >
          <slot name="detail" />
        </div>
      </div>

      <div
        v-if="$slots['body']"
        class="confirm-dialog__body"
      >
        <slot name="body"></slot>
      </div>

      <div
        v-if="!hideFooter"
        class="confirm-dialog__footer"
        :class="{ 'confirm-dialog__footer--sloted': $slots['dont-ask-again'] }"
      >
        <div
          v-if="$slots['dont-ask-again']"
          class="confirm-dialog__footer__dont-ask-again"
        >
          <slot name="dont-ask-again" />
        </div>
        <div class="confirm-dialog__footer__actions">
          <slot name="footer-buttons">
            <DarwinButton
              class="confirm-dialog__footer__cancel"
              size="md"
              flair="rounded"
              kind="outline"
              name="cancel"
              :full-width="!$slots['dont-ask-again']"
              @click="onCancel"
            >
              <slot name="cancel-text">Cancel</slot>
            </DarwinButton>
            <DarwinButton
              class="confirm-dialog__footer__confirm"
              :kind="buttonKind"
              size="md"
              flair="rounded"
              :disabled="disabledConfirmAction"
              :full-width="!$slots['dont-ask-again']"
              name="confirm"
              @click="onConfirm"
            >
              <slot name="confirm-text">Confirm</slot>
              <template
                v-if="$slots['confirm-icon-shortcut']"
                #icon-shortcut
              >
                <slot name="confirm-icon-shortcut" />
              </template>
            </DarwinButton>
          </slot>
        </div>
      </div>
    </div>
  </DarwinDialog>
</template>

<script lang="ts">
export default { name: 'ConfirmDialog' }
</script>

<script lang="ts" setup>
import { computed } from 'vue'

import DarwinButton from '@/uiKit/Button/DarwinButton.vue'
import IconButton from '@/uiKit/Button/IconButton.vue'
import Icon from '@/uiKit/Icon/Icon.vue'
import DarwinDialog from '@/uiKit/Dialog/DarwinDialog.vue'

const props = withDefaults(
  defineProps<{
    type?: 'default' | 'warn' | 'warning' | 'error' | 'negative'
    size?: 'xs' | 'sm'
    className?: string
    open: boolean
    loading?: boolean
    nudgeTop?: string | number
    nudgeRight?: string | number
    nudgeBottom?: string | number
    nudgeLeft?: string | number
    maxHeight?: string | number
    minHeight?: string | number
    scrollable?: boolean
    showOverlay?: boolean
    fullscreen?: boolean
    hideFooter?: boolean
    hideClose?: boolean
    clickOutsideToClose?: boolean
    disabledConfirmAction?: boolean
  }>(),
  {
    type: 'default',
    size: 'xs',
    className: '',
    nudgeTop: 0,
    nudgeRight: 0,
    nudgeBottom: 0,
    nudgeLeft: 0,
    maxHeight: 360,
    minHeight: 30,
    scrollable: true,
    showOverlay: true,
    fullscreen: true,
    clickOutsideToClose: true,
  },
)

const emit = defineEmits<{
  (event: 'update:open', value: boolean): void
  (event: 'cancel'): void
  (event: 'confirm'): void
}>()

const iconColor = computed(() => {
  if (props.type === 'warn' || props.type === 'warning') {
    return 'orange'
  }
  return 'red'
})

const buttonKind = computed(() => {
  if (props.type === 'warn' || props.type === 'warning') {
    return 'primary'
  }
  if (props.type === 'error' || props.type === 'negative') {
    return 'negative'
  }
  return 'primary'
})

const onCancel = (): void => {
  emit('update:open', false)
  emit('cancel')
}

const onConfirm = (): void => {
  emit('update:open', false)
  emit('confirm')
}
</script>

<style lang="scss" scoped>
@import '@/uiKit/assets/borders.scss';
@import '@/uiKit/assets/spacings.scss';

.confirm-dialog {
  border-radius: $borderRadius16;
  position: relative;

  &--size-xs {
    width: 480px;
  }

  &--size-sm {
    width: 580px;
  }

  &__close {
    position: absolute;
    top: $spacing-5;
    right: $spacing-5;
    color: var(--color-icon-subtlest);
    font-size: 20px;
  }

  &__header {
    padding: var(--size-4);
    width: 100%;

    &-icon {
      width: 100%;
      @include row--center;
      width: 28px;
      height: 28px;
      border-radius: 100px;
      margin-bottom: $s-lg;
      &--red {
        background-color: var(--color-background-critical-subtle);
        color: var(--color-icon-critical);
      }
      &--orange {
        background-color: var(--color-background-warning-subtle);
        color: var(--color-icon-warning);
      }
    }

    &-title {
      @include typographyBoldBody400;
      color: var(--color-text-default);
      width: 100%;
      margin-bottom: $s-md;
      display: flex;
      .confirm-dialog--variant-default & {
        @include typographyBoldBody500;
        margin-bottom: $s-2xs;
      }
    }

    &-description {
      @include typographyRegularBody200;
      color: var(--color-text-subtlest);
      width: 100%;
    }
  }

  &__body {
    padding: var(--size-4);
    width: 100%;
    @include typographyRegularBody300;
    color: var(--color-text-subtle);
  }

  &__footer {
    padding: var(--size-4);
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--color-background-gray-subtlest);
    border-top: 1px solid var(--color-border-default);
    border-bottom-right-radius: $borderRadius16;
    border-bottom-left-radius: $borderRadius16;

    &__dont-ask-again {
      width: 100%;
    }

    &__actions {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      .confirm-dialog__footer--sloted & {
        width: auto;
      }
    }
  }
}
</style>
