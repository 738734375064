import type { DirectionalVector } from '@/modules/Editor/AnnotationData'
import { euclideanDistance } from '@/modules/Editor/algebra'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { ToolEvents } from '@/modules/Editor/eventBus'
import type { IPoint } from '@/modules/Editor/point'
import type { PointerEvent } from '@/core/utils/touch'
import { resolveEventPoint } from '@/core/utils/touch'
import { drawVector } from '@/modules/Editor/graphicsV2/drawVector'
import type { SubAnnotationTool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import {
  isVideoAnnotation,
  isImageAnnotation,
} from '@/modules/Editor/models/annotation/annotationKindValidator'
import { shallowCloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { inferVideoData } from '@/modules/Editor/models/annotation/inferVideoData'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { calcCentroidPoint } from '@/modules/Editor/utils'
import type { View } from '@/modules/Editor/views/view'
import { DEFAULT_LINE_WIDTH } from '@/modules/Editor/config'

interface DirectionalVectorTool extends SubAnnotationTool {
  initialPoint?: IPoint
  cursorPoint?: IPoint
  existingDirectionalVector?: Annotation
  onMove: (context: ToolContext, event: PointerEvent) => void
  onEnd: (context: ToolContext, event: PointerEvent) => void
  draw: (view: View) => void
  getExistingDirectionalVector: (
    context: ToolContext,
    annotation: Annotation,
  ) => Annotation | undefined
}

export const directionalVectorTool: DirectionalVectorTool = {
  initialPoint: undefined,
  cursorPoint: undefined,
  masterAnnotation: null,
  existingDirectionalVector: undefined,

  onMove(context: ToolContext, event: PointerEvent): void {
    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point
    this.draw(context.editor.activeView)
  },

  onEnd(context: ToolContext, event: PointerEvent): void {
    if (this.initialPoint && this.masterAnnotation) {
      const { editor } = context
      const point = resolveEventPoint(event)
      if (!point) {
        return
      }

      this.cursorPoint = point
      const directionalVector: DirectionalVector = {
        angle: Math.atan2(
          this.cursorPoint.y - this.initialPoint.y,
          this.cursorPoint.x - this.initialPoint.x,
        ),
        length: euclideanDistance(
          editor.activeView.camera.canvasViewToImageView(this.cursorPoint),
          editor.activeView.camera.canvasViewToImageView(this.initialPoint),
        ),
      }

      ToolEvents.directionalVectorUpdate.emit(
        { toolName: 'directional_vector_tool', lastHotkeyPressed: null, editing: true },
        { annId: this.masterAnnotation.id, vector: directionalVector },
      )

      this.reset(context)
      context.editor.activeView.annotationsLayer.changed()
    }
    context.editor.toolManager.activatePreviousToolEntry()
  },

  getExistingDirectionalVector(
    context: ToolContext,
    annotation: Annotation,
  ): Annotation | undefined {
    let existingDirectionalVector
    if (isVideoAnnotation(annotation)) {
      const subs = context.editor.activeView.annotationManager.inferVideoSubAnnotations(annotation)
      existingDirectionalVector = subs.find(
        (ann) => ann.type === 'directional_vector' && ann.parentId === annotation.id,
      )
    } else {
      if (!isImageAnnotation(annotation)) {
        throw new Error('Annotation is inferred as neither image nor video annotation')
      }
      existingDirectionalVector = annotation.subAnnotations.find(
        (ann) => ann.type === 'directional_vector' && ann.parentId === annotation.id,
      )
    }

    return existingDirectionalVector
  },

  selectMasterAnnotation(context: ToolContext, annotation: Annotation) {
    this.masterAnnotation = annotation

    const existingDirectionalVector = this.getExistingDirectionalVector(context, annotation)

    if (isVideoAnnotation(annotation)) {
      const { data: annotationData } = inferVideoData(
        annotation,
        context.editor.activeView.currentFrameIndex,
      )
      this.initialPoint = calcCentroidPoint(
        context.editor.activeView.camera.scale,
        context.editor.activeView.camera,
        context.editor.activeView.currentFrameIndex,
        shallowCloneAnnotation(annotation, { data: annotationData }),
      )
    } else {
      if (!isImageAnnotation(annotation)) {
        throw new Error('Annotation is inferred as neither image nor video annotation')
      }
      this.initialPoint = calcCentroidPoint(
        context.editor.activeView.camera.scale,
        context.editor.activeView.camera,
        context.editor.activeView.currentFrameIndex,
        annotation,
      )
    }

    this.existingDirectionalVector = existingDirectionalVector
  },
  activate(context: ToolContext) {
    setupMouseButtonLoadout(context, { primary: true })

    selectCursor(EditorCursor.Pointer)

    context.handles.push(...context.editor.onMouseMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onTouchMove((event) => this.onMove(context, event)))
    context.handles.push(...context.editor.onMouseUp((event) => this.onEnd(context, event)))
    context.handles.push(...context.editor.onTouchEnd((event) => this.onEnd(context, event)))

    this.draw(context.editor.activeView)
  },
  deactivate(context: ToolContext) {
    this.masterAnnotation = null
    this.draw(context.editor.activeView)
    context.editor.toolManager.activatePreviousToolEntry()
  },
  /**
   * OptimisedLayer draw method
   * @param view
   * @returns
   */
  draw(view: View): void {
    if (!(this.initialPoint && this.cursorPoint && this.masterAnnotation)) {
      return
    }

    const vector = {
      angle: Math.atan2(
        this.cursorPoint.y - this.initialPoint.y,
        this.cursorPoint.x - this.initialPoint.x,
      ),
      length: euclideanDistance(this.cursorPoint, this.initialPoint),
    }

    const canvasCentroid = calcCentroidPoint(
      view.camera.scale,
      view.camera,
      view.currentFrameIndex,
      this.masterAnnotation,
    )
    if (!canvasCentroid) {
      return
    }

    view.annotationsLayer.draw((ctx) => {
      drawVector(ctx, canvasCentroid, vector, {
        strokeColor: 'rgb(255, 255, 255)',
        fillColor: 'rgb(255, 255, 255)',
        lineWidth: DEFAULT_LINE_WIDTH,
        scale: view.camera.scale,
      })
    })
  },
  reset(context: ToolContext) {
    this.initialPoint = undefined
    this.cursorPoint = undefined
    this.masterAnnotation = null
    context.editor.activeView.annotationsLayer.draw()
  },
}
