import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import type { IPoint } from '@/modules/Editor/point'
import { subPoints } from '@/modules/Editor/point'

import { drawVertex } from '@/modules/Editor/graphicsV2/drawVertex'

export const drawTemporarySkeletonV2 = (
  ctx: CanvasRenderingContext2D,
  initialPoint: IPoint,
  cursorPoint: IPoint,
  annotationClass: AnnotationClass,
): void => {
  if (!annotationClass) {
    return
  }
  if (!annotationClass.annotation_types.includes('skeleton')) {
    return
  }

  ctx.strokeStyle = annotationClass.colorRGBAstring
  ctx.lineWidth = 1

  const { x: width, y: height } = subPoints(cursorPoint, initialPoint)

  if (!annotationClass.skeletonMetaData) {
    return
  }
  const { nodes, edges } = annotationClass.skeletonMetaData
  for (const edge of edges) {
    const fromNode = nodes.find((node) => node.name === edge.from)
    const toNode = nodes.find((node) => node.name === edge.to)
    if (!fromNode || !toNode) {
      continue
    }

    ctx.moveTo(initialPoint.x + fromNode.x * width, initialPoint.y + fromNode.y * height)
    ctx.lineTo(initialPoint.x + toNode.x * width, initialPoint.y + toNode.y * height)
  }
  ctx.stroke()

  ctx.lineJoin = 'round'
  for (const node of nodes) {
    drawVertex(
      ctx,
      {
        x: initialPoint.x + node.x * width,
        y: initialPoint.y + node.y * height,
      },
      {
        strokeColor: annotationClass.colorRGBAstring,
      },
    )
  }
}
