import type { TeamPayload } from '@/store/types/TeamPayload'
import type { MembershipPayload } from '@/store/types/MembershipPayload'

export const getRelevantTeamMemberships = (
  currentTeam: TeamPayload,
  memberships: MembershipPayload[],
): MembershipPayload[] => {
  // partner and regular teams just filter their own members

  if (currentTeam.managed_status !== 'client') {
    return memberships.filter((m) => m.team_id === currentTeam.id)
  }

  // client teams also filter their partner's members
  // if a user is a member in both partner and client, the client membership is used
  const ownMemberships = memberships.filter((m) => m.team_id === currentTeam.id)

  const ownUserIds = ownMemberships.map((m) => m.user_id)
  const partnerMembershipsNotInOwn = memberships.filter(
    (m) => m.team_id === currentTeam.partner_id && !ownUserIds.includes(m.user_id),
  )

  return ownMemberships.concat(partnerMembershipsNotInOwn)
}
