import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { ParsedError } from '@/backend/error/types'
import type { ClassMapping } from '@/modules/Workview/classMappingTypes'
import { HARDWARE_CONCURRENCY, NEIGHBOUR_TILES } from '@/utils/localStorageKeys'
import { PersistedSettings } from '@/core/utils/PersistedSettings'

export const useWorkviewStore = defineStore('workviewStore', () => {
  const persistedSettings = new PersistedSettings()

  const clickerEpsilon = ref(0.5)
  const setClickerEpsilon = (epsilon: number): void => {
    clickerEpsilon.value = epsilon
  }

  const preselectedModelId = ref<null | string>(null)
  const setCurrentToolPreselectedModelId = (_preselectedModelId: null | string): void => {
    preselectedModelId.value = _preselectedModelId
  }

  const neighbourTiles = ref(persistedSettings.loadValue(NEIGHBOUR_TILES, false))
  const setNeighbourTiles = (_neighbourTiles: boolean): void => {
    persistedSettings.saveValue(NEIGHBOUR_TILES, _neighbourTiles)
    neighbourTiles.value = _neighbourTiles
  }

  const hardwareConcurrency = ref(
    persistedSettings.loadValue(HARDWARE_CONCURRENCY, navigator.hardwareConcurrency - 1 || 2),
  )
  const setHardwareConcurrency = (_hardwareConcurrency: number): void => {
    persistedSettings.saveValue(HARDWARE_CONCURRENCY, _hardwareConcurrency)
    hardwareConcurrency.value = _hardwareConcurrency
  }

  const error = ref<ParsedError['error'] | null>(null)
  const setError = (_error: ParsedError['error']): void => {
    error.value = _error
  }

  const autoAnnotateClassMapping = ref<{ [runningSessionId: string]: ClassMapping }>(
    JSON.parse(localStorage.getItem('class-mapping') || '{}'),
  )
  const setAutoAnnotateClassMapping = (instance: {
    runningSessionId: string
    classMapping: ClassMapping
  }): void => {
    autoAnnotateClassMapping.value[instance.runningSessionId] = instance.classMapping
    localStorage.setItem('class-mapping', JSON.stringify(autoAnnotateClassMapping))
  }

  const resetState = (): void => {
    clickerEpsilon.value = 0.5
    preselectedModelId.value = null
    neighbourTiles.value = false
    hardwareConcurrency.value = navigator.hardwareConcurrency - 1 || 2
    error.value = null
    autoAnnotateClassMapping.value = {}
  }

  return {
    setAutoAnnotateClassMapping,
    setError,
    setClickerEpsilon,
    setHardwareConcurrency,
    setNeighbourTiles,
    resetState,
    setCurrentToolPreselectedModelId,
    hardwareConcurrency,
    preselectedModelId,
    clickerEpsilon,
    neighbourTiles,
    autoAnnotateClassMapping,
    error,
  }
})
