import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import { BUILD_ENVIRONMENT } from '@/services/config'

import team, { getInitialState as getTeamInitialState } from './modules/team'
import ui, { getInitialState as getUIInitialState } from './modules/ui'

import type { RootState } from './types'

Vue.use<RootState>(Vuex)

export const createStore = (): Store<RootState> =>
  new Store<RootState>({
    modules: {
      team,
      ui,
    },
    // strict mode is safe, but slow, so useful during development,
    // but should be off in prod
    strict: BUILD_ENVIRONMENT !== 'production',
  })

const store = createStore()

export const getInitialState = (): RootState => ({
  team: getTeamInitialState(),
  ui: getUIInitialState(),
})

export const resetStore = (): void => store.replaceState(getInitialState())

export default store
