import truncate from 'lodash/truncate'
import { watch } from 'vue'

import { useCurrentItemStore } from '@/modules/Workview/useCurrentItemStore'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import { setTags, setUser } from '@/services/sentry'
import type { UserPayload } from '@/store/types/UserPayload'
import { anonymize } from '@/core/utils/formatter'
import { useUserStore } from '@/modules/Auth/useUserStore'
import { useDatasetStore } from '@/modules/Datasets/useDatasetStore'

type SentryUser = {
  id: string
  email: string
}

export const anonymizeUser = (user: UserPayload): SentryUser => {
  const [address, domain] = user.email.split('@')
  const email = `${anonymize(address, 1)}@${domain}`

  return { id: user.id.toString(), email }
}

export const useSentryWatchers = (): void => {
  const datasetStore = useDatasetStore()
  const teamStore = useTeamStore()
  const currentItemStore = useCurrentItemStore()
  const userStore = useUserStore()

  // Update team id as it changes
  watch(
    () => teamStore.currentTeam?.id,
    (teamId): void => setTags({ team_id: teamId?.toString() }),
    { immediate: true },
  )

  // User id - authentication
  watch(
    () => userStore.currentUser,
    (profile: UserPayload | null): void => {
      if (profile) {
        setUser(anonymizeUser(profile))
        setTags({ user_id: profile?.id?.toString() })
      } else {
        setTags({ user_id: undefined, team_id: undefined })
      }
    },
    { immediate: true },
  )

  // Keep feature flags up to date
  const features = useFeatureFlagsStore()
  watch(
    features.featureFlags,
    (features): void => {
      const flagsObj = Object.fromEntries(
        Object.entries(features).map(([f, enabled]) => [
          truncate(`ff.${f.toLowerCase()}`, { length: 32 }),
          enabled ? 'on' : 'off',
        ]),
      )
      setTags(flagsObj || {})
    },
    { immediate: true },
  )

  watch(
    () => [datasetStore.currentDataset.id, currentItemStore.currentItemId],
    ([datasetId, itemId]): void => {
      setTags({ dataset_id: datasetId, item_id: itemId })
    },
    { immediate: true },
  )

  watch(
    () => currentItemStore.activeSlot,
    (value) => {
      if (!value) {
        return
      }
      setTags({
        is_dicom: value.isDicom,
        is_image: value.isImage,
        is_pdf: value.isPdf,
        is_video: value.isVideo,
        is_tiled: value.isTiled,
        is_processed_as_video: value.isProcessedAsVideo,
        has_frames: value.hasFrames,
        frame_count: value.framesCount,
      })
    },
  )

  watch(
    () => currentItemStore.isExternal,
    (value) => setTags({ is_external: value }),
  )

  watch(
    () => currentItemStore.activeSlot?.isTiled,
    (value) => setTags({ is_tiled: value }),
  )
}
