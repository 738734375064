import { computed, ref } from 'vue'
import { getBlockedFilesMessage } from '@/modules/Datasets/datasetUploadUtils'
import type {
  AnnotationClassPayload,
  DatasetItemUploadedItemPayload,
  DatasetPayload,
  DatasetUploadedItemsPayload,
  DatasetUploadItemPayload,
} from '@/store/types'
import type { BlockedReason } from '@/store/types'
import type { ErrorWithMessage, ParsedValidationError } from '@/backend/error'
import { ErrorCodes } from '@/backend/error/errors'

import { useToast } from '@/uiKit/Toast/useToast'
import { useOutOfStorageDialogStore } from '@/modules/Billing/useOutOfStorageDialogStore'
import { registerUpload } from '@/backend/darwin/registerUpload'
import { sendFile, signFile } from '@/modules/Datasets/urlsigner'
import { confirmFileUpload } from '@/backend/darwin/confirmFileUpload'
import { defineStore } from 'pinia'
import { useMediaInfo } from './useMediaInfo'
import {
  fileStatus,
  uploadStatus,
  type FileStatus,
  type SetFileDataPayload,
  type UploadFile,
  type UploadFileData,
  type UploadFileWithData,
  type UploadStatus,
} from './types'
import {
  getDatasetUploadItemPayloads,
  isEqual,
  isVideoDataPayload,
  isVideoFile,
  toUploadFile,
} from './fileUtils'

const MAX_QUEUE_SIZE = 5

export const useDatasetUploadStore = defineStore('datasetUpload', () => {
  const { init: initMediaInfo, closeMediaFile, extractVideoFPS } = useMediaInfo()
  const outOfStorageDialogStore = useOutOfStorageDialogStore()
  const toast = useToast()

  const commonPath = ref<string>('')
  const commonTags = ref<string[]>([])
  const datasetId = ref<number | null>(null)
  const files = ref<UploadFile[]>([])
  const status = ref<UploadStatus | null>(null)
  const tagClasses = ref<AnnotationClassPayload[]>([])
  const currentUploadFile = ref<UploadFile | null>(null)

  /**
   * Returns `true` if upload is currently in progress in the store
   */
  const uploadInProgress = computed<boolean>(() => status.value === uploadStatus.STARTED)

  const init = (): void => initMediaInfo()

  const setDatasetId = (value: number): void => {
    datasetId.value = value
  }

  /**
   * Removes an `UploadFile[]` collection from the store
   */
  const removeUploadFiles = (value: UploadFile[]): void => {
    const removeSet = new Set(value)
    files.value = files.value.filter((uploadFile) => !removeSet.has(uploadFile))
  }

  /**
   * Replaces all `UploadFile` records in the store with new data
   */
  const setFilesData = (payload: SetFileDataPayload[]): void => {
    // payload is { uploadFile, data: newData }[]
    // we want to replace all files in state at once, though, so
    // we map by file name, then map state.files to give them new data if needed
    const mapped = Object.fromEntries(payload.map((p) => [p.uploadFile.file.name, p]))

    files.value = files.value.map((uploadFile) => {
      const mappedFile = mapped[uploadFile.file.name]
      if (!mappedFile) {
        return uploadFile
      }

      const { uploadFile: mappedUploadFile, data: newData } = mappedFile
      const { data: oldData } = mappedUploadFile

      return {
        ...mappedUploadFile,
        data: { ...oldData, ...newData },
        ...(isVideoDataPayload(mappedFile) && { annotateAsFrames: mappedFile.annotateAsFrames }),
      }
    })
  }

  /**
   * Toggles the extract views flag for a given `UploadFile`
   */
  const toggleExtractViews = (uploadFile: UploadFile): void => {
    setFilesData([{ uploadFile, data: { extractViews: !uploadFile.data.extractViews } }])
  }

  /**
   * Clears all `UploadFile` records from state
   *
   * Typed wrapper around `datasetUpload/RESET_FILES`
   */
  const resetUploadFiles = (): void => {
    files.value = []
  }

  /**
   * Combines the backend response for the request which created file records in
   * the database, with the file list in the store, associating each selected
   * file with it's db id, key and signing url.
   *
   * Merges this data into `UploadFile[]` records already in the store
   */
  const processRegistrationResponse = ({
    teamSlug,
    datasetSlug,
    params,
    uploadFiles,
  }: {
    teamSlug: string
    datasetSlug: string
    params: DatasetUploadedItemsPayload
    uploadFiles: UploadFile[]
  }): void => {
    const { items, blocked_items: blockedItems } = params

    const itemsMap = new Map<string, DatasetUploadItemPayload>()

    items.forEach((item: DatasetItemUploadedItemPayload) =>
      item.slots.forEach((file: DatasetUploadItemPayload) => itemsMap.set(file.file_name, file)),
    )
    // backend could block fome files, for example,
    // if the filename is already in dataset.
    // The response will contain them under data.blocked_items.
    const blockedItemsMap = new Map<string, DatasetUploadItemPayload>()

    blockedItems.forEach((item: DatasetItemUploadedItemPayload) =>
      item.slots.forEach((file: DatasetUploadItemPayload) =>
        blockedItemsMap.set(file.file_name, file),
      ),
    )

    const blockedFiles: { name: string; reason?: BlockedReason }[] = []

    const fileData = uploadFiles.reduce<UploadFileWithData[]>((acc, uploadFile) => {
      const newItem = itemsMap.get(uploadFile.file.name)

      if (newItem) {
        const data = {
          teamSlug,
          datasetSlug,
          uploadId: newItem.upload_id,
          signingURL: `v2/teams/${teamSlug}/items/uploads/${newItem.upload_id}/sign`,
        }
        acc.push({ uploadFile, data })
      }

      // If the item is blocked, we just set blocked to true, so store can track it.
      // This file will be skipped and counted as already uploaded.
      const blockedItem = blockedItemsMap.get(uploadFile.file.name)
      if (blockedItem) {
        const data = {
          teamSlug,
          datasetSlug,
          uploadId: blockedItem.upload_id,
          blocked: true,
          reason: blockedItem.reason,
        }
        acc.push({ uploadFile, data })
        blockedFiles.push({ name: uploadFile.file.name, reason: blockedItem?.reason })
      }
      return acc
    }, [])

    if (blockedFiles.length) {
      toast.warning({ meta: { title: getBlockedFilesMessage(blockedFiles) } })
    }

    setFilesData(fileData)
  }

  /**
   * Registers files on the backend by calling the registerUpload API endpoint.
   * Takes an array of UploadFiles and registers them with the provided team and dataset slugs.
   * Includes common path and tags that will be applied to all files in the upload.
   * Returns the registration response or null if registration fails.
   */
  const registerFiles = (
    uploadFiles: UploadFile[],
    teamSlug: string,
    datasetSlug: string,
  ): ReturnType<typeof registerUpload> | null => {
    const items = getDatasetUploadItemPayloads(uploadFiles, commonPath.value, commonTags.value)
    return registerUpload({
      datasetSlug,
      items,
      tags: commonTags.value,
      path: commonPath.value,
      teamSlug,
    })
  }

  /**
   * Takes a list of `File[]` or `FileList` dropped onto a dropzone or picked
   * using a file dialog and pushes them as `UploadFile[]` into the store
   */
  const createUploadFiles = async (fileList: FileList | File[]): Promise<UploadFile[]> => {
    const newFilesSet =
      files.value.length === 0 ? 1 : Math.max(...files.value.map((u) => u.data.setId)) + 1

    const newFiles = Array.from(fileList).filter(
      (f) => !files.value.find((u) => isEqual(u.file, f)),
    )

    const hasVideoFiles = newFiles.some(isVideoFile)
    // lazy load mediainfo if we are processing video files
    // to extract framerate values

    const addedUploadFiles = []
    for (const f of newFiles) {
      if (hasVideoFiles && isVideoFile(f)) {
        const fps = await extractVideoFPS(f)
        addedUploadFiles.push(toUploadFile(f, newFilesSet, commonTags.value, commonPath.value, fps))
        continue
      }

      addedUploadFiles.push(toUploadFile(f, newFilesSet, commonTags.value, commonPath.value))
    }

    closeMediaFile()

    // add uploaded files to the store
    files.value = [...files.value, ...addedUploadFiles]

    return addedUploadFiles
  }

  /**
   * Handles errors when registering files
   */
  const handleRegisterFilesError = (error: ErrorWithMessage | ParsedValidationError): void => {
    // handle explicitly disabled file uploading
    // in SuperAdmin there is a toggle per team called
    // "Allow files to be hosted on V7" that if disabled
    // makes the backend reject file uploads
    if (error.code === ErrorCodes.UPLOAD_DISABLED) {
      toast.warning({
        meta: { title: 'Data upload via the UI has been restricted by your admin.' },
      })
      return
    }

    // we're not really expecting or handling a validation error, as the payload
    // sent to the backend is generated by the system, not the user.
    if ('isValidationError' in error) {
      return console.warn('There were some validation errors in the response', error)
    }

    // This specific code means the user's storage is all used up, so the entire
    // batch failed to register
    if (error.code === ErrorCodes.OUT_OF_SUBSCRIBED_STORAGE) {
      outOfStorageDialogStore.open = true
      return
    }

    // Any other error message given by backend or parsed by frontend is
    // rendered as toast
    return toast.warning({ meta: { title: error.message } })
  }

  /**
   * Register `UploadFile[]` collection with dataset on backend and merge the returned
   * data with the collection in the store.
   */
  const addFiles = async (
    dataset: DatasetPayload,
    uploadFiles: UploadFile[],
  ): Promise<'success' | 'failure'> => {
    // no dataset means action is not possible
    if (!dataset) {
      throw new Error('file upload: no dataset')
    }

    // step 1: register files on the backend. get registration data for them
    const response = await registerFiles(uploadFiles, dataset.team_slug, dataset.slug)
    if (!response) {
      throw new Error('file upload: no response')
    }

    // if step 1 fails, we end here
    if ('error' in response) {
      removeUploadFiles(uploadFiles)
      handleRegisterFilesError(response.error)
      throw new Error('file upload: response error')
    }

    // step 2, merge registration data with `UploadFile` records
    processRegistrationResponse({
      teamSlug: dataset.team_slug,
      datasetSlug: dataset.slug,
      params: response.data,
      uploadFiles: uploadFiles,
    })

    return 'success'
  }

  /**
   * Enqueues files for upload by moving them from 'added' to 'queued' status, up to MAX_QUEUE_SIZE.
   * Files are only queued if there is space in the processing queue (total files in queued/signing/uploading/reporting < MAX_QUEUE_SIZE).
   */
  const enqueueUploads = (): void => {
    const processingStates: FileStatus[] = [
      fileStatus.QUEUED,
      fileStatus.SIGNING,
      fileStatus.UPLOADING,
      fileStatus.REPORTING,
    ]
    const processingFiles = files.value.filter(
      (uploadFile) => processingStates.indexOf(uploadFile.data.status) > -1,
    )

    if (processingFiles.length >= MAX_QUEUE_SIZE) {
      return
    }

    const fileData = files.value
      .filter((uploadFile) => uploadFile.data.status === fileStatus.ADDED)
      .slice(0, MAX_QUEUE_SIZE - processingFiles.length)
      .map((uploadFile) => ({ uploadFile, data: { status: fileStatus.QUEUED } }))

    setFilesData(fileData)
  }

  /**
   * Processes files in the upload queue by:
   * 1. Finding files with 'queued' status
   * 2. For each queued file:
   *    - If blocked, marks as uploaded/reported
   *    - Otherwise, signs the file with backend
   *    - Uploads file to storage with progress tracking
   *    - Reports upload completion to backend
   * 3. Handles errors by setting appropriate error statuses
   */
  const processUploadQueue = async (): Promise<void> => {
    const chunk = files.value.map(async (uploadFile) => {
      if (uploadFile.data.status !== fileStatus.QUEUED) {
        return
      }
      const { file, data } = uploadFile
      const { blocked } = data
      const config = { signingURL: data.signingURL }

      const fileData: Partial<UploadFileData> = blocked
        ? { blocked, status: fileStatus.UPLOADED_REPORTED }
        : { status: fileStatus.SIGNING }
      uploadFile.data = { ...uploadFile.data, ...fileData }
      if (blocked) {
        return
      }

      let signResponse
      try {
        signResponse = await signFile(file, config)
      } catch {
        uploadFile.data.status = fileStatus.ERROR_SIGNING
        return
      }

      uploadFile.data = {
        ...uploadFile.data,
        status: fileStatus.UPLOADING,
        ...signResponse,
      }

      const sendConfig = {
        ...signResponse,
        onProgress(sentBytes: number, totalBytes: number): void {
          uploadFile.data = {
            ...uploadFile.data,
            sentBytes,
            totalBytes,
          }
        },
      }

      const sendResponse = await sendFile(file, sendConfig)
      if (!sendResponse.success) {
        useToast().warning({
          meta: { title: `${file.name} could not be uploaded.` },
          duration: 3000,
        })
        uploadFile.data.status = fileStatus.ERROR_UPLOADING
        return
      }

      uploadFile.data.status = fileStatus.REPORTING

      const { teamSlug, uploadId } = data
      if (!teamSlug || !uploadId) {
        return
      }
      const response = await confirmFileUpload({ teamSlug, uploadId })

      uploadFile.data.status = response.ok
        ? fileStatus.UPLOADED_REPORTED
        : fileStatus.ERROR_REPORTING
    })

    await Promise.all(chunk)
  }

  /**
   * Continuously uploads chunks of files
   */
  const continuouslyUploadChunks = async (): Promise<void> => {
    const addedFiles = files.value.filter(({ data: { status } }) => status === fileStatus.ADDED)
    if (addedFiles.length === 0) {
      return
    }

    enqueueUploads()
    await processUploadQueue()
    continuouslyUploadChunks()
  }

  /**
   * Sets the upload status
   */
  const setUploadStatus = (value: UploadStatus): void => {
    status.value = value
  }

  /**
   * Sets upload status to 'started' and starts the upload process
   */
  const startUpload = (): void => {
    status.value = uploadStatus.STARTED
    continuouslyUploadChunks()
  }

  /**
   * Sets upload status to 'stopped' and clears the queue.
   * The intention of this mutation is to put the current upload in a stopped,
   * unresumable state.
   */
  const stopUpload = (): void => {
    status.value = uploadStatus.STOPPED
    files.value = []
  }

  /**
   * Apply tags to files in the upload queue
   */
  const setTagsForCurrentUpload = (value: string[]): void => {
    commonTags.value = value
  }

  /**
   * Apply tags classes to files in the upload queue
   */
  const setTagClassesForCurrentUpload = (value: AnnotationClassPayload[]): void => {
    tagClasses.value = value
  }

  /**
   * Sets the current upload file
   */
  const setCurrentUploadFile = (file: UploadFile | null): void => {
    currentUploadFile.value = file
  }

  /**
   * Sets the common path for the dataset
   */
  const setCommonPath = (value: string): void => {
    commonPath.value = value
  }

  /**
   * Resets all the dataset upload store state
   */
  const resetState = (): void => {
    currentUploadFile.value = null
    datasetId.value = null
    files.value = []
    commonPath.value = ''
    commonTags.value = []
    tagClasses.value = []
    status.value = uploadStatus.STOPPED
  }

  return {
    datasetId,
    files,
    status,
    tags: commonTags,
    tagClasses,
    path: commonPath,
    currentUploadFile,
    uploadInProgress,
    commonPath: commonPath.value,
    init,
    setDatasetId,
    addFiles,
    setFilesData,
    createUploadFiles,
    removeUploadFiles,
    toggleExtractViews,
    resetUploadFiles,
    setUploadStatus,
    startUpload,
    stopUpload,
    setTagsForCurrentUpload,
    setTagClassesForCurrentUpload,
    enqueueUploads,
    processUploadQueue,
    setCommonPath,
    setCurrentUploadFile,
    resetState,
  }
})
