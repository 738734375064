/* global CanvasImageSource */
import { renderImageOnCanvas } from '@/modules/Editor/renderImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { View } from '@/modules/Editor/views/view'

import { ViewTypes } from './viewTypes'
import { loadImageData, resolveTransformedImageData } from '@/modules/Editor/utils'
import type { WindowLevels } from '@/modules/Editor/imageManipulation'
import isEqual from 'lodash/isEqual'
import {
  setCurrentFrameForCanvas,
  setCurrentFrameQuality,
} from '@/modules/Editor/setCurrentFrameForCanvas'

export class ImageView extends View {
  readonly type = ViewTypes.IMAGE

  private _lastWindowLevels: WindowLevels | null = null
  private _lastImageSrc: string | null = null

  private _rawData: ImageData | null = null
  private _transformedData: CanvasImageSource | null = null

  private _releaseBeforeRender: () => void = () => {}
  private _releaseRender: () => void = () => {}

  resolveFrameData(): void {
    if (!this.currentFrame) {
      return
    }

    const hasChangedData = this._lastImageSrc !== this.currentFrame.data.src
    const hasChangedWindowLevels = !isEqual(this._lastWindowLevels, this.imageFilter.windowLevels)

    if (!hasChangedData && !hasChangedWindowLevels) {
      return
    }

    const rawImageData =
      this._rawData || this.currentFrame.rawData || loadImageData(this.currentFrame.data)
    if (!rawImageData) {
      throw new Error('Failed to load image data')
    }

    this._lastImageSrc = this.currentFrame.data.src
    const resolvedCanvasImageData =
      this.currentFrame.transformedData ||
      resolveTransformedImageData(
        this._lastWindowLevels,
        this.imageFilter.windowLevels,
        rawImageData,
        this.currentFrame.data,
        hasChangedData || hasChangedWindowLevels,
      )

    if (!resolvedCanvasImageData) {
      return
    }

    this._rawData = rawImageData
    this._transformedData = resolvedCanvasImageData
    this._lastWindowLevels = this.imageFilter.windowLevels
  }

  renderFrame(canvas: HTMLCanvasElement): void {
    if (!this.currentFrame) {
      return
    }
    if (!this._transformedData) {
      return
    }

    const renderResult = renderImageOnCanvas(
      canvas,
      this.currentFrame.data,
      this._transformedData,
      this.camera,
      this.imageFilter,
    )
    setCurrentFrameForCanvas(canvas, this.currentFrameIndex)
    setCurrentFrameQuality(canvas, this.currentFrame.quality)

    if (renderResult) {
      this.currentFrame.rawData = null
    }
  }

  init(): void {
    this.cleanupCacheData()
    this.fileManager.loadFrames()

    this.showFramesTool = false
    this._releaseBeforeRender = this.mainLayer.onBeforeRender(() => this.resolveFrameData()).release

    this._releaseRender = this.mainLayer.onRender((ctx, canvas) => {
      this.renderFrame(canvas)
      this.currentFrame && this.editor.renderMeasures && renderMeasureRegion(this)
    }).release
    this.mainLayer.clear()

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }

    this.jumpToFrameAndInitCamera()
  }

  private async jumpToFrameAndInitCamera(): Promise<void> {
    await this.jumpToFrame(0)
    this.initCamera()
  }

  private cleanupCacheData(): void {
    this._releaseBeforeRender()
    this._releaseRender()

    if (this.currentFrame) {
      this._rawData = null
      this._transformedData = null
    }

    this._lastWindowLevels = null
  }

  cleanup(): void {
    this.cleanupCacheData()
    this.mainLayer.clear()

    super.cleanup()
  }
}
