import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { CreditUsagePayload } from '@/backend/darwin/billingTypes'

export const useCreditUsageStore = defineStore('creditUsage', () => {
  const creditUsage = ref<CreditUsagePayload | null>(null)
  const resetState = (): void => {
    creditUsage.value = null
  }
  return { creditUsage, resetState }
})
