import type { Polygon } from '@/modules/Editor/AnnotationData'
import { euclideanDistance, maybeSimplifyPolygon } from '@/modules/Editor/algebra'
import { MainAnnotationType } from '@/core/annotationTypes'
import { calculatePolygonMeasures, toFullOverlayData } from '@/modules/Editor/annotationMeasures'
import {
  getAllVerticesFromPolygon,
  moveVertexOnPolygon,
} from '@/modules/Editor/annotationTypes/polygon'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { Camera, CameraEvent } from '@/modules/Editor/camera'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import {
  CameraEvents,
  EditorEvents,
  ToolManagerEvents,
  WorkviewTrackerEvents,
} from '@/modules/Editor/eventBus'
import type { Action, ActionGroup } from '@/modules/Editor/managers/actionManager'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { EditablePoint, IPoint } from '@/modules/Editor/point'
import { createEditablePoint, pointIsVertexOfPath, subPoints } from '@/modules/Editor/point'
import { resolveRelativeEpsilon } from '@/modules/Editor/resolveEpsilon'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { isTouchEvent, resolveEventPoint } from '@/core/utils/touch'
import { moveVertexAction } from '@/modules/Editor/actions'
import { drawIncompletePath } from '@/modules/Editor/graphicsV2/drawIncompletePath'
import { inferCurrentAnnotationData } from '@/modules/Editor/inferCurrentAnnotationData'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { clearPath2DCache } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { moveAnnotationVertex } from '@/modules/Editor/moveAnnotationVertex'
import { addMaskAnnotationUsingPolygon } from '@/modules/Editor/plugins/mask/utils/addMaskAnnotationUsingPolygon'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { maskAnnotationType, polygonAnnotationType } from './consts'
import { DISTANCE_THRESHOLD, POLYGON_ANNOTATION_TYPE } from './types'
import { getPolygonEdge, insertPoint, isInsertingPoint } from './utils'
import { interpolateBetweenPoints } from './utils/interpolateBetweenPoints'
import { getOrCreateRasterForView } from '@/modules/Editor/plugins/mask/utils/shared/getOrCreateRasterForView'
import { getPrimaryViewFromView } from '@/modules/Editor/plugins/mask/utils/shared/getPrimaryViewFromView'
import { isReformattedDICOMView } from '@/modules/Editor/utils/radiology/isReformattedDicomView'
import { applyRasterChange } from '@/modules/Editor/plugins/mask/utils/shared/applyRasterChange'

const annotationCreationAction = (context: ToolContext, annotation: Annotation): Action => ({
  async do(): Promise<boolean> {
    await context.editor.activeView.annotationManager.createAnnotation(annotation)
    context.editor.activeView.annotationManager.selectAnnotation(annotation.id)
    return true
  },
  async undo(): Promise<boolean> {
    await context.editor.activeView.annotationManager.deleteAnnotation(annotation.id)
    return true
  },
})

const interpolatePath = (path: IPoint[]): IPoint[] => {
  const interpolatedPath = []
  for (let i = 0; i < path.length; i++) {
    const p1 = path[i]
    interpolatedPath.push(p1)

    if (i + 1 === path.length) {
      break
    }

    const p2 = path[i + 1]
    const interpolatedPoints = interpolateBetweenPoints(p1, p2)
    for (const point of interpolatedPoints) {
      interpolatedPath.push(point)
    }
  }
  return interpolatedPath
}

export class PolygonTool implements Tool {
  /**
   * Path of the polygon currently being drawn
   */
  currentPath: IPoint[] = []

  /**
   * Cached current active view used for drawing optimisation
   */
  private cachedActiveViewId: string | undefined

  /**
   * Last known position of the mouse (on image)
   *
   * Constantly updates as mouse moves.
   */
  previousMouseMovePosition: IPoint | undefined = undefined

  /**
   * Last click position of the mouse (on image)
   */
  previousMouseClickPosition: IPoint | undefined = undefined

  /**
   * Initial position of the mouse on mouse down (on image)
   *
   * Is set on mouse down and does not update as mouse moves.
   */
  initialMouseDownPosition: IPoint | undefined = undefined

  vertexMoving: EditablePoint | undefined = undefined
  highlightedVertex: EditablePoint | undefined = undefined
  overVertex: boolean = false

  /**
   * Boolean value representing a state in which the tool is currently awaiting
   * for a polygon to be created.
   *
   * It's used to prevent double-clicking when closing path generating two polygons.
   * async/await can't be used for that matter, because onMouseMove won't behave
   * as expected with it.
   */
  creatingAnnotation: boolean = false

  pointOnLine: IPoint | null = null
  pointOnLineAnnotation: Annotation | null = null
  pointOnLinePath: EditablePoint[] | null = null
  pointOnLinePosition: number | null = null
  actionGroup: ActionGroup | undefined = undefined

  /**
   * Flag used to determine whether the user is drawing a polygon by using
   * a touch interface or a mouse.
   */
  touching: boolean = false

  /**
   * Flag used to determine whether the user is using clicking and dragging
   * to draw a scribble polygon.
   */
  dragging: boolean = false

  originAnnotation?: Annotation

  targetedItemsIndex: number | null = null
  targetedItemEdge: [IPoint, Annotation, number, EditablePoint[]] | null = null

  private handleFrameChanged: () => void = () => {}

  cursorIsClosingPath(camera: Camera, point?: IPoint): boolean {
    const { currentPath, previousMouseMovePosition } = this

    if (currentPath.length <= 2) {
      return false
    }

    const targetPoint = point || previousMouseMovePosition
    if (!targetPoint) {
      return false
    }

    const canvasPoint = camera.imageViewToCanvasView(targetPoint)
    return camera.cursorIsClosingPath(canvasPoint, currentPath[0])
  }

  async addPolygon(context: ToolContext): Promise<void> {
    if (this.creatingAnnotation) {
      return
    }

    let annotation
    this.creatingAnnotation = true
    try {
      const currentPath = this.currentPath.map((value) => createEditablePoint(value))

      // We need to interpolate the path to make sure it's smooth.
      const interpolatedPath = interpolatePath(currentPath)

      // We need to simplify the path to make sure the resulting polygon does not have too
      // many vertices.
      const zoomScale = context.editor.activeView.camera.scale
      const epsilon = resolveRelativeEpsilon(zoomScale)
      const path = maybeSimplifyPolygon(interpolatedPath, epsilon).map((point) =>
        createEditablePoint(point),
      )

      const polygon: Polygon = { path }
      const params = { type: POLYGON_ANNOTATION_TYPE, data: polygon }

      const newAnnotation =
        await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

      if (!newAnnotation) {
        return
      }

      // Possibly draw a raster if we have a preselected raster class.
      const annotationClass = context.editor.activeView.annotationManager.preselectedAnnotationClass

      if (annotationClass?.annotation_types.includes('mask')) {
        this.reset(context)
        // Remove the actions for the polygon group
        this.actionGroup?.remove()
        this.actionGroup = undefined

        if (context.editor.activeView.fileManager.isTiled) {
          EditorEvents.message.emit({
            content: 'Mask annotations can not be created on tiled images',
            level: 'warning',
          })
        } else {
          // Prepare and store the mask update action
          const primaryView = getPrimaryViewFromView(context.editor.activeView)
          if (!primaryView) {
            throw new Error('No primary view found for view')
          }
          const raster = getOrCreateRasterForView(primaryView)
          const isReformattedView = isReformattedDICOMView(context.editor.activeView)

          const createMaskWithPolygon = (): Promise<void> =>
            addMaskAnnotationUsingPolygon(context.editor.activeView, polygon, annotationClass.id)

          await applyRasterChange(createMaskWithPolygon, raster, isReformattedView)
        }
      } else {
        if (isReformattedDICOMView(context.editor.activeView)) {
          EditorEvents.message.emit({
            content: 'Only mask annotations can be created on reformatted views',
            level: 'warning',
          })

          this.currentPath = []
          context.editor.activeView.annotationsLayer.clearDrawingCanvas()
          return
        }

        annotation = newAnnotation

        context.editor.actionManager.do(annotationCreationAction(context, newAnnotation))
        context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(annotation)
      }
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 polygon tool createAnnotation failed')
    } finally {
      this.creatingAnnotation = false
      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
    }

    if (!annotation) {
      return
    }

    if (this.actionGroup) {
      this.actionGroup.remove()
      this.actionGroup = undefined
    }

    this.currentPath = []

    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  updateDrawingMeasuresOverlay(context: ToolContext): void {
    if (this.currentPath.length === 0) {
      return
    }
    const path = [...this.currentPath]
    if (this.previousMouseMovePosition) {
      path.push(this.previousMouseMovePosition)
    }

    const data: Polygon = { path: path.map((p) => createEditablePoint(p)) }
    const view = context.editor.activeView
    const measureData = toFullOverlayData(
      calculatePolygonMeasures(data, view.camera, view.measureManager.measureRegion),
      view.annotationManager.preselectedAnnotationClassColor(),
    )

    if (measureData) {
      context.editor.activeView.measureManager.updateOverlayForDrawingAnnotation(measureData)
    } else {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  }

  addPoints(context: ToolContext, point: IPoint): void {
    if (this.currentPath.length > 0) {
      // prevent to push extremely near point
      const lastPoint = this.currentPath[this.currentPath.length - 1]
      const distance = euclideanDistance(lastPoint, point)
      if (distance < DISTANCE_THRESHOLD) {
        return
      }
    }

    const action = {
      do: (): boolean => {
        this.currentPath.push(point)
        this.draw(context.editor.activeView)
        // report activity for every new draw incomplete path
        WorkviewTrackerEvents.reportActivity.emit()
        return true
      },
      undo: (): boolean => {
        this.currentPath.splice(-1, 1)
        this.draw(context.editor.activeView)
        // report activity for every new draw incomplete path
        WorkviewTrackerEvents.reportActivity.emit()
        return true
      },
    }
    this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()
    this.actionGroup.do(action)
  }

  async activate(context: ToolContext): Promise<void> {
    this.cachedActiveViewId = context.editor.activeView.id

    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Polygon,
      [polygonAnnotationType, maskAnnotationType],
      'You must create or select an existing Mask or Polygon class before using the polygon tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.Draw)

    context.editor.registerCommand('polygon_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.editor.registerCommand('polygon_tool.close', async () => {
      if (this.currentPath.length > 2) {
        await this.addPolygon(context)
      }
    })

    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onMouseMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onMouseUp((e) => this.onEnd(context, e)))

    context.handles.push(...context.editor.onTouchStart((e) => this.onStart(context, e)))
    context.handles.push(...context.editor.onTouchMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onTouchEnd((e) => this.onEnd(context, e)))

    this.handleFrameChanged = (): void => {
      this.resetContext(context)
    }
    ToolManagerEvents.frameChanged.on(this.handleFrameChanged)

    const viewsOnRender = context.editor.viewsList.map((view) => {
      const handleCameraMove = (cameraEvent: CameraEvent): void => {
        if (cameraEvent.viewId !== view.id) {
          return
        }

        if (this.cachedActiveViewId !== view.id) {
          return
        }

        this.draw(view)
      }

      CameraEvents.scaleChanged.on(handleCameraMove)
      CameraEvents.offsetChanged.on(handleCameraMove)

      return {
        id: -1,
        release: (): void => {
          CameraEvents.scaleChanged.off(handleCameraMove)
          CameraEvents.offsetChanged.off(handleCameraMove)
        },
      }
    })

    context.handles.push(...viewsOnRender)
  }

  maybeSuppressMouseEvent(event: PointerEvent): void | typeof CallbackStatus.Stop {
    event.preventDefault()
    const touching = isTouchEvent(event)
    if (!touching && this.touching) {
      // A touch event was already triggered, so we should prevent this mouse event to trigger
      return CallbackStatus.Stop
    }
    this.touching = touching
  }

  /**
   * Track the time when the user left the initial point for
   * better control of the annotation creation.
   * NOTE: without this, it is possible to unexpectedly create
   * an annotation with a slight mouse movement.
   */
  private readyToCreate: boolean = false

  onStart(context: ToolContext, event: PointerEvent): void | typeof CallbackStatus.Stop {
    this.maybeSuppressMouseEvent(event)

    const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation

    this.originAnnotation = selectedAnnotation ? cloneAnnotation(selectedAnnotation) : undefined

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.dragging = true

    const canvasPoint = point
    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)

    this.initialMouseDownPosition = imagePoint
    this.readyToCreate = false

    // inserted point can be selected and start moving within the same tick,
    // so this does not return
    if (isInsertingPoint(this)) {
      insertPoint(this, context)
    }

    // this one is rendered in place of initial click otherwise
    this.pointOnLine = null

    const vertex = context.editor.activeView.annotationManager.findAnnotationVertexAt(imagePoint)
    if (this.currentPath.length === 0 && vertex) {
      context.editor.activeView.annotationManager.deselectVertex()

      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (selectedAnnotation && this.targetedItemsIndex !== null) {
        context.editor.activeView.annotationManager.selectVertexIndex(this.targetedItemsIndex)
      }

      this.vertexMoving = vertex
      return CallbackStatus.Stop
    }

    if (!this.cursorIsClosingPath(context.editor.activeView.camera, imagePoint)) {
      this.addPoints(context, imagePoint)
    }

    return CallbackStatus.Stop
  }

  onMove(context: ToolContext, event: PointerEvent): void | typeof CallbackStatus.Stop {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    this.maybeSuppressMouseEvent(event)

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    if (
      !this.vertexMoving &&
      this.initialMouseDownPosition &&
      this.currentPath.length > 0 &&
      !context.editor.activeView.camera.cursorIsClosingPath(point, this.currentPath[0])
    ) {
      this.readyToCreate = true
    }

    const canvasPoint = point
    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)

    let vertex

    // Optimised annotation - point intersection detection
    context.editor.activeView.annotationsLayer.hitItemRegion(imagePoint).then((res) => {
      if (
        // Do not select annotation when moving a vertex
        !this.vertexMoving &&
        res &&
        context.editor.activeView.annotationManager.selectedAnnotation?.id !== res
      ) {
        const annToSelect = context.editor.activeView.annotationManager.getAnnotation(res)

        if (annToSelect?.type === MainAnnotationType.Polygon) {
          // Select an annotation when a user points to it
          context.editor.activeView.annotationManager.selectAnnotation(res)
        }
      }
    })

    if (context.editor.activeView.annotationManager.selectedAnnotation) {
      // When an annotation is selected we're looking for the edge (stroke) intersections
      getPolygonEdge(
        context.editor,
        context.editor.activeView.annotationManager.selectedAnnotation,
        canvasPoint,
        5,
      ).then((edge) => {
        this.targetedItemEdge = edge
      })
    }
    if (this.targetedItemEdge && context.editor.activeView.annotationManager.selectedAnnotation) {
      // When we have a selected annotation and edge we can get vertex.
      // findVertexAt - is expensive to run (selected annotation and edge reduce the cost)
      vertex = context.editor.activeView.annotationManager.findVertexAt(
        imagePoint,
        context.editor.activeView.annotationManager.selectedAnnotation.id,
      )

      this.targetedItemsIndex =
        context.editor.activeView.annotationsLayer.hitVertexRegion(
          context.editor.activeView.annotationManager.selectedAnnotation.id,
          imagePoint,
        ) || null
    }
    const { previousMouseMovePosition, vertexMoving } = this

    if (this.touching) {
      selectCursor(EditorCursor.Draw)

      this.addPoints(context, imagePoint)

      if (context.editor.renderMeasures) {
        this.updateDrawingMeasuresOverlay(context)
      }

      this.overVertex = false
      this.previousMouseMovePosition = imagePoint

      return CallbackStatus.Stop
    }

    this.overVertex = false
    this.previousMouseMovePosition = imagePoint
    // Vertex translation
    if (previousMouseMovePosition && vertexMoving) {
      // Move the active vertex

      this.overVertex = true
      // Move a vertex only when it belongs to the selected annotation
      const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation
      if (selectedAnnotation) {
        const data = inferCurrentAnnotationData(
          selectedAnnotation,
          context.editor.activeView.currentFrameIndex,
        )
        const path = getAllVerticesFromPolygon(data)
        if (
          path &&
          pointIsVertexOfPath(vertexMoving, path, 5 / context.editor.activeView.cameraScale)
        ) {
          moveVertexOnPolygon(vertexMoving, subPoints(imagePoint, previousMouseMovePosition))

          if (selectedAnnotation) {
            context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
          }
        }
        // force the path to be recalculated
        clearPath2DCache(selectedAnnotation.id)
      }
    } else if (vertex && this.currentPath.length === 0) {
      // Highlight targeted vertex

      this.overVertex = true
      context.editor.activeView.unhighlightAllVertices()
      if (this.targetedItemsIndex !== null) {
        context.editor.activeView.annotationManager.highlightVertexIndex(this.targetedItemsIndex)
      }

      this.highlightedVertex = vertex
      this.pointOnLine = null
      selectCursor(EditorCursor.Edit)
    } else if (
      this.currentPath.length === 0 &&
      context.editor.activeView.annotationManager.selectedAnnotation
    ) {
      // Add a new vertex for the selected annotation

      this.pointOnLine = null
      const result = this.targetedItemEdge
      if (result !== null) {
        const [point, annotation, position, path] = result
        if (context.editor.activeView.annotationManager.selectedAnnotation?.id !== annotation.id) {
          context.editor.activeView.annotationManager.selectAnnotation(annotation.id)
        }

        this.pointOnLine = context.editor.activeView.camera.imageViewToCanvasView(point)
        this.pointOnLineAnnotation = annotation
        this.pointOnLinePath = path
        this.pointOnLinePosition = position
        selectCursor(EditorCursor.AddPoint)
      } else {
        selectCursor(EditorCursor.Draw)
        if (context.editor.renderMeasures) {
          this.updateDrawingMeasuresOverlay(context)
        }
      }

      context.editor.activeView.unhighlightAllVertices()
    } else if (this.cursorIsClosingPath(context.editor.activeView.camera) && this.readyToCreate) {
      // Close the drawn polygon path

      this.previousMouseMovePosition = this.currentPath[0]
      selectCursor(EditorCursor.Edit)
      context.editor.activeView.unhighlightAllVertices()
    } else if (this.dragging && this.readyToCreate) {
      // Draw a polygon

      selectCursor(EditorCursor.Draw)
      this.addPoints(context, imagePoint)
      if (context.editor.renderMeasures) {
        this.updateDrawingMeasuresOverlay(context)
      }
      return CallbackStatus.Stop
    } else {
      // Default tool movement

      selectCursor(EditorCursor.Draw)
      if (context.editor.renderMeasures) {
        this.updateDrawingMeasuresOverlay(context)
      }
      context.editor.activeView.unhighlightAllVertices()
    }
    this.draw(context.editor.activeView)
  }

  async onEnd(context: ToolContext, event: PointerEvent): Promise<typeof CallbackStatus.Stop> {
    this.maybeSuppressMouseEvent(event)

    const { activeView, actionManager } = context.editor
    const { selectedAnnotation } = activeView.annotationManager

    const { camera } = activeView

    this.dragging = false

    const point = resolveEventPoint(event, true)
    if (point) {
      const imagePoint = camera.canvasViewToImageView(point)
      if (this.previousMouseClickPosition) {
        const distance = euclideanDistance(imagePoint, this.previousMouseClickPosition)
        if (distance < DISTANCE_THRESHOLD) {
          return CallbackStatus.Stop
        }
      }

      this.previousMouseClickPosition = imagePoint
      this.previousMouseMovePosition = imagePoint
    }

    const { initialMouseDownPosition, previousMouseMovePosition, vertexMoving } = this
    if (
      selectedAnnotation &&
      vertexMoving &&
      initialMouseDownPosition &&
      previousMouseMovePosition
    ) {
      moveAnnotationVertex(
        selectedAnnotation,
        activeView,
        vertexMoving,
        subPoints(initialMouseDownPosition, previousMouseMovePosition),
      )

      const action = moveVertexAction(
        context.editor.activeView,
        this.originAnnotation,
        selectedAnnotation,
        vertexMoving,
        initialMouseDownPosition,
        previousMouseMovePosition,
        (annotation, vertex, offset, view) =>
          moveAnnotationVertex(annotation, view, vertex, offset),
      )

      if (selectedAnnotation) {
        context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
      }

      actionManager.do(action)
      this.resetContext(context)
    } else if (this.cursorIsClosingPath(camera, previousMouseMovePosition)) {
      // snap cursor point to close path
      this.previousMouseMovePosition = this.currentPath[0]
      await this.addPolygon(context)
      this.resetContext(context)
    }

    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  }

  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      const { currentPath, pointOnLine, previousMouseMovePosition, overVertex } = this
      if (currentPath.length === 0) {
        if (pointOnLine !== null) {
          ctx.strokeStyle = view.annotationManager.selectedAnnotationClassColor()
          ctx.fillStyle = 'rgb(255,255,255)'
          ctx.lineWidth = 1
          ctx.beginPath()
          ctx.arc(pointOnLine.x, pointOnLine.y, 3.5, 0, 2 * Math.PI)
          ctx.fill()
          ctx.stroke()
          ctx.closePath()
        } else {
          if (previousMouseMovePosition && !overVertex) {
            const path = [previousMouseMovePosition]
            drawIncompletePath(
              ctx,
              path,
              view.camera,
              view.annotationManager.preselectedAnnotationClassColor(),
            )
          }
        }
        return
      }

      if (!this.dragging && !this.touching && previousMouseMovePosition) {
        const path = [...this.currentPath, previousMouseMovePosition]
        drawIncompletePath(
          ctx,
          path,
          view.camera,
          view.annotationManager.preselectedAnnotationClassColor(),
        )
      } else {
        drawIncompletePath(
          ctx,
          this.currentPath,
          view.camera,
          view.annotationManager.preselectedAnnotationClassColor(),
        )
      }
    })
  }

  onRender(view: View, context: ToolContext): void {
    const ctx = view.annotationsLayer.context
    if (!ctx) {
      return
    }

    const { currentPath, pointOnLine, previousMouseMovePosition, overVertex } = this
    if (currentPath.length === 0) {
      if (pointOnLine !== null) {
        ctx.strokeStyle = context.editor.activeView.annotationManager.selectedAnnotationClassColor()
        ctx.fillStyle = 'rgb(255,255,255)'
        ctx.lineWidth = 1
        ctx.beginPath()
        ctx.arc(pointOnLine.x, pointOnLine.y, 3.5, 0, 2 * Math.PI)
        ctx.fill()
        ctx.stroke()
        ctx.closePath()
      } else {
        if (previousMouseMovePosition && !overVertex) {
          const path = [previousMouseMovePosition]
          drawIncompletePath(
            ctx,
            path,
            view.camera,
            context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
          )
        }
      }
      return
    }

    if (!this.dragging && !this.touching && previousMouseMovePosition) {
      const path = [...this.currentPath, previousMouseMovePosition]
      drawIncompletePath(
        ctx,
        path,
        view.camera,
        context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
      )
    } else {
      drawIncompletePath(
        ctx,
        this.currentPath,
        view.camera,
        context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
      )
    }
  }

  deactivate(context: ToolContext): void {
    this.reset(context)
    ToolManagerEvents.frameChanged.off(this.handleFrameChanged)
    this.handleFrameChanged = (): void => {}
    context.editor.activeView.annotationsLayer.draw()
  }

  reset(context: ToolContext): void {
    this.resetContext(context)
    this.currentPath = []
  }

  resetContext(context: ToolContext): void {
    this.readyToCreate = false
    this.pointOnLine = null
    this.initialMouseDownPosition = undefined
    this.previousMouseMovePosition = undefined
    this.previousMouseClickPosition = undefined
    this.pointOnLineAnnotation = null
    this.pointOnLinePath = null
    this.pointOnLinePosition = null
    this.vertexMoving = undefined
    this.touching = false
    this.dragging = false
    this.originAnnotation = undefined
    this.targetedItemEdge = null
    this.overVertex = false
    this.targetedItemsIndex = null

    if (context.editor.renderMeasures) {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  }

  async confirmCurrentAnnotation(context: ToolContext): Promise<void> {
    await this.addPolygon(context)
  }
}

export const polygonTool = new PolygonTool()
