import type { IPoint } from '@/modules/Editor/point'
import type { RenderingContext2D } from '@/modules/Editor/types'

type DrawVectorProps = {
  strokeColor: string
  fillColor: string
  lineWidth: number
  scale: number
}

export const drawVector = (
  ctx: RenderingContext2D,
  centroid: IPoint,
  vector: { angle: number; length: number },
  { strokeColor, fillColor, lineWidth, scale }: DrawVectorProps,
): void => {
  const tip = {
    x: centroid.x + vector.length * Math.cos(vector.angle),
    y: centroid.y + vector.length * Math.sin(vector.angle),
  }

  ctx.save()

  ctx.strokeStyle = strokeColor
  ctx.fillStyle = fillColor
  ctx.lineWidth = lineWidth
  ctx.lineJoin = 'round'

  // Draw line
  ctx.beginPath()
  ctx.moveTo(centroid.x, centroid.y)
  ctx.lineTo(tip.x, tip.y)
  ctx.stroke()

  // Draw tip
  ctx.translate(tip.x, tip.y)
  ctx.rotate(vector.angle + Math.PI / 2)
  ctx.moveTo(0, 0)
  ctx.lineTo(5 / scale, 8 / scale)
  ctx.lineTo(-5 / scale, 8 / scale)
  ctx.closePath()
  ctx.fill()

  ctx.restore()
}
