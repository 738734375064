import { createSharedComposable } from '@vueuse/core'
import { ref, watch } from 'vue'

import { useClasses } from '@/modules/Classes/useClasses'
import { useClassesById } from '@/modules/Classes/useClassesById'
import { useFeatureFlags } from '@/composables/useFeatureFlags'
import { useStore } from '@/store/useStore'
import type { AnnotationType } from '@/core/annotationTypes'
import type { AnnotationClass } from '@/modules/Editor/AnnotationClass'
import { Editor } from '@/modules/Editor/editor'
import type { LayoutConfig } from '@/modules/Editor/layout'
import type { AutoAnnotateModel } from '@/modules/Editor/types'
import { storeToEditorAnnotationClass } from '@/modules/Workview/engineAdapters'
import { useClassSelectionStore } from '@/modules/Workview/useClassSelectionStore'
import {
  useAutoAnnotateStore,
  type AutoAnnotateModel as StoreAutoAnnotateModel,
} from '@/pinia/useAutoAnnotateStore'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { CommentsProvider } from '@/modules/Workview/CommentsProvider'
import { FeatureName } from '@/store/types/FeaturePayload'
import { useUserStore } from '@/modules/Auth/useUserStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import { BASE_API } from '@/services/config'
import { useImageManipulationPresetsStore } from '@/modules/Workview/useImageManipulationPresetsStore'
import isEqual from 'lodash/isEqual'
import { loadSlotSectionsTiled } from '@/backend/darwin/loadSlotSectionsTiled'
import { loadSlotSections } from '@/backend/darwin/loadSlotSections'
import type { Section } from '@/modules/Editor/managers/fileManager'
import type {
  AutoAnnotateInferencePayload,
  InferenceData,
  InferenceResult,
} from '@/modules/Editor/backend'
import { isClickerData, parseInferenceData } from '@/modules/Editor/backend'
import { runModelInference } from '@/backend/wind/runModelInference'
import { useToast } from '@/uiKit/Toast/useToast'
import { useWorkviewV2TrackerStore } from '@/modules/Workview/useWorkviewV2TrackerStore'
import { useModelsStore } from '@/modules/Models/useModelsStore'
import { ModelType } from '@/core/annotations'
import { isVideoView } from '@/modules/Editor/utils/isVideoView'
import { useWorkviewV3TrackerStore } from '@/modules/Workview/useWorkviewV3TrackerStore'
import { useWorkviewStore } from '@/modules/Workview/useWorkviewStore'

export const storeToEditorAutoAnnotateModel = (m: StoreAutoAnnotateModel): AutoAnnotateModel => ({
  id: m.id,
  name: m.label,
  classes: m.classes.map((c) => ({
    id: c.id,
    name: c.name,
    type: c.type,
    subs: c.subs,
    darwin_id: c.darwin_id,
    display_name: c.display_name,
  })),
  type: m.type,
})

export const useEditorV2 = createSharedComposable(() => {
  const store = useStore()
  const { featureEnabled } = useFeatureFlags()
  const { classesById } = useClassesById()
  const classStore = useClasses()
  const workviewSettingsStore = useWorkviewSettingsStore()

  const annotationsClassStore = useClassSelectionStore()

  const autoAnnotateStore = useAutoAnnotateStore()

  const userStore = useUserStore()
  const presetStore = useImageManipulationPresetsStore()
  const workviewStore = useWorkviewStore()
  /**
   * Global, single v2 editor instance, accessible in shared way by all components.
   */
  const editor = ref<Editor>()

  const teamStore = useTeamStore()

  const toast = useToast()

  const workviewV2Tracker = useWorkviewV2TrackerStore()
  const workviewV3Tracker = useWorkviewV3TrackerStore()

  const modelStore = useModelsStore()

  /**
   * Instantiates an editor instance using a layout config and sets it onto
   * the shared `editor` ref returned by this same composable.
   *
   * Once instantiated, the editor should not be instantiated again. Instead,
   * we can update the item and layout it contains by calling `editor.init`.
   */
  const initEditor = (layout: LayoutConfig): void => {
    const featureFlags = {
      [FeatureName.LEGACY_DICOM]: featureEnabled(FeatureName.LEGACY_DICOM),
      [FeatureName.IMAGE_TAG_LOADER]: featureEnabled(FeatureName.IMAGE_TAG_LOADER),
      [FeatureName.MED_LIGHT_MODE]: featureEnabled(FeatureName.MED_LIGHT_MODE),
      [FeatureName.OBLIQUE_PLANES]: featureEnabled(FeatureName.OBLIQUE_PLANES),
      [FeatureName.MEDICAL_VOLUME_CACHE]: featureEnabled(FeatureName.MEDICAL_VOLUME_CACHE),
      [FeatureName.SYNC_DICOM_PLAYBACK]: featureEnabled(FeatureName.SYNC_DICOM_PLAYBACK),
      [FeatureName.SENTRY_REPLAY]: featureEnabled(FeatureName.SENTRY_REPLAY),
      [FeatureName.USE_IMG_RENDERING]: featureEnabled(FeatureName.USE_IMG_RENDERING),
      [FeatureName.WORKLOG_V3_ENABLED]: featureEnabled(FeatureName.WORKLOG_V3_ENABLED),
      [FeatureName.FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION]: featureEnabled(
        FeatureName.FRAMES_MANIFEST_WITH_FRAMES_EXTRACTION,
      ),
      [FeatureName.ANNOTATIONS_PACKAGE]: featureEnabled(FeatureName.ANNOTATIONS_PACKAGE),
    }

    editor.value = new Editor(store, layout, {
      featureFlags: featureFlags,
      providers: {
        commentsProvider: new CommentsProvider({
          // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
          // can be moved out once we deal with that
          teamSlug: teamStore.currentTeam?.slug || '',
        }),
      },
      autoAnnotateModels: autoAnnotateStore.autoAnnotateModels.map(storeToEditorAutoAnnotateModel),
      hardwareConcurrency: workviewStore.hardwareConcurrency,
      neighbourTiles: workviewStore.neighbourTiles,
      clickerEpsilon: workviewStore.clickerEpsilon,
      preselectedModelId: workviewStore.preselectedModelId,
      autoAnnotateClassMapping: workviewStore.autoAnnotateClassMapping,
      preselectedAnnotationClassId: annotationsClassStore.preselectedAnnotationClassId,
      preselectedClassIdPerTool: annotationsClassStore.preselectedClassIdPerTool,
      renderMeasures: workviewSettingsStore.renderMeasures,
      renderSubAnnotations: workviewSettingsStore.renderSubAnnotations,
      videoPlaybackSpeed: workviewSettingsStore.videoPlaybackSpeed,
      videoPlaybackLoop: workviewSettingsStore.videoPlaybackLoop,
      syncVideoPlayback: workviewSettingsStore.syncVideoPlayback,
      videoAnnotationDuration: workviewSettingsStore.videoAnnotationDuration,
      framesPreloadSize: workviewSettingsStore.defaultAnnotationsPageSize,
      getBaseStreamUrl(itemId: string, fileSlotName: string): string {
        if (!teamStore.currentTeam) {
          throw new Error("Can't get currentTeam!")
        }

        const teamSlug = teamStore.currentTeam.slug
        return `${BASE_API}/v2/teams/${teamSlug}/items/${itemId}/slots/${fileSlotName}/stream`
      },
      getClassById: (id: number): AnnotationClass | undefined => {
        const klass = classesById.value[id]
        return klass && storeToEditorAnnotationClass(klass)
      },
      getCurrentUserId: (): number | undefined => userStore.currentUserId,
      getFirstClassForType: (
        type: AnnotationType,
        extraCondition?: (c: AnnotationClass) => boolean,
      ): AnnotationClass | undefined => {
        const klass = classStore.datasetClasses.find((c) => {
          const editorsClass = storeToEditorAnnotationClass(c)
          return (
            c.annotation_types.includes(type) &&
            (extraCondition ? extraCondition(editorsClass) : true)
          )
        })

        return klass && storeToEditorAnnotationClass(klass)
      },
      selectPreset: (key: string): void => {
        const selectedPreset = presetStore.presets.find((preset) =>
          isEqual(preset.keys, ['Tab', key]),
        )
        if (!selectedPreset?.id) {
          return
        }

        presetStore.activePresetId = selectedPreset.id
      },
      loadTiles: async (
        itemId: string,
        slotName: string,
        tiles: { x: number; y: number; z: number }[],
      ): Promise<{ [key: string]: string } | null> => {
        if (!teamStore.currentTeam) {
          return null
        }

        const teamSlug = teamStore.currentTeam.slug

        const res = await loadSlotSectionsTiled({ teamSlug, itemId, slotName, tiles })

        if (!res.ok) {
          return null
        }

        return res.data.slot_sections[0]?.tile_urls || null
      },

      loadSlotSections: async (
        itemId: string,
        slotName: string,
        offset: number,
        size: number,
      ): Promise<Section[] | null> => {
        if (!teamStore.currentTeam) {
          return null
        }

        const teamSlug = teamStore.currentTeam.slug

        const res = await loadSlotSections({ teamSlug, itemId, slotName, page: { offset, size } })

        if (!res.ok) {
          return null
        }

        return res.data.slot_sections.map((s) => ({
          hq_url: s.hq_url,
          lq_url: s.lq_url || undefined,
          section_index: s.section_index,
          width: s.width,
          height: s.height,
          type: s.type,
        }))
      },

      runInference: async (
        modelId: string,
        data: InferenceData | AutoAnnotateInferencePayload,
      ): Promise<InferenceResult | InferenceResult[] | null> => {
        if (!teamStore.currentTeam) {
          return null
        }

        const payload = {
          ...parseInferenceData(data),
          modelId,
          teamId: teamStore.currentTeam.id,
        }
        const response = await runModelInference<InferenceResult | InferenceResult[]>(payload)

        if (!response.ok) {
          typeof response.error.message === 'string' &&
            toast.warning({ meta: { title: response.error.message } })
          return null
        }

        const isFirstSend = isClickerData(data) && data.data.clicks.length === 0
        if (isFirstSend) {
          workviewV2Tracker.reportAutomationAction({ action_type: 'clicker', model_id: modelId })
          workviewV3Tracker.reportAutomationAction({ action_type: 'clicker', model_id: modelId })
        }

        return response.data.result
      },

      preselectDefaultAutoAnnotateModel: (): AutoAnnotateModel | null => {
        if (autoAnnotateStore.autoAnnotateModels.length === 0) {
          return null
        }

        const autoAnnotate = autoAnnotateStore.autoAnnotateModels.find((m) =>
          modelStore.trainedModels.some(
            (tm) => tm.id === m.id && tm.model_template.type === ModelType.AUTO_ANNOTATE,
          ),
        )

        const preselectedModel = autoAnnotate || autoAnnotateStore.autoAnnotateModels[0]

        if (preselectedModel) {
          workviewStore.setCurrentToolPreselectedModelId(preselectedModel.id)
        }

        return storeToEditorAutoAnnotateModel(preselectedModel)
      },
    })
  }

  watch(
    () => annotationsClassStore.preselectedAnnotationClassId,
    (value) => editor.value?.setPreselectedAnnotationClassId(value),
  )

  watch(
    () => annotationsClassStore.preselectedClassIdPerTool,
    (value) => editor.value?.setPreselectedClassIdPerTool(value),
  )

  watch(
    () => workviewSettingsStore.renderSubAnnotations,
    (value) => editor.value?.setRenderSubAnnotations(value),
  )

  watch(
    () => workviewSettingsStore.syncVideoPlayback,
    (value) => editor.value?.setSyncVideoPlayback(value),
  )

  watch(
    () => workviewSettingsStore.videoPlaybackState,
    (value) => {
      const playbackState = value[workviewSettingsStore.activeSlotName]
      // We should play only the active slot.
      // NOTE: sync playback expects only one slot to be played and rest to be synced using lqJumpToFrame.
      const view = editor.value?.layout.getViewByName(workviewSettingsStore.activeSlotName)
      if (!isVideoView(view)) {
        return
      }

      if (playbackState) {
        view.play()
        return
      }

      // Pause all slots with playbackState false or undefined.
      Object.keys(value).forEach((slotName) => {
        const state = value[slotName]
        const view = editor.value?.layout.getViewByName(slotName)
        if (!isVideoView(view)) {
          return
        }
        if (!state) {
          view.pause()
        }
      })
    },
  )

  watch(
    () => workviewSettingsStore.videoPlaybackLoop,
    (value) => editor.value?.setVideoPlaybackLoop(value),
  )

  watch(
    () => workviewSettingsStore.renderMeasures,
    (value) => editor.value?.setRenderMeasures(value),
  )

  watch(
    () => workviewSettingsStore.videoPlaybackSpeed,
    (value) => editor.value?.setVideoPlaybackSpeed(value),
  )

  watch(
    () => workviewSettingsStore.activeSlotName,
    (value: string) => {
      const view = editor.value?.layout.getViewByName(value)

      editor.value?.layout.setActiveView(view?.id || editor.value?.layout.viewsList[0]?.id)
    },
  )

  watch(
    () => workviewSettingsStore.currentPreviewFrameIndex,
    (value: number | null) => {
      if (value === null) {
        editor.value?.clearPreviewFrameIndex()
        return
      }

      editor.value?.setPreviewFrameIndex(value)
    },
  )

  watch(
    () => workviewSettingsStore.defaultFramesPreloadSize,
    (value: number) => editor.value?.setFramesPreloadSize(value),
  )

  watch(
    () => workviewStore.clickerEpsilon,
    (value) => editor.value?.setClickerEpsilon(value),
  )

  watch(
    () => workviewStore.preselectedModelId,
    (value) => editor.value?.setPreselectedModelId(value),
  )

  watch(
    () => workviewStore.autoAnnotateClassMapping,
    (value) => editor.value?.setAutoAnnotateClassMapping(value),
  )

  return {
    editor,
    initEditor,
  }
})
