import type { ActionTree } from 'vuex'

import type { TeamState } from '@/store/modules/team/state'
import type { RootState } from '@/store/types'

import { deleteMembership } from './deleteMembership'
import { deleteTeam } from './deleteTeam'
import { getInvitations } from './getInvitations'
import { getMemberships } from './getMemberships'
import { getPartnerMemberships } from './getPartnerMemberships'
import { getTeam } from './getTeam'
import { leaveTeam } from './leaveTeam'
import { register } from './register'
import { resolveImageUrl } from './resolveImageUrl'
import { updateMembership } from './updateMembership'
import { updateTeam } from './updateTeam'

export const actions: ActionTree<TeamState, RootState> = {
  deleteMembership,
  deleteTeam,
  getInvitations,
  getMemberships,
  getPartnerMemberships,
  getTeam,
  leaveTeam,
  register,
  resolveImageUrl,
  updateMembership,
  updateTeam,
}
