import type { AnnotationData, Polyline } from '@/modules/Editor/AnnotationData'
import { calculatePolylineMeasures, toFullOverlayData } from '@/modules/Editor/annotationMeasures'
import { CallbackStatus } from '@/modules/Editor/callbackHandler'
import type { CameraEvent } from '@/modules/Editor/camera'
import { EditorCursor, selectCursor } from '@/modules/Editor/editorCursor'
import { CameraEvents, WorkviewTrackerEvents } from '@/modules/Editor/eventBus'
import type { Action, ActionGroup } from '@/modules/Editor/managers/actionManager'
import { isLeftMouseButton } from '@/modules/Editor/mouse'
import type { IPoint, EditablePoint } from '@/modules/Editor/point'
import { subPoints, pointIsVertexOfPath, createEditablePoint } from '@/modules/Editor/point'
import { ToolName } from '@/modules/Editor/tools/types'
import type { PointerEvent } from '@/core/utils/touch'
import { isTouchEvent, resolveEventPoint } from '@/core/utils/touch'
import { moveVertexAction } from '@/modules/Editor/actions'
import { getAnnotationCompoundPathAtFrame } from '@/modules/Editor/getAnnotationCompoundPath'
import { drawIncompletePath } from '@/modules/Editor/graphicsV2/drawIncompletePath'
import type { Tool, ToolContext } from '@/modules/Editor/managers/toolManager'
import type { Annotation } from '@/modules/Editor/models/annotation/Annotation'
import { clearPath2DCache } from '@/modules/Editor/models/annotation/annotationRenderingCache'
import { cloneAnnotation } from '@/modules/Editor/models/annotation/cloneAnnotation'
import { moveAnnotationVertex } from '@/modules/Editor/moveAnnotationVertex'
import { setupMouseButtonLoadout } from '@/modules/Editor/plugins/mixins/loadouts'
import { preselectOrPromptForAnnotationClass } from '@/modules/Editor/utils/preselectOrPromptForAnnotationClass'
import type { View } from '@/modules/Editor/views/view'
import { setContext } from '@/services/sentry'

import { POLYLINE_ANNOTATION_TYPE } from './types'
import { findClosestPolylineEdge, insertPoint, isInsertingPoint } from './utils'

const annotationCreationAction = async (
  context: ToolContext,
  polyline: AnnotationData,
): Promise<Action> => {
  const params = { type: POLYLINE_ANNOTATION_TYPE, data: polyline }
  const newAnnotation =
    await context.editor.activeView.annotationManager.prepareAnnotationForCreation(params)

  if (!newAnnotation) {
    throw new Error('Failed to create annotation')
  }

  // support view id for undoing purposes
  const sourceViewId = context.editor.activeView.id

  return {
    do(): boolean {
      context.editor.activeView.annotationManager.createAnnotation(newAnnotation)
      context.editor.activeView.annotationManager.selectAnnotation(newAnnotation.id)
      context.editor.activeView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)

      return true
    },
    undo(): boolean {
      // we don't want to use the active view here, as by the time the user want to undo
      // the view could have changed due to multi-slot;
      // this would result in the annotation not being found and the undo action failing
      const sourceView = context.editor.viewsList.find(({ id }) => id === sourceViewId)
      if (!sourceView) {
        return false
      }

      sourceView.annotationManager.deleteAnnotation(newAnnotation.id)

      sourceView.measureManager.updateOverlayForExistingAnnotation(newAnnotation)
      return true
    },
  }
}

export class PolylineTool implements Tool {
  /**
   * Path of the polyline currently being drawn
   */
  currentPath: IPoint[] = []

  /**
   * Last known position of the mouse (on image)
   *
   * Constantly updates as mouse moves.
   */
  previousMouseMovePosition: IPoint | undefined = undefined

  /**
   * Initial position of the mouse on mouse down (on image)
   *
   * Is set on mouse down and does not update as mouse moves.
   */
  initialMouseDownPosition: IPoint | undefined = undefined

  cursorPoint: IPoint | undefined = undefined

  vertexIndexMoving: number | null = null
  highlightedVertex: EditablePoint | undefined = undefined
  overVertex: boolean = false

  pointOnLine: IPoint | null = null
  pointOnLineAnnotation: Annotation | null = null
  pointOnLinePath: EditablePoint[] | null = null
  pointOnLinePosition: number | null = null
  actionGroup: ActionGroup | undefined = undefined

  touching: boolean = false

  originAnnotation?: Annotation

  targetedItemsIndex: number | undefined = undefined

  async addPolyline(context: ToolContext): Promise<void> {
    if (!this.currentPath.length) {
      return
    }

    const polyline: Polyline = {
      path: this.currentPath.map((value) => createEditablePoint(value)),
    }
    try {
      context.editor.actionManager.do(await annotationCreationAction(context, polyline))
    } catch (e) {
      setContext('error', { error: e })
      console.error('V2 polyline tool createAnnotation failed')
    }

    if (this.actionGroup) {
      this.actionGroup.remove()
      this.actionGroup = undefined
    }

    this.currentPath = []
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  addPoints(context: ToolContext, point: IPoint): void {
    const action: Action = {
      do: () => {
        this.currentPath.push(point)
        this.draw(context.editor.activeView)
        // report activity for every new draw
        WorkviewTrackerEvents.reportActivity.emit()
        return true
      },
      undo: () => {
        this.currentPath.splice(-1, 1)
        this.draw(context.editor.activeView)
        // report activity for every new draw
        WorkviewTrackerEvents.reportActivity.emit()
        return true
      },
    }
    this.actionGroup = this.actionGroup || context.editor.actionManager.createGroup()
    this.actionGroup.do(action)
  }

  async activate(context: ToolContext): Promise<void> {
    setupMouseButtonLoadout(context, { middle: true })

    const classSelected = await preselectOrPromptForAnnotationClass(
      context.editor.activeView,
      ToolName.Polyline,
      [POLYLINE_ANNOTATION_TYPE],
      'You must create or select an existing Polyline class before using the polyline tool',
    )

    if (!classSelected) {
      return
    }

    selectCursor(EditorCursor.Draw)

    context.editor.registerCommand('polyline_tool.cancel', () => {
      this.reset(context)
      this.draw(context.editor.activeView)
    })

    context.editor.registerCommand('polyline_tool.confirm', () => {
      if (this.currentPath.length > 1) {
        this.addPolyline(context)
      }
    })

    context.handles.push(...context.editor.onDoubleClick(() => this.onDoubleClick(context)))
    context.handles.push(
      ...context.editor.onMouseDown((e) => {
        if (!isLeftMouseButton(e)) {
          return CallbackStatus.Continue
        }
        return this.onStart(context, e)
      }),
    )
    context.handles.push(...context.editor.onMouseMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onMouseUp((e) => this.onEnd(context, e)))
    context.handles.push(...context.editor.onTouchStart((e) => this.onStart(context, e)))
    context.handles.push(...context.editor.onTouchMove((e) => this.onMove(context, e)))
    context.handles.push(...context.editor.onTouchEnd((e) => this.onEnd(context, e)))

    const viewsOnRender = context.editor.viewsList.map((view) => {
      const handleCameraMove = (cameraEvent: CameraEvent): void => {
        if (cameraEvent.viewId !== view.id) {
          return
        }
        this.draw(view)
      }
      CameraEvents.scaleChanged.on(handleCameraMove)
      CameraEvents.offsetChanged.on(handleCameraMove)

      return {
        id: -1,
        release: (): void => {
          CameraEvents.scaleChanged.off(handleCameraMove)
          CameraEvents.offsetChanged.off(handleCameraMove)
        },
      }
    })

    context.handles.push(...viewsOnRender)
  }

  async onDoubleClick(context: ToolContext): Promise<void> {
    this.currentPath.pop()
    if (this.currentPath.length === 1) {
      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
      return
    }
    try {
      await this.addPolyline(context)
    } finally {
      if (context.editor.renderMeasures) {
        context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
      }
    }
  }

  updateDrawingMeasuresOverlay(context: ToolContext): void {
    if (this.currentPath.length === 0) {
      return
    }
    const path = [...this.currentPath]
    if (this.previousMouseMovePosition) {
      path.push(this.previousMouseMovePosition)
    }

    const data: Polyline = { path: path.map((p) => createEditablePoint(p)) }
    const view = context.editor.activeView
    const measureData = toFullOverlayData(
      calculatePolylineMeasures(data, view.camera, view.measureManager.measureRegion),
      view.annotationManager.preselectedAnnotationClassColor(),
    )

    if (measureData) {
      context.editor.activeView.measureManager.updateOverlayForDrawingAnnotation(measureData)
    } else {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  }

  maybeSuppressMouseEvent(event: PointerEvent): void | typeof CallbackStatus.Stop {
    event.preventDefault()
    const touching = isTouchEvent(event)
    if (!touching && this.touching) {
      // A touch event was already triggered, so we should prevent this mouse event to trigger
      return CallbackStatus.Stop
    }
    this.touching = touching
  }

  onStart(context: ToolContext, event: PointerEvent): void | typeof CallbackStatus.Stop {
    this.maybeSuppressMouseEvent(event)

    const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation

    this.originAnnotation = selectedAnnotation ? cloneAnnotation(selectedAnnotation) : undefined

    if (isTouchEvent(event) && event.targetTouches.length > 2 && this.currentPath.length > 1) {
      this.addPolyline(context)
      return
    }

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point
    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(this.cursorPoint)

    this.initialMouseDownPosition = imagePoint
    this.previousMouseMovePosition = imagePoint

    // inserted point can be selected and start moving within the same tick,
    // so this does not return
    if (isInsertingPoint(this)) {
      insertPoint(this, context)
      this.vertexIndexMoving = this.pointOnLinePosition
    }

    // this one is rendered in place of initial click otherwise
    this.pointOnLine = null

    const vertex = context.editor.activeView.annotationManager.findAnnotationVertexAt(imagePoint)
    if (this.currentPath.length === 0 && vertex) {
      context.editor.activeView.annotationManager.deselectVertex()
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (selectedAnnotation && this.targetedItemsIndex !== undefined) {
        this.vertexIndexMoving = this.targetedItemsIndex
        context.editor.activeView.annotationManager.selectVertexIndex(this.targetedItemsIndex)
      }
    } else if (vertex) {
      const { selectedAnnotation } = context.editor.activeView.annotationManager
      if (selectedAnnotation && this.targetedItemsIndex !== undefined) {
        this.vertexIndexMoving = this.targetedItemsIndex
        context.editor.activeView.annotationManager.selectVertexIndex(this.targetedItemsIndex)
      }
    } else {
      this.addPoints(context, imagePoint)
    }

    return CallbackStatus.Stop
  }

  onMove(context: ToolContext, event: PointerEvent): void | typeof CallbackStatus.Stop {
    if (!context.editor.activeView.hitTarget(event)) {
      return CallbackStatus.Stop
    }

    this.maybeSuppressMouseEvent(event)

    const point = resolveEventPoint(event)
    if (!point) {
      return
    }

    this.cursorPoint = point
    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(this.cursorPoint)
    const vertex = context.editor.activeView.annotationManager.findAnnotationVertexAt(imagePoint)

    const { selectedAnnotation } = context.editor.activeView.annotationManager
    if (selectedAnnotation) {
      this.targetedItemsIndex = context.editor.activeView.annotationsLayer.hitVertexRegion(
        selectedAnnotation.id,
        imagePoint,
      )

      if (selectedAnnotation && this.targetedItemsIndex !== undefined) {
        context.editor.activeView.annotationsLayer.activateVertexWithState(
          selectedAnnotation.id,
          this.targetedItemsIndex,
          { isHighlighted: true },
        )
      } else {
        context.editor.activeView.annotationsLayer.unhighlightAllVertices()
      }
    }

    const { previousMouseMovePosition, vertexIndexMoving } = this

    this.overVertex = false
    this.previousMouseMovePosition = imagePoint

    // Vertex translation
    if (previousMouseMovePosition && vertexIndexMoving !== null) {
      this.overVertex = true
      // Move a vertex only when it belongs to the selected annotation
      const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation
      if (selectedAnnotation) {
        const { activeView } = context.editor

        const { path } = getAnnotationCompoundPathAtFrame(
          selectedAnnotation,
          activeView.currentFrameIndex,
        )
        const vertexMoving = path[vertexIndexMoving]
        if (pointIsVertexOfPath(vertexMoving, path, 5 / context.editor.activeView.cameraScale)) {
          moveAnnotationVertex(
            selectedAnnotation,
            activeView,
            vertexMoving,
            subPoints(imagePoint, previousMouseMovePosition),
          )
          context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)
        }

        // force the path to be recalculated
        clearPath2DCache(selectedAnnotation.id)
      }
    } else if (vertex && this.currentPath.length === 0) {
      this.overVertex = true
      context.editor.activeView.unhighlightAllVertices()
      vertex.isHighlighted = true
      this.highlightedVertex = vertex
      this.pointOnLine = null
      selectCursor(EditorCursor.Edit)
    } else if (this.currentPath.length === 0) {
      this.pointOnLine = null
      const result = findClosestPolylineEdge(context.editor, this.cursorPoint, 5)

      if (result !== null) {
        const [point, annotation, position, path] = result
        context.editor.activeView.annotationManager.highlightAnnotation(annotation.id)
        context.editor.activeView.annotationManager.selectAnnotation(annotation.id)

        this.pointOnLine = context.editor.activeView.camera.imageViewToCanvasView(point)
        this.pointOnLineAnnotation = annotation
        this.pointOnLinePath = path
        this.pointOnLinePosition = position
        selectCursor(EditorCursor.AddPoint)
      } else {
        selectCursor(EditorCursor.Draw)
        if (context.editor.renderMeasures) {
          this.updateDrawingMeasuresOverlay(context)
        }
      }

      context.editor.activeView.unhighlightAllVertices()
    } else {
      selectCursor(EditorCursor.Draw)
      if (context.editor.renderMeasures) {
        this.updateDrawingMeasuresOverlay(context)
      }
      context.editor.activeView.unhighlightAllVertices()
    }
    this.draw(context.editor.activeView)
  }

  onEnd(context: ToolContext, event: PointerEvent): typeof CallbackStatus.Stop | void {
    this.maybeSuppressMouseEvent(event)

    const point = resolveEventPoint(event, true)
    if (point) {
      this.cursorPoint = point
    }

    if (!this.cursorPoint) {
      return
    }

    const canvasPoint = this.cursorPoint
    const imagePoint = context.editor.activeView.camera.canvasViewToImageView(canvasPoint)
    const selectedAnnotation = context.editor.activeView.annotationManager.selectedAnnotation

    const { initialMouseDownPosition, previousMouseMovePosition, vertexIndexMoving } = this

    if (
      initialMouseDownPosition?.x === previousMouseMovePosition?.x &&
      initialMouseDownPosition?.y === previousMouseMovePosition?.y
    ) {
      this.draw(context.editor.activeView)
      this.resetContext(context)
      return CallbackStatus.Stop
    }

    if (
      selectedAnnotation &&
      vertexIndexMoving !== null &&
      initialMouseDownPosition &&
      previousMouseMovePosition
    ) {
      const { path } = getAnnotationCompoundPathAtFrame(
        selectedAnnotation,
        context.editor.activeView.currentFrameIndex,
      )
      const vertexMoving = path[vertexIndexMoving]

      moveAnnotationVertex(
        selectedAnnotation,
        context.editor.activeView,
        vertexMoving,
        subPoints(initialMouseDownPosition, imagePoint),
      )
      context.editor.activeView.updateRenderedAnnotation(selectedAnnotation.id)

      const action = moveVertexAction(
        context.editor.activeView,
        this.originAnnotation,
        selectedAnnotation,
        vertexMoving,
        initialMouseDownPosition,
        imagePoint,
        (annotation, vertex, offset, view) =>
          moveAnnotationVertex(annotation, view, vertex, offset),
      )

      context.editor.actionManager.do(action)

      this.resetContext(context)
    }

    this.draw(context.editor.activeView)
    return CallbackStatus.Stop
  }

  onRender(view: View, context: ToolContext): void {
    const ctx = view.annotationsLayer.context
    if (!ctx) {
      return
    }
    const { currentPath, pointOnLine, previousMouseMovePosition, overVertex } = this
    if (currentPath.length === 0) {
      if (pointOnLine !== null) {
        ctx.strokeStyle = context.editor.activeView.annotationManager.selectedAnnotationClassColor()
        ctx.fillStyle = 'rgb(255,255,255)'
        ctx.lineWidth = 1.5
        ctx.beginPath()
        ctx.arc(pointOnLine.x, pointOnLine.y, 3.5, 0, 2 * Math.PI)
        ctx.fill()
        ctx.stroke()
        ctx.closePath()
      } else {
        if (previousMouseMovePosition && !overVertex) {
          const path = [previousMouseMovePosition]
          drawIncompletePath(
            ctx,
            path,
            view.camera,
            context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
          )
        }
      }
      return
    }
    if (previousMouseMovePosition) {
      const path = [...this.currentPath, previousMouseMovePosition]
      drawIncompletePath(
        ctx,
        path,
        view.camera,
        context.editor.activeView.annotationManager.preselectedAnnotationClassColor(),
      )
    }
  }

  draw(view: View): void {
    view.annotationsLayer.draw((ctx) => {
      const { currentPath, pointOnLine, previousMouseMovePosition, overVertex } = this
      if (currentPath.length === 0) {
        if (pointOnLine !== null) {
          ctx.strokeStyle = view.annotationManager.selectedAnnotationClassColor()
          ctx.fillStyle = 'rgb(255,255,255)'
          ctx.lineWidth = 1.5
          ctx.beginPath()
          ctx.arc(pointOnLine.x, pointOnLine.y, 3.5, 0, 2 * Math.PI)
          ctx.fill()
          ctx.stroke()
          ctx.closePath()
        } else {
          if (previousMouseMovePosition && !overVertex) {
            const path = [previousMouseMovePosition]
            drawIncompletePath(
              ctx,
              path,
              view.camera,
              view.annotationManager.preselectedAnnotationClassColor(),
            )
          }
        }
        return
      }
      if (previousMouseMovePosition) {
        const path = [...this.currentPath, previousMouseMovePosition]
        drawIncompletePath(
          ctx,
          path,
          view.camera,
          view.annotationManager.preselectedAnnotationClassColor(),
        )
      }
    })
  }

  deactivate(context: ToolContext): void {
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  reset(context: ToolContext): void {
    this.resetContext(context)
    this.currentPath = []
    context.editor.activeView.annotationsLayer.clearDrawingCanvas()
  }

  resetContext(context: ToolContext): void {
    this.pointOnLine = null
    this.initialMouseDownPosition = undefined
    this.previousMouseMovePosition = undefined
    this.vertexIndexMoving = null
    this.touching = false

    if (context.editor.renderMeasures) {
      context.editor.activeView.measureManager.removeOverlayForDrawingAnnotation()
    }
  }

  async confirmCurrentAnnotation(context: ToolContext): Promise<void> {
    await this.addPolyline(context)
  }
}

export const polylineTool = new PolylineTool()
