export enum BlockedReason {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ITEM_COUNT_LIMIT_EXCEEDED = 'ITEM_COUNT_LIMIT_EXCEEDED',
}

export type DatasetUploadItemPayload = {
  file_name: string
  slot_name?: string
  type?: string
  upload_id?: string
  fps?: number | 'native'
  path?: string
  tags?: string[]
  as_frames?: boolean
  extract_views?: boolean
  reason?: BlockedReason
  processing_status?: string
  processing_error?: DatasetUploadItemProcessingErrorPayloadV2
}

export type DatasetUploadItemProcessingErrorPayloadV2 = {
  type: string
  http_status_code?: number
  raw_error?: string
  stage: string
  message: string
  storage_key?: string
}
