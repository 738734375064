import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOutOfStorageDialogStore = defineStore('outOfStorageDialog', () => {
  const open = ref(false)
  const resetState = (): void => {
    open.value = false
  }

  return { open, resetState }
})
